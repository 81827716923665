import React, { useEffect } from "react";
import {
  Col,
  config,
  Container,
  Row,
  Visible,
} from "react-awesome-styled-grid";
import ReactGA from "react-ga4";
import styled from "styled-components";
import { Card } from "../../components/Card";
import { FeatureStack } from "../../components/FeatureStack";
import useEventTracker from "../../components/GaEvent";
import { Contact } from "../../components/icons/Contact";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { SEO } from "../../components/SEO";
import { Spacer } from "../../components/Spacer";
import { Text } from "../../components/Text";
import { colors } from "../../theme";
import { RequestDemoForm } from "./form";

const Heading = styled("h2")`
  color: ${colors.gray700};
  font-family: KurrentBold;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 54px;

  ${(props) => config(props).media["lg"]`
    font-size: 48px;
    line-height: 76px;
  `}
`;

const ShadowCard = styled(Card)`
  box-shadow: 4px 24px 27px -6px #d9dee669;
`;

const EmailLink = styled("a")`
  color: ${colors.gray700};
  font-family: KurrentBold;
`;

const IntroContent: React.FC = () => {
  const gaEventTracker = useEventTracker("CONTACT:INTRO_CONTENT");

  return (
    <>
      <Visible md lg xl>
        <Contact />
      </Visible>
      <Spacer size="sm" direction="v" />
      <Heading>Request a demo</Heading>
      <Text fontSize="20px" lineHeight="150%" fontFamily="InterRegular">
        You can reach us anytime,{" "}
        <EmailLink
          href="mailto:hello@palisade.co"
          onClick={() => gaEventTracker("MAIL_TO", "CLICK_LINK")}
        >
          hello@palisade.co
        </EmailLink>
      </Text>
      <Spacer size="4xl" direction="v" />
    </>
  );
};

export const RequestDemoPage: React.FC = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      <SEO
        description="Let us show you how we can protect your assets. Request a demo using our form or via hello@palisade.co"
        title="Request a demo"
      />
      <Container>
        <Spacer size="4xl" direction="v" />
        <Col lg={11} offset={0.5}>
          <Row>
            <RestoreScrollTop />
            <Col md={5} lg={5} offset={{ lg: 0 }}>
              <ShadowCard>
                <IntroContent />
                <FeatureStack />
              </ShadowCard>
            </Col>

            <Col md={6} lg={6} offset={{ lg: 1 }}>
              <RequestDemoForm />
              <Visible xs sm>
                <Spacer direction="v" size="5xl" />
              </Visible>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};
