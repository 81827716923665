import { config } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";

export const H1 = styled.h1`
  color: ${colors.gray700};
  font-family: KurrentSemiBold;
  font-size: 44px;
  letter-spacing: -1.2px;
  line-height: 120%;
  margin: 0;
  padding: 0;

  ${(props) => config(props).media["md"]`
    font-size: 54px;
  `}

  ${(props) => config(props).media["lg"]`
    font-size: 60px;
  `}
`;

export const H2 = styled.h2`
  color: ${colors.gray700};
  font-family: KurrentSemiBold;
  font-size: 32px;
  letter-spacing: -0.96px;
  line-height: 120%;
  margin: 0;

  ${(props) => config(props).media["md"]`
    font-size: 42px;
  `}

  ${(props) => config(props).media["lg"]`
    font-size: 48px;
  `}
`;

export const H3 = styled.h3`
  color: ${colors.gray700};
  font-family: KurrentSemiBold;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.72px;
  margin: 0;

  ${(props) => config(props).media["md"]`
    font-size: 32px
  `}

  ${(props) => config(props).media["lg"]`
    font-size: 36px;
  `}
`;

export const SubH1 = styled.p`
  color: ${colors.gray700};
  font-family: InterRegular;
  font-size: 18px;
  line-height: 150%;
  margin: 0;
  padding: 0;
`;
