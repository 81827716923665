import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../theme";

export const Logo: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { color = colors.gray700 } = props;

  return (
    <svg
      width="150"
      height="30"
      viewBox="0 0 150 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H30.4904V29.6512H0V0ZM9.73309 7.69034L13.5407 5.01329V24.6379L9.73309 22.1917V7.69034ZM16.527 5.01329L20.3346 7.95727V18.6541L16.527 21.7424V5.01329Z"
        fill={color}
      />
      <path
        d="M50.9465 5.94793H42.3076L42.3076 25.4732L45.3445 23.1385V18.1495H50.7401C54.75 18.1495 57.5805 16.0371 57.5805 12.0348C57.5805 8.00469 54.75 5.94793 50.9465 5.94793ZM50.8876 15.3979H45.3445V8.69954H50.8876C53.0399 8.69954 54.4257 9.89468 54.4257 12.0348C54.4257 14.1194 53.2463 15.3979 50.8876 15.3979Z"
        fill={color}
      />
      <path
        d="M64.927 23.1246C62.922 23.1246 61.949 22.1518 61.949 20.7065C61.949 19.3724 63.1874 18.5386 65.1333 18.5386C66.7845 18.5386 67.8459 18.7888 68.6715 19.1779V21.2624C67.9638 22.1796 66.5486 23.1246 64.927 23.1246ZM71.6494 15.3979C71.6494 12.0904 69.2906 10.5061 65.841 10.5061C63.6591 10.5061 62.008 10.9508 60.5338 11.8125L61.7132 13.9804C62.922 13.3411 64.1604 13.0632 65.7525 13.0632C67.6395 13.0632 68.6715 13.9526 68.6715 15.5369V16.6208C67.8164 16.2595 66.6665 16.0649 65.2218 16.0649C61.7132 16.0649 59.0596 17.538 59.0596 20.7899C59.0596 24.3753 61.9196 25.7095 64.4257 25.7095C66.1063 25.7095 67.61 24.959 68.6715 23.9584V25.4037H71.6494V15.3979Z"
        fill={color}
      />
      <path
        d="M78.8968 5.14191L75.8599 6.64278V25.4037H78.8968V5.14191Z"
        fill={color}
      />
      <path
        d="M84.9012 5.22529C83.8103 5.22529 82.9258 6.05911 82.9258 7.08748C82.9258 8.08807 83.8103 8.92189 84.9012 8.92189C85.9627 8.92189 86.8472 8.08807 86.8472 7.08748C86.8472 6.05911 85.9627 5.22529 84.9012 5.22529ZM86.4049 10.8119H83.3681V25.4037H86.4049V10.8119Z"
        fill={color}
      />
      <path
        d="M96.3308 16.5096C94.0015 15.648 93.4708 15.1199 93.4708 14.3973C93.4708 13.6191 94.1195 13.0632 95.5937 13.0632C97.0679 13.0632 98.3357 13.4801 99.6036 14.3139L100.93 12.1182C99.5446 11.1732 97.6281 10.5061 95.6821 10.5061C92.5863 10.5061 90.4044 11.8681 90.4044 14.4807C90.4044 16.7876 91.6133 17.8438 94.945 19.0667C97.1858 19.8727 98.5421 20.4008 98.5421 21.5126C98.5421 22.5409 97.6281 23.1246 95.7116 23.1246C94.2669 23.1246 92.6453 22.6243 91.2005 21.6793L89.8148 23.8473C91.3774 24.9312 93.6477 25.7095 95.7411 25.7095C98.778 25.7095 101.55 24.6255 101.55 21.4014C101.55 18.6498 99.7805 17.7604 96.3308 16.5096Z"
        fill={color}
      />
      <path
        d="M110.134 23.1246C108.129 23.1246 107.156 22.1518 107.156 20.7065C107.156 19.3724 108.394 18.5386 110.34 18.5386C111.991 18.5386 113.053 18.7888 113.878 19.1779V21.2624C113.171 22.1796 111.755 23.1246 110.134 23.1246ZM116.856 15.3979C116.856 12.0904 114.497 10.5061 111.048 10.5061C108.866 10.5061 107.215 10.9508 105.74 11.8125L106.92 13.9804C108.129 13.3411 109.367 13.0632 110.959 13.0632C112.846 13.0632 113.878 13.9526 113.878 15.5369V16.6208C113.023 16.2595 111.873 16.0649 110.428 16.0649C106.92 16.0649 104.266 17.538 104.266 20.7899C104.266 24.3753 107.126 25.7095 109.632 25.7095C111.313 25.7095 112.817 24.959 113.878 23.9584V25.4037H116.856V15.3979Z"
        fill={color}
      />
      <path
        d="M126.757 23.069C124.516 23.069 123.278 21.457 123.278 17.7882C123.278 14.703 124.457 13.1466 126.639 13.1466C128.172 13.1466 129.588 14.2027 130.266 15.0644V21.2068C129.47 22.1518 128.202 23.069 126.757 23.069ZM133.244 5.14191L130.236 6.61499V12.1738C129.381 11.2844 127.907 10.5061 126.344 10.5061C123.13 10.5061 120.241 12.8686 120.241 17.8715C120.241 23.4303 122.865 25.7095 126.197 25.7095C127.818 25.7095 129.116 24.9868 130.236 24.014V25.4037H133.244V5.14191Z"
        fill={color}
      />
      <path
        d="M143.484 10.5061C139.474 10.5061 136.702 13.1744 136.702 18.1217C136.702 22.9578 139.769 25.7095 143.395 25.7095C145.754 25.7095 147.376 25.0146 148.732 23.7917L146.934 21.9573C145.902 22.6799 144.87 23.1246 143.572 23.1246C141.685 23.1246 140.005 21.9295 139.68 19.1223H149.911C149.941 18.8165 150 18.344 150 17.816C150 13.8692 147.877 10.5061 143.484 10.5061ZM139.739 16.7598C140.005 14.4251 141.243 13.091 143.278 13.091C145.784 13.091 146.786 14.7586 146.934 16.7598H139.739Z"
        fill={color}
      />
    </svg>
  );
};
