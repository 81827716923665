import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Circle: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="96"
      height="101"
      viewBox="0 0 96 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M39.0991 74.5239C25.4861 69.7496 18.5049 54.9143 23.5664 41.7839C26.1843 34.622 31.9437 29.1655 39.0991 26.6077C39.7974 26.2668 40.1463 25.7552 40.1463 24.9023V22.5152C40.1463 21.8329 39.7974 21.3214 39.0991 21.1511C38.9245 21.1511 38.5756 21.1511 38.4009 21.3214C21.821 26.437 12.7455 43.6599 17.9813 59.8593C21.1228 69.4083 28.6275 76.7408 38.4009 79.8102C39.0991 80.1511 39.7974 79.8102 39.9716 79.128C40.1463 78.9577 40.1463 78.7871 40.1463 78.4462V76.0586C40.1463 75.5471 39.6227 74.8652 39.0991 74.5239ZM57.5991 21.3214C56.9009 20.9805 56.2026 21.3214 56.0284 22.0036C55.8537 22.1742 55.8537 22.3445 55.8537 22.6858V25.073C55.8537 25.7552 56.3773 26.437 56.9009 26.7783C70.5139 31.5526 77.4951 46.388 72.4336 59.5184C69.8157 66.6802 64.0563 72.1368 56.9009 74.6946C56.2026 75.0355 55.8537 75.5471 55.8537 76.3999V78.7871C55.8537 79.4693 56.2026 79.9809 56.9009 80.1511C57.0755 80.1511 57.4244 80.1511 57.5991 79.9809C74.179 74.8652 83.2545 57.6423 78.0187 41.443C74.8772 31.7233 67.1978 24.3907 57.5991 21.3214Z"
        fill="#314756"
      />
    </svg>
  );
};
