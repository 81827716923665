import { gql } from "@apollo/client";

export const QUERY_LIST_LEGAL_DOCUMENTS = gql`
  query ListLegaLDocuments {
    legals(sort: "createdAt") {
      data {
        id
        attributes {
          title
          body
          createdAt
        }
      }
    }
  }
`;
