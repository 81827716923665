import React from "react";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { Navigate } from "react-router-dom";
import { Articles } from "../../../api/articles";
import { RestoreScrollTop } from "../../../components/RestoreScrollTop";
import { Spacer } from "../../../components/Spacer";
import { Stack } from "../../../components/Stack";
import { GraphQlLoadable } from "../../../graphql/types";
import { Article } from "../Article";
import { ArticlesSubMenu } from "../ArticlesSubMenu";

interface Props {
  listArticlesLoadable: GraphQlLoadable<Articles>;
}

export const BlogIndexPageView: React.FC<Props> = (props) => {
  const { listArticlesLoadable } = props;

  if (!listArticlesLoadable.data || listArticlesLoadable.isLoading) {
    return null;
  }

  if (!listArticlesLoadable.data?.articles?.data?.length) {
    return <Navigate to="/not-found" replace={true} />;
  }

  const articleList = listArticlesLoadable?.data?.articles.data;

  const article = articleList[0].attributes;

  return (
    <Container>
      <Spacer direction="v" size="5xl" />
      <Spacer direction="v" size="4xl" />
      <Row>
        <RestoreScrollTop />
        <Col md={4} lg={4} offset={{ lg: 0 }}>
          <ArticlesSubMenu articleList={articleList} />
        </Col>
        <Col md={7} lg={6} offset={{ md: 1, lg: 1 }}>
          <Stack gap="56px">
            <Article article={article} />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};
