import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Litecoin: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="68"
      height="101"
      viewBox="0 0 68 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M33.8173 53.5396L29.8883 67.5021H50.9035C51.0428 67.4969 51.1816 67.5208 51.312 67.5722C51.4425 67.6236 51.5621 67.7016 51.6639 67.8018C51.7657 67.902 51.8478 68.0223 51.9055 68.1559C51.9632 68.2896 51.9953 68.4339 52 68.5806V68.9465L50.1726 75.5907C50.092 75.9049 49.9125 76.1809 49.6634 76.3734C49.4144 76.5658 49.1106 76.6634 48.802 76.6499H16.6396L22.0305 57.2951L16 59.2209L17.3706 54.7914L23.401 52.8656L30.9848 25.711C31.0683 25.3986 31.2485 25.1246 31.4969 24.9326C31.7453 24.7407 32.0475 24.6418 32.3553 24.6518H40.4873C40.6265 24.6467 40.7653 24.6705 40.8958 24.7219C41.0263 24.7733 41.1458 24.8514 41.2477 24.9515C41.3495 25.0517 41.4316 25.172 41.4893 25.3057C41.5469 25.4393 41.579 25.5836 41.5838 25.7303V26.0962L35.1878 49.0139L41.2183 47.088L39.9391 51.7101L33.8173 53.5396Z"
        fill="#314756"
      />
    </svg>
  );
};
