import { Link } from "react-router-dom";
import { Button, ButtonProps } from "../Button";

interface LinkButtonProps extends ButtonProps {
  to: string;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { isFullWidth, to, ...rest } = props;

  return (
    <Link to={to} tabIndex={-1}>
      <Button {...rest} isFullWidth={isFullWidth} />
    </Link>
  );
};
