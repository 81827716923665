import styled from "styled-components";
import { colors } from "../../theme";

interface Props {
  showUnderline?: boolean;
  color?: string;
  whiteSpace?: string;
}

export const ExternalLink = styled.a.attrs((props) => ({
  color: colors.white,
  showUnderline: false,
  ...props,
}))<Props>`
  color: ${({ color }) => color};
  text-decoration: ${({ showUnderline }) =>
    showUnderline ? "underline" : "none"};
  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "nowrap")};
`;
