import { Article } from "../../api/articles";
import { List, ListItem, ListLink, ListTitle, SubMenu } from "./helpers";

interface Props {
  articleList: Article[];
}

export const ArticlesSubMenu: React.FC<Props> = ({ articleList }) => {
  return (
    <SubMenu>
      <ListTitle>Articles</ListTitle>
      <List>
        {articleList.map((item: any) => {
          return (
            <ListItem key={`blog-post_${item.id}`}>
              <ListLink
                to={`/blog/${
                  item.attributes.slug ? item.attributes.slug : item.id
                }`}
              >
                {item?.attributes.title}
              </ListLink>
            </ListItem>
          );
        })}
      </List>
    </SubMenu>
  );
};
