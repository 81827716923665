import { config, Container } from "react-awesome-styled-grid";
import styled from "styled-components";
import { H2 } from "../../components/Heading";
import { Stack } from "../../components/Stack";
import { colors } from "../../theme";

const TimelineContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  padding: 40px 0;
`;

const TimelineItems = styled.div`
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const TimelineYear = styled.div`
  align-items: center;
  background: url("../images/timeline-year-background-repeat.svg") repeat-y left;
  font-family: KurrentSemiBold;
  font-size: 36px;
  letter-spacing: -0.72px;
  line-height: 120%;
  padding: 24px;

  ${(props) => config(props).media["xs"]`
    display: block;
    width: 100%;
  `}

  ${(props) => config(props).media["md"]`
    display: inline-flex;
    width: auto;
  `}
`;

const TimelineCard = styled.div`
  align-items: center;
  background-color: rgba(251, 252, 253, 0.35);
  border: 1px solid ${colors.gray200};
  border-radius: 6px;
  box-shadow: 22px 23px 53px 1px rgba(215, 221, 230, 0.44);
  flex-grow: 1;
  justify-content: center;
  padding: 32px 40px;
  white-space: nowrap;
`;

export const Timeline: React.FC = () => {
  return (
    <Container>
      <TimelineContainer>
        <H2>Making rapid progress for our customers,</H2>
        <Stack gap="4px">
          <TimelineItems>
            <TimelineYear>2022</TimelineYear>
            <TimelineCard>Palisade Financial LTD. founded</TimelineCard>
            <TimelineCard>Obtained grant from XRP Grants Program</TimelineCard>
            <TimelineCard>£3 million Pre-Seed raise</TimelineCard>
            <TimelineCard>Sandbox launch</TimelineCard>
            <TimelineYear>2023</TimelineYear>
            <TimelineCard>NCC Pen Test completed</TimelineCard>
            <TimelineCard>Achieved Registration in France</TimelineCard>
            <TimelineCard>First customer in production</TimelineCard>
            <TimelineCard>Live MPC wallets</TimelineCard>
            <TimelineCard>WalletConnect Integration</TimelineCard>
            <TimelineYear>2024</TimelineYear>
            <TimelineCard>Cloud Sign launch</TimelineCard>
            {/* <TimelineCard>FCA application submission</TimelineCard> */}
          </TimelineItems>
        </Stack>
      </TimelineContainer>
    </Container>
  );
};
