import React from "react";
import { Col, config, Container, Row } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Avalanche } from "../icons/v2/Avalanche";
import { BNB } from "../icons/v2/BNB";
import { BUSD } from "../icons/v2/BUSD";
import { ChainLink } from "../icons/v2/ChainLink";
import { Circle } from "../icons/v2/Circle";
import { Dollar } from "../icons/v2/Dollar";
import { Ethereum } from "../icons/v2/Ethereum";
import { Litecoin } from "../icons/v2/Litecoin";
import { PolkaDot } from "../icons/v2/PolkaDot";
import { Polygon } from "../icons/v2/Polygon";
import { Solana } from "../icons/v2/Solana";
import { Uniswap } from "../icons/v2/Uniswap";
import { Unknown } from "../icons/v2/Unknown";
import { XRPL } from "../icons/v2/XRPL";
import { Text } from "../Text";

interface Props {}

const FeatureHeading = styled("p")`
  font-size: 44px;
  line-height: 1.2;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: KurrentSemiBold;
  letter-spacing: -1px;
  color: ${colors.gray700};
`;

const Grid = styled.div`
  display: grid;
  row-gap: 24px;
  column-gap: 14px;

  justify-items: flex-start;
  align-items: center;

  overflow: hidden;

  grid-template-columns: repeat(auto-fill, 103px);
`;

const ChainIcon = styled.div`
  min-width: 103px;
  text-align: center;
`;

const IntroGridColumn = styled.div`
  grid-column: span 3;

  ${(props) => config(props).media["sm"]`
    grid-column: span 5;
  `}
`;

const OutroGridColumn = styled.div`
  grid-column: span 3;

  ${(props) => config(props).media["sm"]`
    grid-column: span 5;
  `}
`;

export const SupportedChains: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        position: "relative",
        padding: "48px 0px",
      }}
    >
      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            offset={{
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            }}
          >
            <Grid>
              <IntroGridColumn>
                <FeatureHeading>
                  Comprehensive multi-chain support including…
                </FeatureHeading>
              </IntroGridColumn>
              <ChainIcon>
                <Ethereum />
              </ChainIcon>
              <ChainIcon>
                <Solana />
              </ChainIcon>
              <ChainIcon>
                <Uniswap />
              </ChainIcon>
              <ChainIcon>
                <Polygon />
              </ChainIcon>
              <ChainIcon>
                <Circle />
              </ChainIcon>
              <ChainIcon>
                <ChainLink />
              </ChainIcon>
              <ChainIcon>
                <PolkaDot />
              </ChainIcon>
              <ChainIcon>
                <Dollar />
              </ChainIcon>
              <ChainIcon>
                <BUSD />
              </ChainIcon>
              <ChainIcon>
                <Litecoin />
              </ChainIcon>
              <ChainIcon>
                <XRPL />
              </ChainIcon>
              <ChainIcon>
                <Unknown />
              </ChainIcon>
              <ChainIcon>
                <Avalanche />
              </ChainIcon>
              <ChainIcon>
                <BNB />
              </ChainIcon>
              <OutroGridColumn>
                <Text
                  fontSize="16px"
                  fontFamily="InterRegular"
                  color={colors.gray700}
                  lineHeight="24px"
                >
                  Out of the box support for the leading blockchains and DeFi
                  protocols including Avalanche, Ethereum, XRPL, Solana, Polygon
                  and more.
                </Text>
              </OutroGridColumn>
            </Grid>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
