import styled, { css } from "styled-components";
import { colors } from "../../theme";
import { ButtonActivityIndicator } from "./ButtonActivityIndicator";
import { getActivityIndicatorColor } from "./helpers";

export type ButtonLevels =
  | "primary"
  | "secondary"
  | "tertiary"
  | "assertive"
  | "warning";
export type ButtonSizes = "xs" | "sm";
export type ButtonVariants = "ghost" | "filled";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  level?: ButtonLevels;
  isLoading?: boolean;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  minWidth?: string;
  size?: ButtonSizes;
  variant?: ButtonVariants;
}

interface StyledButtonProps extends Partial<ButtonProps> {
  $isFullWidth?: boolean;
  $isDisabled?: boolean;
  minWidth?: string;
}

interface ContentWrapperProps {
  isHidden?: boolean;
}

const buttonThemes = {
  primary: {
    filled: css`
      background: ${colors.gray800};
      border-color: ${colors.gray900};
      border: 2px solid ${colors.gray900};
      color: ${colors.white};

      &:hover {
        background: ${colors.gray900};
      }

      &:focus {
        outline: solid 2px ${colors.gray300};
      }
    `,
    ghost: css`
      background: transparent;
      border-color: transparent;
      color: ${colors.gray800};
      border-color: transparent;

      &:hover {
        background: ${colors.gray50};
      }

      &:focus {
        outline: solid 2px ${colors.gray200};
      }
    `,
  },
  secondary: {
    filled: css`
      background: ${colors.gray200};
      border-color: ${colors.gray300};
      color: ${colors.gray800};

      &:hover {
        background: ${colors.gray300};
      }

      &:focus {
        outline: solid 2px ${colors.gray200};
      }
    `,
    ghost: css`
      border-color: transparent;
    `,
  },
  tertiary: {
    filled: css`
      background: ${colors.white};
      border: 2px solid ${colors.gray100};
      color: ${colors.gray700};

      &:hover {
        border-color: ${colors.gray50};
        background: ${colors.gray50};
      }

      &:focus {
        outline: solid 2px ${colors.gray200};
      }
    `,
    ghost: css`
      border-color: transparent;
    `,
  },
  assertive: {
    filled: css`
      background: ${colors.error700};
      border-color: ${colors.error800};
      color: ${colors.white};

      &:hover {
        background: ${colors.error900};
      }

      &:focus {
        outline: solid 2px ${colors.error200};
      }
    `,
    ghost: css`
      border-color: transparent;
    `,
  },
  warning: {
    filled: css`
      background: ${colors.warning50};
      border-color: ${colors.warning50};
      color: ${colors.gray700};

      &:hover {
        background: ${colors.warning100};
        border-color: ${colors.warning100};
      }

      &:focus {
        outline: solid 2px ${colors.warning200};
      }
    `,
    ghost: css`
      border-color: transparent;
    `,
  },
};

function getButtonSize(size?: ButtonSizes) {
  if (size === "sm") {
    return css`
      font-family: InterRegular;
      font-size: 16px;
      height: 36px;
      line-height: 150%;
      padding: 8px 14px;
    `;
  }

  return css`
    font-family: InterMedium;
    font-size: 20px;
    height: 60px;
    padding: 8px 24px;
  `;
}

function getButtonTheme(variant: ButtonVariants, level: ButtonLevels) {
  return buttonThemes[level][variant];
}

// Base style used for both <Button /> and <LinkButton />
const ButtonBaseStyle = css`
  font-size: 12px;
  font-family: InterMedium;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  white-space: nowrap;
  line-height: 1;

  -webkit-transition: background 150ms ease, border 150ms ease;
  -ms-transition: background 200ms linear, border 200ms linear;
  transition: background 200ms linear, border 200ms linear;
`;

const StyledButton = styled.button<StyledButtonProps>`
  ${ButtonBaseStyle}
  ${({ size }) => getButtonSize(size)}
  min-width: ${({ minWidth }) => (minWidth ? minWidth : undefined)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
  width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : undefined)};
  ${({ level, variant }) => getButtonTheme(variant!, level!)}
`;

const ContentWrapper = styled.span<ContentWrapperProps>`
  align-items: center;
  display: flex;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  line-height: 28px;
`;

export const Button: React.FC<ButtonProps> = ({
  children,
  isDisabled = false,
  isFullWidth = false,
  isLoading = false,
  level = "primary",
  minWidth,
  size,
  type = "button",
  variant = "filled",
  ...rest
}) => {
  return (
    <StyledButton
      $isFullWidth={isFullWidth}
      data-testid="button"
      disabled={isDisabled}
      level={level}
      minWidth={minWidth}
      size={size}
      variant={variant}
      {...rest}
    >
      {isLoading && (
        <ButtonActivityIndicator
          color={getActivityIndicatorColor(level, variant)}
          data-testid="button-activity-indicator"
        />
      )}
      <ContentWrapper data-testid="button-content" isHidden={isLoading}>
        {children}
      </ContentWrapper>
    </StyledButton>
  );
};
