import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Dollar: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="64"
      height="101"
      viewBox="0 0 64 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M48 59.4892C48 50.7757 42.985 47.7882 32.9551 46.5436C25.7909 45.5475 24.3581 43.556 24.3581 40.0701C24.3581 36.5843 26.7463 34.3442 31.5223 34.3442C35.8208 34.3442 38.2091 35.838 39.4029 39.5724C39.6419 40.3193 40.3584 40.817 41.0748 40.817H44.8953C45.8507 40.817 46.5672 40.0701 46.5672 39.0747V38.8255C45.6117 33.3482 41.3132 29.116 35.8208 28.6183V22.6432C35.8208 21.6472 35.1044 20.9003 33.9106 20.6511H30.3284C29.373 20.6511 28.6566 21.398 28.4176 22.6432V28.3691C21.2534 29.3652 16.7164 34.3442 16.7164 40.5685C16.7164 48.7842 21.4924 52.0203 31.5223 53.2655C38.2091 54.5101 40.3584 56.0039 40.3584 59.9875C40.3584 63.9711 37.0147 66.7095 32.4777 66.7095C26.2683 66.7095 24.1191 63.9705 23.4026 60.2361C23.1642 59.2406 22.4478 58.7423 21.7314 58.7423H17.6713C16.7164 58.7423 16 59.4892 16 60.4852V60.7344C16.9548 66.958 20.776 71.4393 28.6566 72.6845V78.6596C28.6566 79.6551 29.373 80.402 30.5669 80.6511H34.149C35.1044 80.6511 35.8208 79.9042 36.0598 78.6596V72.6845C43.224 71.4393 48 66.2111 48 59.4892Z"
        fill="#314756"
      />
    </svg>
  );
};
