import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Polygon: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="104"
      height="101"
      viewBox="0 0 104 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M87.6692 51.7757V68.2676C87.6634 69.2961 87.3967 70.3054 86.8951 71.1968C86.3935 72.0882 85.6744 72.8313 84.8078 73.3535L70.8365 81.5823C69.9712 82.1098 68.9826 82.3882 67.9751 82.3882C66.9678 82.3882 65.9791 82.1098 65.1138 81.5823L51.1424 73.3535C50.2758 72.8313 49.5567 72.0882 49.0551 71.1968C48.5537 70.3054 48.2869 69.2961 48.2811 68.2676V63.6389L55.4344 59.393V67.4391L67.9528 74.8678L80.4711 67.4391V52.6156L67.9528 45.1869L38.6241 62.5589C37.7507 63.0621 36.7653 63.3265 35.7627 63.3265C34.7603 63.3265 33.7748 63.0621 32.9014 62.5589L18.93 54.2957C18.0768 53.7657 17.3715 53.0198 16.8819 52.1299C16.3925 51.2402 16.1356 50.2365 16.1357 49.2156V32.7236C16.1415 31.6951 16.4083 30.6859 16.9099 29.7943C17.4113 28.9029 18.1306 28.1598 18.997 27.6377L32.9684 19.4088C33.8356 18.8867 34.8235 18.6113 35.8297 18.6113C36.8361 18.6113 37.824 18.8867 38.6912 19.4088L52.6626 27.6377C53.529 28.1598 54.2483 28.9029 54.7497 29.7943C55.2513 30.6859 55.5181 31.6951 55.5239 32.7236V37.3522L48.3259 41.5695V33.5693L35.8074 26.1405L23.289 33.5693V48.3755L35.8074 55.8044L65.1361 38.4324C66.0095 37.9291 66.995 37.6648 67.9976 37.6648C69 37.6648 69.9854 37.9291 70.8588 38.4324L84.8303 46.6954C85.6918 47.22 86.406 47.9634 86.9034 48.8536C87.401 49.7438 87.6648 50.7504 87.6692 51.7757Z"
        fill={color}
      />
    </svg>
  );
};
