import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const FeatureWallet: React.FC<Props> = ({
  color = "#183142",
  ...rest
}) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        style={{
          filter: `drop-shadow(0px 0px 5px rgb(0 0 0 / 0.1))`,
        }}
      >
        <path
          d="M53.3763 81.72V32.7283L23.8438 19.3887V46.0679L53.3763 81.72Z"
          fill="#314756"
        />
        <path
          d="M53.375 32.7317V10.4229L82.9076 19.3159V46.0782L53.375 32.7317Z"
          fill="#F7F9FA"
        />
        <path
          d="M24.0312 19.4725L53.3732 10.4951V32.7278L24.0312 19.4725Z"
          fill="#CAD2D8"
        />
        <path
          d="M82.855 46.0399L53.375 81.6394V32.7275L82.855 46.0399Z"
          fill="#94A3AE"
        />
      </g>
      <rect x="16.8984" y="31.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="31.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="31.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="31.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="31.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="16.8984" y="35.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="35.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="35.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="35.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="32.8984" y="35.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="16.8984" y="39.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="39.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="39.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="43.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="43.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="43.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="32.8984" y="43.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="16.8984" y="47.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="47.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="47.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="51.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="51.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="51.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="51.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="16.8984" y="55.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="55.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="55.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="55.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="59.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="59.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="59.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="59.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="63.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="63.7256" width="2" height="2" fill="#94A3AE" />
      <rect x="28.8984" y="63.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="63.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="67.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="67.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="67.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="71.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="71.7256" width="2" height="2" fill="#94A3AE" />
      <rect x="24.8984" y="71.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="71.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="16.8984" y="75.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="75.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="75.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="75.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="16.8984" y="79.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="79.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="79.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="79.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="79.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="83.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="20.8984" y="83.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="83.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="83.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="83.7256" width="2" height="2" fill="#47FFBC" />
      <rect x="16.8984" y="87.7256" width="2" height="2" fill="#94A3AE" />
      <rect x="20.8984" y="87.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="24.8984" y="87.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="28.8984" y="87.7256" width="2" height="2" fill="#E6EAED" />
      <rect x="32.8984" y="87.7256" width="2" height="2" fill="#47FFBC" />
    </svg>
  );
};
