import { config, Container } from "react-awesome-styled-grid";
import styled from "styled-components";
import { CopyContainer } from ".";
import { H2, H3 } from "../../components/Heading";
import { Stack } from "../../components/Stack";
import { colors } from "../../theme";

const FoundersContainer = styled.div`
  padding: 40px 0;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
`;

const FounderCards = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;

  ${(props) => config(props).media["md"]`
    grid-template-columns: 1fr 1fr;
  `}
`;

const FounderCard = styled.div`
  align-items: flex-start;
  background-color: white;
  padding: 32px 40px;

  img {
    background-color: ${colors.gray900};
  }

  ${(props) => config(props).media["xs"]`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  `}

  ${(props) => config(props).media["md"]`
    display: flex;
    gap: 32px;
  `}
`;

const Role = styled.p`
  color: ${colors.gray700};
  font-family: InterRegular;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 2.16px;
  margin: 0;
  text-transform: uppercase;
`;

const P = styled.p`
  margin: 0;
`;

export const Founders: React.FC = () => {
  return (
    <Container>
      <FoundersContainer>
        <CopyContainer>
          <H2>Founded on a decade of blockchain experience</H2>
        </CopyContainer>
        <FounderCards>
          <FounderCard>
            <img
              alt="Manthan Dave Profile"
              src="../images/profile-pictures/manthan-dave.jpg"
            />
            <Stack gap="16px">
              <div>
                <Role>Co-founder, Co-CEO</Role>
                <H3>Manthan Dave</H3>
              </div>
              <P>
                Manthan is a seasoned technology leader. His expertise spans
                blockchain and fintech, with notable roles at Ripple and Ava
                Labs, where he contributed to decentralized payment systems and
                Ethereum virtual machine technologies. Previously at Capital
                One, he led innovations in identity management and multi-factor
                authentication. Manthan’s career is marked by a deep commitment
                to advancing the digital asset and blockchain sectors.
              </P>
            </Stack>
          </FounderCard>
          <FounderCard>
            <img
              alt="Thomas Kiddle Profile"
              src="../images/profile-pictures/thomas-kiddle.png"
            />
            <Stack gap="16px">
              <div>
                <Role>Co-founder, Co-CEO</Role>
                <H3>Thomas Kiddle</H3>
              </div>
              <P>
                Thomas is a seasoned software engineer and entrepreneur with a
                robust background in e-commerce, payments, and blockchain. With
                nearly two decades of experience, Thomas has worked at notable
                companies such as BT, Apple, and Ripple. Driven by a passion for
                advancing the digital economy, Thomas co-founded Palisade, a
                fully regulated digital asset custody provider.
              </P>
            </Stack>
          </FounderCard>
        </FounderCards>
      </FoundersContainer>
    </Container>
  );
};
