import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const XLogo: React.FC<Props> = ({ color = colors.gray700, ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 -2 31 30"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2092_1455)">
        <path
          d="M16.9029 12.5075L27.1071 0.651367H24.689L15.8287 10.9458L8.752 0.651367H0.589844L11.2912 16.2184L0.589844 28.6514H3.00805L12.3648 17.7801L19.8383 28.6514H28.0005L16.9023 12.5075H16.9029ZM13.5908 16.3556L12.5065 14.8055L3.87937 2.47092H7.5936L14.5558 12.4253L15.6401 13.9754L24.6902 26.9146H20.976L13.5908 16.3562V16.3556Z"
          fill="#314756"
        />
      </g>
      <defs>
        <clipPath id="clip0_2092_1455">
          <rect
            width="27.4106"
            height="30"
            fill="white"
            transform="translate(0.589844 0.651367)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
