import styled from "styled-components";

interface Props {
  direction?: "h" | "v";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "4xl" | "5xl";
}

const SIZES = {
  "5xl": "80px",
  "4xl": "64px",
  "2xl": "48px",
  xl: "32px",
  lg: "24px",
  md: "16px",
  sm: "8px",
  xs: "4px",
};

export const Spacer = styled.div<Props>`
  ${({ size = "xl", direction = "h" }: Props) => {
    if (direction === "v") {
      return `height: ${SIZES[size!]}`;
    } else {
      return `width: ${SIZES[size!]}`;
    }
  }}
`;
