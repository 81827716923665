import styled from "styled-components";

export const UnorderedList = styled("ul")`
  padding: 0px;
  margin: 0px;
`;
export const ListItem = styled("li")`
  list-style: none;
  display: inline-block;
  width: 100%;
  font-family: InterRegular;

  & + & {
    margin-top: 8px;
  }
`;
