import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const LinkedInLogo: React.FC<Props> = ({
  color = colors.white,
  ...rest
}) => {
  return (
    <svg
      width="31"
      height="33"
      viewBox="0 0 31 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2092_1453)">
        <path
          d="M28.4732 2.65137H3.06975C1.90573 2.65137 0.959961 3.55762 0.959961 4.67012V28.6326C0.959961 29.7451 1.90573 30.6514 3.06975 30.6514H28.4732C29.6372 30.6514 30.5896 29.7451 30.5896 28.6326V4.67012C30.5896 3.55762 29.6372 2.65137 28.4732 2.65137ZM9.91499 26.6514H5.52345V13.2889H9.9216V26.6514H9.91499ZM7.71922 11.4639C6.31049 11.4639 5.17292 10.3826 5.17292 9.05762C5.17292 7.73262 6.31049 6.65137 7.71922 6.65137C9.12134 6.65137 10.2655 7.73262 10.2655 9.05762C10.2655 10.3889 9.12795 11.4639 7.71922 11.4639ZM26.3766 26.6514H21.9851V20.1514C21.9851 18.6014 21.952 16.6076 19.7033 16.6076C17.415 16.6076 17.0645 18.2951 17.0645 20.0389V26.6514H12.6729V13.2889H16.8859V15.1139H16.9454C17.534 14.0639 18.9692 12.9576 21.1055 12.9576C25.5499 12.9576 26.3766 15.7264 26.3766 19.3264V26.6514Z"
          fill="#314756"
        />
      </g>
      <defs>
        <clipPath id="clip0_2092_1453">
          <rect
            width="29.6296"
            height="32"
            fill="white"
            transform="translate(0.959961 0.651367)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
