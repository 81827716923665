import React from "react";
import { Col, Container, Row, Visible } from "react-awesome-styled-grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import { Card } from "../../components/Card";
import useEventTracker from "../../components/GaEvent";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { SEO } from "../../components/SEO";
import { Spacer } from "../../components/Spacer";
import { Text } from "../../components/Text";
import { Document } from "../../components/icons/Document";
import { Legal } from "../../components/icons/Legal";
import { colors } from "../../theme";

interface Props {
  listLoadable: any;
}

export const ShadowCard = styled(Card)`
  box-shadow: 4px 24px 27px -6px #d9dee669;
`;

export const Heading = styled("h2")`
  color: ${colors.gray700};
  font-family: KurrentBold;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 54px;
`;

export const EmailLink = styled("a")`
  margin-bottom: 0px;
  font-family: KurrentBold;
  color: ${colors.gray700};
  font-size: 20px;
  line-height: 48px;
`;

const ListTitle = styled("span")`
  color: ${colors.gray700};
  font-family: "InterSemiBold";
  display: block;
  font-size: 20px;
  margin-bottom: 16px;
`;

const List = styled("ul")`
  margin: 0px;
  padding: 0px;
`;

const ListLink = styled(Link)`
  color: ${colors.gray700};
  font-family: "InterRegular";
  font-size: 16px;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  display: block;

  & + & {
    margin-top: 16px;
  }
`;

export const LegalArticleListPageView: React.FC<Props> = (props) => {
  const { listLoadable } = props;
  const gaEventTracker = useEventTracker("CONTACT:INTRO_CONTENT");

  const renderLoadingState = () => {
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <ActivityIndicator />
          </Col>
        </Row>
      </Container>
    );
  };

  if (listLoadable.isLoading) {
    return renderLoadingState();
  }

  if (!listLoadable.data) {
    return null;
  }

  const articleList = listLoadable?.data?.legals.data;

  return (
    <>
      <SEO
        description="You can reach us anytime via, hello@palisade.co"
        title="Legal documents"
      />
      <Container>
        <Spacer size="4xl" direction="v" />
        <Col lg={11} offset={0.5}>
          <Row>
            <RestoreScrollTop />
            <Col md={5} lg={5} offset={{ lg: 0 }}>
              <ShadowCard>
                <Visible md lg xl>
                  <Legal />
                </Visible>
                <Spacer size="sm" direction="v" />
                <Heading>Legal documents</Heading>
                <Text
                  fontSize="20px"
                  lineHeight="40px"
                  fontFamily="InterRegular"
                >
                  You can reach us anytime,{" "}
                  <EmailLink
                    href="mailto:hello@palisade.co"
                    onClick={() => gaEventTracker("MAIL_TO", "CLICK_LINK")}
                  >
                    hello@palisade.co
                  </EmailLink>
                </Text>
                <Spacer size="4xl" direction="v" />
                <ListTitle>Documents</ListTitle>
                <List>
                  {articleList.map((item: any, index: number) => {
                    return (
                      <ListItem key={`legal-document_${index}`}>
                        <ListLink to={`/legal/${item.id}`}>
                          <span
                            style={{
                              display: "inline",
                              float: "left",
                              marginRight: "10px",
                            }}
                          >
                            <Document />
                          </span>
                          <span style={{ display: "inline" }}>
                            {item?.attributes.title}
                          </span>
                        </ListLink>
                      </ListItem>
                    );
                  })}
                </List>
              </ShadowCard>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};
