import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const PolkaDot: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="87"
      height="101"
      viewBox="0 0 87 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M43.5005 33.8171C49.3253 33.8171 54.0472 31.0936 54.0472 27.7341C54.0472 24.3746 49.3253 21.6511 43.5005 21.6511C37.6758 21.6511 32.9539 24.3746 32.9539 27.7341C32.9539 31.0936 37.6758 33.8171 43.5005 33.8171Z"
        fill="#314756"
      />
      <path
        d="M43.5005 79.6511C49.3253 79.6511 54.0472 76.9277 54.0472 73.5682C54.0472 70.2086 49.3253 67.4852 43.5005 67.4852C37.6758 67.4852 32.9539 70.2086 32.9539 73.5682C32.9539 76.9277 37.6758 79.6511 43.5005 79.6511Z"
        fill="#314756"
      />
      <path
        d="M28.8031 42.2248C31.7154 37.2224 31.6965 31.8046 28.7608 30.1237C25.8251 28.4428 21.0844 31.1354 18.172 36.1377C15.2596 41.1401 15.2785 46.5579 18.2142 48.2388C21.1499 49.9197 25.8907 47.2271 28.8031 42.2248Z"
        fill="#314756"
      />
      <path
        d="M68.8245 65.1516C71.7369 60.1493 71.7196 54.7323 68.7859 53.0526C65.8522 51.3728 61.113 54.0663 58.2006 59.0687C55.2882 64.071 55.3055 69.4879 58.2392 71.1677C61.173 72.8475 65.9121 70.154 68.8245 65.1516Z"
        fill="#314756"
      />
      <path
        d="M28.7623 71.1663C31.698 69.4854 31.7169 64.0675 28.8045 59.0652C25.8921 54.0628 21.1513 51.3703 18.2157 53.0512C15.28 54.7321 15.2611 60.1499 18.1734 65.1523C21.0858 70.1546 25.8266 72.8472 28.7623 71.1663Z"
        fill="#314756"
      />
      <path
        d="M68.7884 48.2527C71.7221 46.5729 71.7394 41.156 68.827 36.1536C65.9146 31.1513 61.1754 28.4578 58.2417 30.1375C55.308 31.8173 55.2907 37.2342 58.2031 42.2366C61.1155 47.2389 65.8546 49.9324 68.7884 48.2527Z"
        fill="#314756"
      />
    </svg>
  );
};
