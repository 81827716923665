import { gql } from "@apollo/client";

export const QUERY_GET_ARTICLE = gql`
  query GetArticle($slug: String!) {
    articles(
      filters: { slug: { eq: $slug } }
      sort: "createdAt"
      pagination: { limit: 1 }
    ) {
      data {
        id
        attributes {
          title
          createdAt
          description
          body
          slug
          youtube_video_id
          category {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const QUERY_LIST_ARTICLES = gql`
  query ListArticles {
    articles(sort: "createdAt:desc") {
      data {
        id
        attributes {
          slug
          youtube_video_id
          title
          createdAt
        }
      }
    }
  }
`;
