import React from "react";
import { Container } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Spacer } from "../Spacer";
import { VentureBacked } from "../VentureBacked";

interface Props {
  children: any;
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: 80px;
`;

const ContentWrapper = styled.div`
  min-height: calc(100vh - 323px);
`;

const StickyHeader = styled.div`
  background-color: ${colors.gray100};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`;

export const Layout: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      <StickyHeader>
        <Container>
          <Header />
        </Container>
      </StickyHeader>
      <ContentWrapper>{children}</ContentWrapper>
      <Spacer direction="v" size="5xl" />
      <Spacer direction="v" size="5xl" />
      <VentureBacked />
      <div
        style={{
          background: colors.gray100,
        }}
      >
        <div
          style={{
            paddingTop: "64px",
            background:
              "linear-gradient(180deg, rgba(240, 244, 246, 1) 0%, rgba(240, 244, 246, 0) 100%)",
            mixBlendMode: "multiply",
          }}
        >
          <Container>
            <Footer />
          </Container>
        </div>
      </div>
    </Wrapper>
  );
};
