import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const CheckInCircle: React.FC<Props> = ({
  color = colors.primary400,
  ...rest
}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.5 8.15106C14.5 11.7409 11.5899 14.6511 8 14.6511C4.41015 14.6511 1.5 11.7409 1.5 8.15106C1.5 4.56121 4.41015 1.65106 8 1.65106C11.5899 1.65106 14.5 4.56121 14.5 8.15106Z"
        fill="#314756"
        stroke="#183142"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8568 6.0511L7.30324 10.6047C7.10798 10.7999 6.79139 10.7999 6.59613 10.6047L4.14258 8.1511L4.84968 7.44399L6.94969 9.54399L11.1497 5.34399L11.8568 6.0511Z"
        fill="white"
      />
    </svg>
  );
};
