import React from "react";
import { config, Container } from "react-awesome-styled-grid";
import styled from "styled-components";

import { AnchorButton } from "../AnchorButton";
import { Card } from "../Card";
import { Code } from "../Code";
import useEventTracker from "../GaEvent";
import { ExternalLinkIcon } from "../icons/ExternalLinkIcon";
import { Spacer } from "../Spacer";

interface Props {}

const FeaturesHeading = styled.h2`
  font-family: "KurrentSemiBold";
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 1.5;
  margin: 0;
`;

const FeatureCopy = styled.p`
  margin: 0;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${(props) => config(props).media["md"]`
    grid-template-columns: 3fr 2fr;
  `}
`;

const Frame = styled.div`
  padding: 32px;
  ${(props) => config(props).media["md"]`
    padding: 40px 48px 40px 64px;
  `}
`;

export const CodeExample: React.FC<Props> = (props) => {
  const gaEventTracker = useEventTracker("HOME:CODE_EXAMPLE");

  return (
    <Container>
      <Card padding="0">
        <Layout>
          <Frame>
            <FeaturesHeading>Straightforward integration</FeaturesHeading>
            <Spacer direction="v" size="md" />
            <FeatureCopy>
              Stop worying about the complexities of providing custody yourself
              – we’ve done that for you. Integrate rapidly using the Palisade{" "}
              <abbr title="Software Development Kit">SDK</abbr> or REST API.
            </FeatureCopy>
            <Spacer direction="v" size="xl" />
            <AnchorButton
              href="https://developers.palisade.co/docs/overview"
              target="_blank"
              level="tertiary"
              onClick={() => gaEventTracker("READ_THE_DOCS", "CLICK_LINK")}
            >
              Read the docs
              <Spacer size="sm" />
              <ExternalLinkIcon />
            </AnchorButton>
          </Frame>
          <Code />
        </Layout>
      </Card>
    </Container>
  );
};
