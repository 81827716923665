import * as React from 'react';
import styled, { keyframes } from 'styled-components';

interface ButtonActivityIndicatorProps {
  color?: string;
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ButtonSpinner = styled.div<ButtonActivityIndicatorProps>`
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  transform: translateZ(0);
  box-sizing: border-box;
  animation: ${rotate360} 1s linear infinite;
  border: 2px solid transparent;
  border-color: ${({ color }) => `transparent ${color} ${color}`};
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const ButtonActivityIndicator: React.FC<ButtonActivityIndicatorProps> = (
  props
) => {
  const { color } = props;

  return (
    <Wrapper>
      <ButtonSpinner color={color} />
    </Wrapper>
  );
};
