import React from "react";
import { Col, config, Container, Row } from "react-awesome-styled-grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import { Spacer } from "../../components/Spacer";
import { colors } from "../../theme";

interface Props {
  postListLoadable: any;
}

const ReadMoreLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: InterMedium;
  color: ${colors.white};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TitleLink = styled(Link)`
  color: ${colors.white};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const PostTitle = styled.h3`
  color: ${colors.white};
  font-size: 26px;
  font-family: KurrentSemiBold;
  font-weight: normal;
  margin: 0px;
`;

const PostDescription = styled.p`
  color: ${colors.white};
  font-size: 18px;
  font-family: InterRegular;
  font-weight: normal;
  margin: 0px;
`;

const ArticleGrid = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;

  ${(props) => config(props).media["md"]`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const Article = styled.div`
  background: rgb(49, 71, 86, 0.15);
  border-radius: 4px;
  border: 1px solid ${colors.gray700};
  padding: 32px;
  position: relative;
`;

export const BlogPostHighlightsView: React.FC<Props> = (props) => {
  const { postListLoadable } = props;

  if (postListLoadable.error) {
    return null;
  }

  const renderLoadingState = () => {
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{ position: "relative" }}>
            <ActivityIndicator />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div
      style={{
        padding: "48px 0px",
        background:
          "url(../images/v2/background-full-pattern-dark.png) repeat center center",
      }}
    >
      <Container>
        {postListLoadable.isLoading ? (
          renderLoadingState()
        ) : (
          <ArticleGrid>
            {postListLoadable.data?.articles.data.map(
              (article: any, index: number) => {
                const attributes = article.attributes;

                return (
                  <Article key={`blog-post_${index}`}>
                    <TitleLink to={`/blog/${article.id}`}>
                      <PostTitle>{attributes.title}</PostTitle>
                    </TitleLink>
                    <Spacer direction="v" size="md" />
                    <PostDescription>
                      {`${attributes.description.substring(0, 80)}...`}
                    </PostDescription>
                    <Spacer direction="v" />
                    <ReadMoreLink to={`/blog/${article.id}`}>
                      Read more
                    </ReadMoreLink>
                  </Article>
                );
              }
            )}
          </ArticleGrid>
        )}
      </Container>
    </div>
  );
};
