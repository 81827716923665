import { useQuery } from "@apollo/client";
import * as React from "react";
import { createGraphQlLoadable } from "../../graphql/utils";
import { QUERY_LIST_LEGAL_DOCUMENTS } from "./queries";
import { LegalArticleListPageView } from "./view";

export const LegalArticleListPageContainer: React.FC = () => {
  const listQuery = useQuery(QUERY_LIST_LEGAL_DOCUMENTS, {
    fetchPolicy: "network-only",
  });

  const listLoadable = createGraphQlLoadable({
    isLoading: listQuery.loading,
    error: listQuery.error,
    data: listQuery.data,
  });

  return <LegalArticleListPageView listLoadable={listLoadable} />;
};
