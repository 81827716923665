export const colors = {
  white: "#FFFFFF",
  black: "#000000",

  gray25: "#FBFCFD",
  gray50: "#F7F9FA",
  gray100: "#F0F4F6",
  gray200: "#E6EAED",
  gray300: "#CAD2D8",
  gray400: "#94A3AE",
  gray500: "#657683",
  gray600: "#495D6A",
  gray700: "#314756",
  gray800: "#183142",
  gray900: "#001B2E",

  primary25: "#F6FFFC",
  primary50: "#D2FFEF",
  primary100: "#AFFFE2",
  primary200: "#8CFFD6",
  primary300: "#68FFC9",
  primary400: "#45FFBC",
  primary500: "#2EEBB2",
  primary600: "#1BD6A7",
  primary700: "#0AC29B",
  primary800: "#00AD8E",
  primary900: "#009980",

  error25: "#FFF8F8",
  error50: "#FFDCDE",
  error100: "#FFBFC3",
  error200: "#FFA3A9",
  error300: "#FF878E",
  error400: "#FF6B74",
  error500: "#EB555F",
  error600: "#D6414C",
  error700: "#C2303B",
  error800: "#AD212C",
  error900: "#99141F",

  warning25: "#FFFBF6",
  warning50: "#FFEBD3",
  warning100: "#FFDBAF",
  warning200: "#FFCA8C",
  warning300: "#FFBA69",
  warning400: "#FFAA46",
  warning500: "#EB8C2F",
  warning600: "#D66C1C",
  warning700: "#C24A0B",
  warning800: "#AD2900",
  warning900: "#990A00",

  success25: "#F4FDFB",
  success50: "#C8F5EA",
  success100: "#9DEDD9",
  success200: "#72E6C8",
  success300: "#46DEB8",
  success400: "#1BD6A7",
  success500: "#08D6A2",
  success600: "#00D39C",
  success700: "#00C294",
  success800: "#00AD8B",
  success900: "#009980",
};
