import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { colors } from "../../theme";
import { withPathParams } from "../../utils";
import { BlankButton } from "../BlankButton";
import { Flex } from "../Flex";
import { Spacer } from "../Spacer";
import { BrandSquare } from "../icons/BrandSquare";
import { Cross } from "../icons/Cross";
import { ExternalLinkIcon } from "../icons/ExternalLinkIcon";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

const MobileNavigationBackdrop = styled("div")``;

const MobileNavigationWrapper = styled("div")`
  background: ${colors.gray900};
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0.98;
  padding: 32px 1rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const MobileNavigationNav = styled.nav`
  margin-top: 56px;
  padding: 0 40px;
`;

const MobileNavigationList = styled("ul")`
  border-bottom: 1px solid ${colors.gray600};
  display: grid;
  grid-columns: 1;
  gap: 32px;
  margin: 0;
  padding-bottom: 40px;
`;

const MobileNavigationListItem = styled("li")`
  display: block;
  list-style: none;
`;

const navLinkBaseStyle = css`
  color: ${colors.white};
  font-family: InterMedium;
  font-size: 20px;
  line-height: 150%;
  opacity: 1;
  text-decoration: none;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
`;

const MobileNavigationLink = styled(Link)<{ isActive?: boolean }>`
  ${navLinkBaseStyle}
  border-bottom: 1px solid ${({ isActive }) =>
    isActive ? "white" : "transparent"};
  padding-bottom: 6px;
`;

const MobileNavigationLinkExternal = styled.a`
  ${navLinkBaseStyle}
  align-items: center;
  display: inline-flex;
`;

const StyledBlankButton = styled(BlankButton)`
  opacity: 1;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
`;

export const MobileNavigation: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: globalThis.MouseEvent) {
      if (
        wrapperRef?.current &&
        !wrapperRef.current.contains(event.target as any)
      ) {
        onClose();
      }
    }

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [wrapperRef, onClose]);

  interface NavItem {
    path: string;
    name: string;
  }

  const navItems: NavItem[] = [
    { path: PATHS.home, name: "Home" },
    { path: withPathParams(PATHS.blogPost, { id: 1 }), name: "Resources" },
    { path: PATHS.legal, name: "Legal" },
  ];

  return isOpen ? (
    <>
      <MobileNavigationWrapper ref={wrapperRef}>
        <Flex justifyContent="space-between" alignItems="center">
          <BrandSquare color={colors.white} />
          <StyledBlankButton onClick={onClose}>
            <Cross color="white" />
          </StyledBlankButton>
        </Flex>
        <MobileNavigationNav>
          <MobileNavigationList>
            {navItems.map((navItem: NavItem, index) => {
              return (
                <MobileNavigationListItem key={`mobile-nav_${index}`}>
                  <MobileNavigationLink
                    onClick={onClose}
                    isActive={location.pathname === navItem.path}
                    to={navItem.path}
                  >
                    {navItem.name}
                  </MobileNavigationLink>
                </MobileNavigationListItem>
              );
            })}

            <MobileNavigationListItem>
              <MobileNavigationLinkExternal
                href="https://developers.palisade.co/"
                target="_blank"
              >
                Developer Docs
                <Spacer size="sm" />
                <ExternalLinkIcon color={colors.white} />
              </MobileNavigationLinkExternal>
            </MobileNavigationListItem>
          </MobileNavigationList>
        </MobileNavigationNav>
      </MobileNavigationWrapper>
      <MobileNavigationBackdrop />
    </>
  ) : null;
};
