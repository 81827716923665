import React from "react";
import {
  Col,
  config,
  Container,
  Row,
  Visible,
} from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Flex } from "../Flex";
import { CheckInCircle } from "../icons/CheckInCircle";
import { EnterprisePlan } from "../icons/EnterprisePlan";
import { SoloPlan } from "../icons/SoloPlan";
import { ListItem, UnorderedList } from "../List";
import { Spacer } from "../Spacer";
import { Text } from "../Text";

interface Props {}

export const FeaturesHeading = styled("h2")`
  color: ${colors.gray700};
  font-family: KurrentSemiBold;
  margin: 0;
  padding: 0;

  font-size: 36px;
  line-height: 42px;

  ${(props) => config(props).media["lg"]`
    font-size: 48px;
    line-height: 54px;
  `}
`;

export const Wrapper = styled.div`
  padding: 0px 32px;
`;

export const GradientWrapper = styled.div`
  border-radius: 5px;
  position: relative;
  padding: 32px;
  border: 1px solid ${colors.gray200};
  background: rgb(255, 255, 255) 0.1;
  box-shadow: 20px 5px 22px -14px #d7dde6;
`;

export const ListTextWrapper = styled.span`
  display: inline-block;
  margin-left: 12px;
`;

export const GradientOverlay = styled.div`
  background: rgba(240, 244, 246);
  background: linear-gradient(
    175deg,
    rgba(240, 244, 246, 0) 0%,
    rgba(240, 244, 246, 1) 100%
  );
  position: absolute;
  left: 0;
  height: 50%;
  right: 0;
  bottom: 0;
  clip-path: inset(0px -34px 0px 0px);
`;

export const PricingExample: React.FC<Props> = (props) => {
  return (
    <Container>
      <Row>
        <Col md={3.5} lg={5}>
          <Wrapper>
            <Spacer direction="v" size="sm" />
            <FeaturesHeading>Scalable pricing</FeaturesHeading>
            <Spacer direction="v" size="md" />
            <Text fontFamily="InterRegular" fontSize="16px" lineHeight="1.5">
              We believe that digital asset security should be available to
              everyone. That’s why we’re working on pricing plans that scale for
              teams of all sizes.
            </Text>
            <Spacer direction="v" />
            <Text
              fontFamily="InterRegular"
              fontSize="16px"
              lineHeight="32px"
              fontStyle="italic"
            >
              * Starting from
            </Text>
          </Wrapper>
          <Visible xs sm>
            <Spacer direction="v" />
          </Visible>
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4.25}
          lg={3.5}
          justify="flex-start"
          align="flex-start"
        >
          <GradientWrapper>
            <Flex flexDirection="column" justifyContent="center">
              <SoloPlan />
              <Spacer direction="v" size="md" />
              <Text
                style={{ letterSpacing: "2px" }}
                fontSize="16px"
                fontFamily="InterLight"
                color={colors.gray700}
              >
                LITE
              </Text>
              <Spacer direction="v" size="sm" />

              <Text
                fontSize="30px"
                fontFamily="KurrentBold"
                color={colors.gray700}
              >
                £250/mth*
              </Text>
              <Spacer direction="v" size="sm" />
              <Text fontSize="16px" color={colors.gray700}></Text>
              <Spacer direction="v" />
              <UnorderedList>
                <ListItem>
                  <CheckInCircle />
                  <ListTextWrapper>
                    Provision up to 2500 wallets
                  </ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle />
                  <ListTextWrapper>
                    Add up to 5 organisation users
                  </ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle />
                  <ListTextWrapper>Basic policy controls</ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle />
                  <ListTextWrapper>
                    Add up to 10 allowlist entries
                  </ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle />
                  <ListTextWrapper>9-5 support</ListTextWrapper>
                </ListItem>
              </UnorderedList>
            </Flex>
            <GradientOverlay />
          </GradientWrapper>
        </Col>

        <Col
          xs={12}
          sm={6}
          md={4.25}
          lg={3.5}
          justify="flex-start"
          align="flex-start"
        >
          <GradientWrapper>
            <Flex flexDirection="column" justifyContent="center">
              <EnterprisePlan />
              <Spacer direction="v" size="md" />
              <Text
                style={{ letterSpacing: "2px" }}
                fontSize="16px"
                fontFamily="InterLight"
                color={colors.gray700}
              >
                ENTERPRISE
              </Text>
              <Spacer direction="v" size="sm" />
              <Text
                fontSize="30px"
                fontFamily="KurrentBold"
                color={colors.gray700}
              >
                Custom pricing
              </Text>
              <Spacer direction="v" size="sm" />
              <Text fontSize="16px" color={colors.white}></Text>
              <Spacer direction="v" />
              <UnorderedList>
                <ListItem>
                  <CheckInCircle color={colors.success700} />
                  <ListTextWrapper>Provision unlimited wallets</ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle color={colors.success700} />
                  <ListTextWrapper>
                    Unlimited organisation users
                  </ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle color={colors.success700} />
                  <ListTextWrapper>Enhanced policy controls</ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle color={colors.success700} />
                  <ListTextWrapper>Unlimited allowlist entries</ListTextWrapper>
                </ListItem>
                <ListItem>
                  <CheckInCircle color={colors.success700} />
                  <ListTextWrapper>24x7 support</ListTextWrapper>
                </ListItem>
              </UnorderedList>
              <GradientOverlay />
            </Flex>
          </GradientWrapper>
        </Col>
      </Row>
    </Container>
  );
};
