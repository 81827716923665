import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Uniswap: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="98"
      height="101"
      viewBox="0 0 98 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M41.9845 29.3912C41.1737 29.2705 41.135 29.2303 41.5212 29.19C42.2547 29.0694 43.9536 29.2303 45.1505 29.5119C47.9305 30.1957 50.4401 31.9657 53.1042 35.0632L53.7992 35.9079L54.8031 35.747C59.0888 35.0229 63.4904 35.5861 67.1584 37.3561C68.1623 37.8388 69.7453 38.8043 69.9384 39.0456C70.0156 39.1261 70.1314 39.649 70.2086 40.172C70.4789 42.0627 70.3631 43.4706 69.7839 44.5567C69.475 45.1601 69.475 45.321 69.6681 45.844C69.8225 46.2463 70.2858 46.5279 70.7106 46.5279C71.6372 46.5279 72.6025 44.9992 73.0658 42.8672L73.2588 42.0224L73.6063 42.4247C75.5755 44.7176 77.1199 47.8956 77.3515 50.1483L77.4288 50.7517L77.0813 50.2287C76.5021 49.3035 75.9616 48.7001 75.228 48.1772C73.9152 47.2519 72.5252 46.9703 68.8573 46.7692C65.5368 46.5681 63.6449 46.2865 61.7916 45.6429C58.6255 44.5567 57.0039 43.1488 53.2587 37.9595C51.5984 35.6666 50.556 34.4195 49.5135 33.3736C47.2355 31.0807 44.9575 29.8739 41.9845 29.3912Z"
        fill="#314756"
      />
      <path
        d="M70.7492 34.5C70.8264 32.9714 71.0195 31.9657 71.4442 31.0405C71.5987 30.6784 71.7531 30.3566 71.7917 30.3566C71.8303 30.3566 71.7531 30.6382 71.6373 30.96C71.3284 31.845 71.2898 33.0921 71.4828 34.5C71.7531 36.3102 71.8689 36.5516 73.7222 38.5227C74.5716 39.4479 75.5755 40.6145 75.9616 41.0972L76.618 41.9822L75.9616 41.3386C75.1508 40.534 73.2975 39.0054 72.8728 38.8043C72.6025 38.6434 72.5639 38.6434 72.3709 38.8445C72.2164 39.0054 72.1778 39.2468 72.1778 40.4134C72.1392 42.2236 71.9075 43.3499 71.3284 44.5165C71.0195 45.1199 70.9809 44.9992 71.2512 44.3154C71.4442 43.7924 71.4828 43.5511 71.4828 41.8213C71.4828 38.3216 71.0967 37.4768 68.7415 36.0689C68.1623 35.7068 67.1585 35.1839 66.5793 34.9023C65.9615 34.6207 65.4982 34.3793 65.5368 34.3793C65.6141 34.2989 67.8921 34.9827 68.7801 35.385C70.1315 35.9482 70.3631 35.9884 70.5176 35.9482C70.6334 35.8275 70.7106 35.5057 70.7492 34.5Z"
        fill="#314756"
      />
      <path
        d="M43.5675 40.4938C41.9459 38.1607 40.9034 34.5402 41.1351 31.845L41.2123 31.0003L41.5984 31.0807C42.2934 31.2014 43.4903 31.6841 44.0695 32.0462C45.6139 33.0116 46.3089 34.3391 46.9653 37.6377C47.1583 38.6032 47.4286 39.7295 47.5444 40.0916C47.7375 40.695 48.4711 42.1029 49.0888 42.9879C49.5135 43.6315 49.2433 43.9533 48.278 43.8729C46.8108 43.712 44.8417 42.304 43.5675 40.4938Z"
        fill="#314756"
      />
      <path
        d="M68.78 57.9925C61.1352 54.7744 58.4325 51.9987 58.4325 47.2922C58.4325 46.6083 58.4711 46.0452 58.4711 46.0452C58.5097 46.0452 58.78 46.2865 59.1275 46.5681C60.6719 47.8554 62.4093 48.4185 67.2356 49.1426C70.0542 49.5851 71.6758 49.9069 73.143 50.4299C77.8149 52.039 80.7106 55.3376 81.4056 59.8028C81.5987 61.09 81.4828 63.5439 81.174 64.8311C80.9037 65.8368 80.1315 67.6872 79.9384 67.7275C79.8998 67.7275 79.8226 67.5263 79.8226 67.2045C79.7454 65.515 78.9346 63.9059 77.5832 62.6589C75.9616 61.2107 73.8766 60.1246 68.78 57.9925Z"
        fill="#314756"
      />
      <path
        d="M63.3747 59.32C63.2975 58.7166 63.1044 57.9523 62.9886 57.6305L62.7955 57.0271L63.143 57.4696C63.6449 58.073 64.031 58.7971 64.3785 59.8028C64.6488 60.5671 64.6488 60.8084 64.6488 62.0555C64.6488 63.2623 64.6102 63.5439 64.3785 64.2277C63.9924 65.3138 63.5291 66.0782 62.7569 66.9229C61.3669 68.4113 59.5522 69.2159 56.9654 69.5779C56.502 69.6181 55.1893 69.7388 54.031 69.8193C51.1352 69.9802 49.2047 70.302 47.4672 70.9456C47.2356 71.0261 47.0039 71.1065 46.9653 71.0663C46.8881 70.9858 48.085 70.2618 49.0502 69.779C50.4016 69.0952 51.7916 68.7331 54.8418 68.17C56.3476 67.9286 57.892 67.6068 58.2781 67.4459C62.1005 66.1988 63.9924 63.1014 63.3747 59.32Z"
        fill="#314756"
      />
      <path
        d="M66.8882 65.7966C65.8843 63.5036 65.6527 61.3314 66.1932 59.2798C66.2704 59.0786 66.3477 58.8775 66.4249 58.8775C66.5021 58.8775 66.7338 58.9982 66.9654 59.1591C67.4288 59.4809 68.394 60.0441 70.8651 61.452C73.9925 63.222 75.7686 64.5897 77.0041 66.1586C78.0852 67.5263 78.7416 69.0952 79.0505 71.0261C79.2435 72.1122 79.1277 74.7269 78.8574 75.813C78.008 79.2323 76.0775 81.9678 73.2589 83.5366C72.8342 83.778 72.4867 83.9389 72.4481 83.9389C72.4095 83.9389 72.5639 83.5366 72.7956 83.0539C73.7222 81.0023 73.8381 79.0312 73.1431 76.8187C72.7184 75.451 71.8303 73.8017 70.0543 71.0261C67.9307 67.8079 67.4288 66.9631 66.8882 65.7966Z"
        fill="#314756"
      />
      <path
        d="M38.1235 78.106C40.9807 75.6119 44.4942 73.8419 47.7375 73.2788C49.1274 73.0374 51.4441 73.1179 52.7182 73.4799C54.7646 74.0431 56.6179 75.2499 57.5831 76.7383C58.5098 78.1864 58.9345 79.4335 59.3592 82.2091C59.5136 83.2953 59.7067 84.4216 59.7453 84.663C60.0542 86.1112 60.6719 87.2375 61.4441 87.8409C62.6411 88.7661 64.726 88.8064 66.7724 88.0018C67.1199 87.8811 67.4287 87.7605 67.4287 87.8007C67.506 87.8811 66.4635 88.6052 65.7685 88.9673C64.8032 89.4902 64.031 89.6511 62.9886 89.6511C61.1353 89.6511 59.5522 88.6454 58.2781 86.6341C58.0078 86.2318 57.4673 85.0653 57.004 83.9791C55.6526 80.7207 54.9576 79.7553 53.3746 78.6692C51.9846 77.7439 50.2085 77.5428 48.8572 78.2267C47.0811 79.1117 46.6178 81.4851 47.8533 82.9332C48.3552 83.5366 49.2819 84.0194 50.0541 84.14C51.4827 84.3412 52.7182 83.1746 52.7182 81.6862C52.7182 80.7207 52.3707 80.1576 51.4441 79.7151C50.2085 79.1519 48.8572 79.7955 48.8958 81.0426C48.8958 81.5655 49.1275 81.8873 49.6294 82.1287C49.9383 82.2896 49.9383 82.2896 49.7066 82.2494C48.5869 82.008 48.3166 80.5598 49.2047 79.6346C50.2858 78.5083 52.5638 78.991 53.336 80.5598C53.6449 81.2035 53.6835 82.4907 53.4132 83.2953C52.7568 85.0653 50.9035 85.9905 49.0116 85.4675C47.7375 85.1055 47.1969 84.7434 45.6525 83.0941C42.9498 80.1978 41.9073 79.6346 38.0463 79.0312L37.3127 78.9105L38.1235 78.106Z"
        fill="#314756"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8145 13.3809C26.8107 24.7651 40.6718 42.4649 41.3668 43.4304C41.9459 44.2349 41.7143 44.9992 40.749 45.5624C40.2085 45.8842 39.0888 46.2061 38.5482 46.2061C37.9304 46.2061 37.1968 45.8842 36.6949 45.3613C36.3474 44.9992 34.8416 42.7063 31.4439 37.1952C28.857 32.9714 26.6562 29.4717 26.6176 29.4314C26.4632 29.351 26.4632 29.351 31.1736 38.1204C34.1466 43.6315 35.1119 45.6026 35.1119 45.844C35.1119 46.367 34.9575 46.6486 34.3397 47.3726C33.2972 48.5794 32.8339 49.9472 32.4864 52.8033C32.1003 55.9812 31.0578 58.2339 28.0848 62.0555C26.3474 64.3082 26.0771 64.7104 25.6524 65.6357C25.1118 66.762 24.9574 67.4056 24.8802 68.8538C24.8029 70.3824 24.9574 71.3479 25.3821 72.7961C25.7682 74.0833 26.1929 74.9281 27.2354 76.5774C28.1234 78.0256 28.664 79.1117 28.664 79.5139C28.664 79.8358 28.7412 79.8358 30.1312 79.5139C33.4516 78.7094 36.193 77.3417 37.6988 75.6522C38.6254 74.6063 38.8571 74.0431 38.8571 72.5949C38.8571 71.6697 38.8185 71.4686 38.5868 70.9054C38.2007 70.0204 37.4671 69.2963 35.8841 68.17C33.7991 66.6816 32.9111 65.4748 32.6794 63.8657C32.4864 62.498 32.7181 61.5727 33.8764 59.0384C35.0733 56.4237 35.3822 55.3376 35.5752 52.6826C35.691 50.9931 35.8841 50.3092 36.3474 49.7863C36.8494 49.2231 37.2741 49.0219 38.471 48.861C40.4401 48.5794 41.7143 48.0565 42.7181 47.0508C43.6062 46.2061 43.9923 45.3613 44.0309 44.1143L44.0695 43.189L43.5676 42.6259C41.7529 40.4536 16.6175 11.6511 16.5017 11.6511C16.4631 11.6511 17.0809 12.4154 17.8145 13.3809ZM29.6678 70.5836C30.0926 69.8193 29.8609 68.8538 29.1659 68.3711C28.5095 67.9286 27.5057 68.1297 27.5057 68.7331C27.5057 68.894 27.5829 69.0549 27.8145 69.1354C28.162 69.3365 28.2006 69.5377 27.9304 69.9802C27.6601 70.4227 27.6601 70.8249 28.0076 71.1065C28.5481 71.549 29.2817 71.3077 29.6678 70.5836Z"
        fill="#314756"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.305 49.4242C44.3783 49.7058 43.4903 50.7517 43.22 51.7976C43.0656 52.4412 43.1428 53.6078 43.4131 53.9699C43.8378 54.533 44.2239 54.6939 45.305 54.6939C47.4286 54.6939 49.2433 53.7285 49.4363 52.5619C49.6294 51.5965 48.8185 50.269 47.6988 49.6656C47.1197 49.3438 45.9228 49.2231 45.305 49.4242ZM47.7761 51.4356C48.0849 50.9528 47.9691 50.4299 47.39 50.0678C46.3475 49.384 44.7645 49.9472 44.7645 50.9931C44.7645 51.516 45.5753 52.0792 46.3475 52.0792C46.8494 52.0792 47.5444 51.7574 47.7761 51.4356Z"
        fill="#314756"
      />
    </svg>
  );
};
