import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const FeatureApprovals: React.FC<Props> = ({
  color = "#183142",
  ...rest
}) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        style={{
          filter: `drop-shadow(0px 0px 5px rgb(0 0 0 / 0.1))`,
        }}
      >
        <rect
          width="30.8803"
          height="30.8803"
          transform="matrix(-3.1864e-05 1 -0.866009 0.500028 75.4922 5.19629)"
          fill="#314756"
        />
        <rect
          width="30.8803"
          height="30.8803"
          transform="matrix(3.17982e-05 1 -0.86601 -0.500028 75.4844 36.0781)"
          fill="#F7F9FA"
        />
        <rect
          width="30.8803"
          height="30.8803"
          transform="matrix(0.86601 0.500028 3.17982e-05 1 37 33.042)"
          fill="#94A3AE"
        />
        <rect
          width="30.8803"
          height="30.8803"
          transform="matrix(-3.1864e-05 1 -0.866009 0.500028 63.7422 48.4834)"
          fill="#CAD2D8"
        />
      </g>
      <rect
        x="79.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 79.375 57.958)"
        fill="#94A3AE"
      />
      <rect
        x="79.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 79.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="79.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 79.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="79.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 79.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="75.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 75.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="75.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 75.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="75.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 75.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="75.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 75.375 69.958)"
        fill="#47FFBC"
      />
      <rect
        x="75.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 75.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="71.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 71.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="71.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 71.375 61.958)"
        fill="#47FFBC"
      />
      <rect
        x="71.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 71.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="71.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 71.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="67.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 67.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="67.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 67.375 61.958)"
        fill="#94A3AE"
      />
      <rect
        x="67.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 67.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="67.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 67.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="63.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 63.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="63.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 63.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="63.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 63.375 65.958)"
        fill="#47FFBC"
      />
      <rect
        x="63.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 63.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="63.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 63.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="59.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 59.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="59.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 59.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="59.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 59.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="59.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 59.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="55.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 55.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="55.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 55.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="55.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 55.375 65.958)"
        fill="#94A3AE"
      />
      <rect
        x="55.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 55.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="55.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 55.375 73.958)"
        fill="#47FFBC"
      />
      <rect
        x="51.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 51.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="51.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 51.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="51.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 51.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="47.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 47.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="47.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 47.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="47.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 47.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="47.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 47.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="47.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 47.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="43.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 43.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="43.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 43.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="43.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 43.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="43.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 43.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="43.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 43.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="39.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 39.375 57.958)"
        fill="#47FFBC"
      />
      <rect
        x="39.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 39.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="39.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 39.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="39.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 39.375 69.958)"
        fill="#47FFBC"
      />
      <rect
        x="39.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 39.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="35.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 35.375 57.958)"
        fill="#94A3AE"
      />
      <rect
        x="35.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 35.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="35.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 35.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="35.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 35.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="35.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 35.375 73.958)"
        fill="#94A3AE"
      />
      <rect
        x="31.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 31.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="31.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 31.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="31.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 31.375 69.958)"
        fill="#E6EAED"
      />
      <rect
        x="31.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 31.375 73.958)"
        fill="#E6EAED"
      />
      <rect
        x="27.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 27.375 57.958)"
        fill="#E6EAED"
      />
      <rect
        x="27.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 27.375 61.958)"
        fill="#94A3AE"
      />
      <rect
        x="27.375"
        y="69.958"
        width="2"
        height="2"
        transform="rotate(90 27.375 69.958)"
        fill="#47FFBC"
      />
      <rect
        x="23.375"
        y="57.958"
        width="2"
        height="2"
        transform="rotate(90 23.375 57.958)"
        fill="#47FFBC"
      />
      <rect
        x="23.375"
        y="61.958"
        width="2"
        height="2"
        transform="rotate(90 23.375 61.958)"
        fill="#E6EAED"
      />
      <rect
        x="23.375"
        y="65.958"
        width="2"
        height="2"
        transform="rotate(90 23.375 65.958)"
        fill="#E6EAED"
      />
      <rect
        x="23.375"
        y="73.958"
        width="2"
        height="2"
        transform="rotate(90 23.375 73.958)"
        fill="#E6EAED"
      />
    </svg>
  );
};
