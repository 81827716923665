import { useQuery } from "@apollo/client";
import * as React from "react";
import { useParams } from "react-router-dom";
import { createGraphQlLoadable } from "../../graphql/utils";
import { QUERY_GET_DOCUMENT, QUERY_LIST_DOCUMENTS } from "./queries";
import { LegalDocumentPageView } from "./view";

interface UrlParams {
  id: string;
}

export const LegalDocumentPageContainer: React.FC = () => {
  const { id } = useParams() as any as UrlParams;

  const getQuery = useQuery(QUERY_GET_DOCUMENT, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  const postLoadable = createGraphQlLoadable({
    isLoading: getQuery.loading,
    error: getQuery.error,
    data: getQuery.data,
  });

  const listQuery = useQuery(QUERY_LIST_DOCUMENTS, {
    fetchPolicy: "network-only",
  });

  const listLoadable = createGraphQlLoadable({
    isLoading: listQuery.loading,
    error: listQuery.error,
    data: listQuery.data,
  });

  return (
    <LegalDocumentPageView
      postLoadable={postLoadable}
      listLoadable={listLoadable}
    />
  );
};
