export const OPTIONS = [
  {
    name: "Transactions",
    description:
      "Seamlessly execute and manage blockchain transactions with confidence. Our API integration ensures fluid automation for your financial operations.",
  },
  {
    name: "DeFi (Decentralized Finance)",
    description:
      "Navigate the dynamic world of DeFi with confidence. Our platform provides secure access to decentralized financial services, augmented by API capabilities for seamless integration into your existing systems.",
  },
  {
    name: "Trading",
    description:
      "Enhance your trading operations with our seamless integrations into leading centralized and decentralized exchanges. Our solution empowers you to maintain off-exchange custody of assets while trading. Choose to manually navigate markets via our intuitive console or utilize our API for streamlined, automated trading, ensuring both security and strategic efficiency in your trading activities.",
  },
  {
    name: "Treasury Management",
    description:
      "Revolutionize your digital asset treasury operations. Our comprehensive solution offers strategic asset management, risk optimization, and seamless integration with your systems through our API.",
  },
  {
    name: "Token Issuance",
    description:
      "Unleash your potential with our token issuance services. Create and manage your own digital tokens for various applications, bolstered by our secure platform and API for streamlined operations.",
  },
  {
    name: "CBDC",
    description:
      "Be at the forefront of digital finance with our CBDC management solutions. Our platform offers secure handling and transaction capabilities for CBDCs, complemented by extensive API integration for seamless operations.",
  },
];
