import styled from "styled-components";
import { colors } from "../../theme";

const RadioWrapper = styled.div`
  opacity: 1;
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
`;

const RadioMask = styled.div`
  opacity: 1;
  position: absolute;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${colors.gray400};
  height: 20px;
  width: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`;

const Radio = styled.input.attrs(() => ({
  type: "radio",
}))`
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 999;
  height: 20px;
  width: 20px;

  &:checked {
    & ~ ${RadioMask} {
      background: ${colors.white};

      &:before {
        position: relative;
        display: block;
        width: 8px;
        height: 8px;
        background: ${colors.gray700};
        border-radius: 100%;
        content: " ";
      }
    }
  }

  &:focus {
    & ~ ${RadioMask} {
      background: ${colors.white};
      border: 1px solid ${colors.gray700};
    }
  }

  &:hover:not(:checked) {
    & ~ ${RadioMask} {
      background: ${colors.gray50};
      border: 1px solid ${colors.gray700};
    }
  }
`;

export const RadioInput: React.FC<{
  id: string;
  name: string;
  value: string;
  required: boolean;
}> = ({ id, name, value, required }) => {
  return (
    <RadioWrapper>
      <Radio id={id} name={name} value={value} required={required} />
      <RadioMask />
    </RadioWrapper>
  );
};
