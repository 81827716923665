import styled from "styled-components";

interface Props {
  gap?: string;
  columns?: string;
  alignItems?: string;
  isAutoColumns?: boolean;
}

export const Grid = styled.div.attrs((props) => ({
  alignItems: "center",
  columns: "1fr 1fr 1fr",
  gap: "24px",
  ...props,
}))<Props>`
  align-items: ${({ alignItems }) => alignItems};
  display: grid;
  gap: ${({ gap }) => gap};

  ${({ isAutoColumns, columns }) => {
    if (isAutoColumns) {
      return `grid-auto-flow: ${columns}`;
    }
    return `grid-template-columns: ${columns}`;
  }}
`;
