import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Unknown: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="99"
      height="101"
      viewBox="0 0 99 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M34.3676 50.4058C34.2247 53.1334 36.1363 55.4686 38.6197 55.6256C38.709 55.6256 38.7983 55.6256 38.8877 55.6256C41.3889 55.6256 43.4077 53.4081 43.3898 50.6609C43.3898 47.9137 41.371 45.6963 38.8698 45.7159C36.4758 45.7159 34.4926 47.7763 34.3676 50.4058Z"
        fill="#314756"
      />
      <path
        d="M17.538 49.1303C16.734 49.0714 16.0372 49.7583 16.0015 50.6413C15.9658 51.5243 16.5732 52.2896 17.3772 52.3289C18.1811 52.3878 18.86 51.701 18.9136 50.8179C18.9672 49.9349 18.3419 49.1892 17.538 49.1303Z"
        fill="#314756"
      />
      <path
        d="M34.0817 21.6383C34.7964 21.2458 35.0822 20.2647 34.7249 19.4798C34.3676 18.6948 33.4743 18.3809 32.7597 18.7733C32.045 19.1658 31.7592 20.1273 32.1165 20.9123C32.4738 21.7168 33.3492 22.0504 34.0817 21.6383Z"
        fill="#314756"
      />
      <path
        d="M38.6554 30.4098C39.7631 29.8015 40.2098 28.3102 39.6559 27.0935C39.1021 25.8769 37.7443 25.3863 36.6366 25.9946C35.5289 26.603 35.0823 28.0943 35.6361 29.311C36.19 30.5276 37.5478 31.0182 38.6554 30.4098Z"
        fill="#314756"
      />
      <path
        d="M23.2551 36.4341C24.1126 37.0425 25.256 36.7874 25.8277 35.8454C26.3816 34.9035 26.1493 33.6476 25.2918 33.0197C24.4342 32.4114 23.2908 32.6665 22.7191 33.6084C22.1474 34.5503 22.3975 35.8258 23.2551 36.4341Z"
        fill="#314756"
      />
      <path
        d="M25.9885 48.2473C24.7379 48.1688 23.6838 49.2088 23.6123 50.5824C23.5409 51.9561 24.4878 53.1138 25.7384 53.1923C26.989 53.2708 28.0431 52.2308 28.1145 50.8572C28.186 49.5032 27.2391 48.3258 25.9885 48.2473Z"
        fill="#314756"
      />
      <path
        d="M23.5052 64.868C22.594 65.3783 22.2188 66.5949 22.6834 67.6153C23.1479 68.6161 24.2555 69.0282 25.1846 68.518C26.0957 68.0078 26.4709 66.7911 26.0064 65.7707C25.5419 64.7699 24.4163 64.3578 23.5052 64.868Z"
        fill="#314756"
      />
      <path
        d="M32.2595 43.1257C33.4922 44.0087 35.1359 43.6359 35.922 42.2819C36.7259 40.9279 36.3865 39.1225 35.1537 38.2591C33.921 37.3761 32.2773 37.7489 31.4912 39.1029C30.6873 40.4373 31.0267 42.2426 32.2595 43.1257Z"
        fill="#314756"
      />
      <path
        d="M60.0408 30.2136C61.077 30.9593 62.4884 30.6453 63.1674 29.5072C63.8463 28.3691 63.5604 26.8188 62.5242 26.0732C61.488 25.3275 60.0766 25.6414 59.3977 26.7796C58.7009 27.9374 58.9867 29.468 60.0408 30.2136Z"
        fill="#314756"
      />
      <path
        d="M64.6503 21.4813C65.3292 21.9719 66.2225 21.7561 66.6691 21.0104C67.1158 20.2647 66.9193 19.2835 66.2403 18.793C65.5614 18.3024 64.6681 18.5182 64.2215 19.2443C63.7927 19.99 63.9892 20.9908 64.6503 21.4813Z"
        fill="#314756"
      />
      <path
        d="M60.3803 45.6767C57.8969 45.5197 55.753 47.6193 55.628 50.347C55.485 53.0746 57.3967 55.4294 59.88 55.5667C59.9694 55.5667 60.0587 55.5667 60.1302 55.5667C62.6135 55.5667 64.6324 53.3493 64.6324 50.6021C64.6502 47.9922 62.7743 45.814 60.3803 45.6767Z"
        fill="#314756"
      />
      <path
        d="M40.1383 42.7724C40.9065 44.4404 42.4608 45.5 44.1759 45.5C46.6593 45.5 48.6781 43.2826 48.6781 40.5354C48.6781 39.7701 48.5173 39.0048 48.1958 38.2983C47.0702 35.8454 44.3546 34.8643 42.1392 36.1005C39.906 37.3564 39.0127 40.3391 40.1383 42.7724Z"
        fill="#314756"
      />
      <path
        d="M75.4948 36.4341C76.406 35.9239 76.7633 34.6877 76.2988 33.6869C75.8343 32.6861 74.7087 32.2937 73.7976 32.8039C72.8864 33.3141 72.5291 34.5307 72.9757 35.5315C73.4581 36.5323 74.5658 36.9443 75.4948 36.4341Z"
        fill="#314756"
      />
      <path
        d="M64.0606 38.004C62.7564 38.7301 62.2204 40.4765 62.8815 41.909C63.5425 43.3415 65.1326 43.9302 66.4368 43.2042C67.741 42.4781 68.277 40.7316 67.6159 39.2991C66.9549 37.8667 65.3648 37.2976 64.0606 38.004Z"
        fill="#314756"
      />
      <path
        d="M49.3213 20.7356C50.3397 20.7945 51.2151 19.9311 51.2866 18.8126C51.358 17.6941 50.5541 16.7325 49.5357 16.654C48.5174 16.5952 47.6419 17.439 47.5705 18.5575C47.5169 19.6956 48.303 20.6571 49.3213 20.7356Z"
        fill="#314756"
      />
      <path
        d="M49.3035 33.5495C50.7685 33.6477 52.0191 32.4114 52.0906 30.8023C52.1799 29.1932 51.0544 27.8196 49.5894 27.7411C48.1244 27.643 46.8738 28.8792 46.8023 30.4883C46.7308 32.0974 47.8385 33.4711 49.3035 33.5495Z"
        fill="#314756"
      />
      <path
        d="M34.9393 63.2983C36.2435 62.5722 36.7795 60.8257 36.1184 59.3932C35.4574 57.9608 33.8673 57.3721 32.5631 58.0981C31.2589 58.8242 30.723 60.5706 31.384 62.0031C32.045 63.4356 33.6351 64.0243 34.9393 63.2983Z"
        fill="#314756"
      />
      <path
        d="M51.0186 37.8078C49.6608 40.1037 50.2325 43.1649 52.3228 44.6562C54.4131 46.1476 57.2002 45.5197 58.558 43.2238C59.9158 40.9279 59.3441 37.8666 57.2538 36.3753C56.5213 35.8455 55.6637 35.5707 54.7883 35.5707C53.2697 35.5707 51.8583 36.4145 51.0186 37.8078Z"
        fill="#314756"
      />
      <path
        d="M58.8617 58.5298C57.7361 56.0769 55.0205 55.0957 52.8051 56.332C50.5719 57.5683 49.6786 60.551 50.8042 62.9842C51.9297 65.4371 54.6453 66.4183 56.8607 65.182C59.0761 63.9654 59.9872 61.0219 58.8795 58.569C58.8795 58.5494 58.8795 58.5494 58.8617 58.5298Z"
        fill="#314756"
      />
      <path
        d="M66.7406 58.1766C65.5078 57.2935 63.8642 57.6664 63.0781 59.0204C62.2741 60.3744 62.6135 62.1797 63.8463 63.0431C65.079 63.9261 66.7227 63.5533 67.5088 62.1993C68.3127 60.8649 67.9733 59.0596 66.7406 58.1766Z"
        fill="#314756"
      />
      <path
        d="M75.3876 50.7394C75.4591 49.3658 74.5122 48.208 73.2616 48.1296C72.011 48.0511 70.9569 49.0911 70.8854 50.4647C70.814 51.8383 71.7609 52.9961 73.0115 53.0746C74.2442 53.1531 75.3162 52.0934 75.3876 50.7394Z"
        fill="#314756"
      />
      <path
        d="M81.6228 48.9734C80.8189 48.9145 80.1221 49.6013 80.0864 50.4843C80.0506 51.3674 80.6581 52.1327 81.462 52.1719C82.266 52.2308 82.9449 51.544 82.9985 50.6609C83.0342 49.7779 82.4268 49.0322 81.6228 48.9734Z"
        fill="#314756"
      />
      <path
        d="M75.745 64.8681C74.8874 64.2598 73.744 64.5149 73.1723 65.4568C72.6184 66.3987 72.8507 67.6546 73.7083 68.2825C74.5658 68.8909 75.7092 68.6357 76.2809 67.6938C76.8527 66.7519 76.6025 65.4764 75.745 64.8681Z"
        fill="#314756"
      />
      <path
        d="M34.3319 79.8209C33.653 79.3303 32.7597 79.5462 32.313 80.2722C31.8664 81.0179 32.0629 81.9991 32.7239 82.4896C33.4028 82.9802 34.2961 82.7644 34.7428 82.0383C35.2073 81.3122 35.0108 80.3115 34.3319 79.8209Z"
        fill="#314756"
      />
      <path
        d="M64.9183 79.6639C64.2036 80.0564 63.9178 81.0376 64.2751 81.8225C64.6324 82.6074 65.5257 82.9214 66.2403 82.5289C66.955 82.1365 67.2408 81.1749 66.8835 80.39C66.5262 79.5855 65.6508 79.2519 64.9183 79.6639Z"
        fill="#314756"
      />
      <path
        d="M47.9814 63.4945C49.3392 61.1986 48.7675 58.1374 46.6772 56.6264C44.5869 55.135 41.7998 55.763 40.4241 58.0589C39.0485 60.3548 39.638 63.416 41.7283 64.927C42.4608 65.4568 43.3184 65.7315 44.1938 65.7315C45.7303 65.7511 47.1417 64.9073 47.9814 63.4945Z"
        fill="#314756"
      />
      <path
        d="M38.9591 71.0886C37.9229 70.3429 36.5115 70.6569 35.8326 71.795C35.1537 72.9332 35.4395 74.4834 36.4758 75.2291C37.512 75.9748 38.9234 75.6608 39.6023 74.5226C40.2991 73.3845 40.0132 71.8343 38.9591 71.0886Z"
        fill="#314756"
      />
      <path
        d="M49.4822 80.5666C48.4638 80.5077 47.5884 81.3712 47.5169 82.4897C47.4455 83.6082 48.2494 84.5697 49.2678 84.6482C50.2861 84.7071 51.1616 83.8633 51.233 82.7448C51.2866 81.6066 50.5005 80.6451 49.4822 80.5666Z"
        fill="#314756"
      />
      <path
        d="M49.5 67.7527C48.035 67.6546 46.7843 68.8908 46.7129 70.4999C46.6236 72.109 47.7491 73.4826 49.2141 73.5611C50.6791 73.6396 51.9297 72.423 52.0012 70.8139C52.0905 69.2244 50.965 67.8312 49.5 67.7527Z"
        fill="#314756"
      />
      <path
        d="M60.3446 70.8924C59.2369 71.5203 58.7903 73.0117 59.362 74.2283C59.9337 75.4449 61.2915 75.9355 62.3992 75.3076C63.5069 74.6993 63.9535 73.2079 63.3997 71.9913C62.8279 70.7746 61.4701 70.2644 60.3446 70.8924Z"
        fill="#314756"
      />
    </svg>
  );
};
