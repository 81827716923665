import styled from "styled-components";
import { Button } from "../../components/Button";
import { FormField } from "../../components/FormField";
import { Grid } from "../../components/Grid";
import { InputLabel } from "../../components/InputLabel";
import { RadioField } from "../../components/RadioField";
import { Stack } from "../../components/Stack";
import { Text } from "../../components/Text";
import { TextInput } from "../../components/icons/TextInput";
import { colors } from "../../theme";

interface Props {}

const Wrapper = styled("div")`
  width: 100%;
  padding: 40px 32px;
  border-radius: 6px;
`;

const Paragraph = styled(Text)`
  color: ${colors.gray700};
  font-size: 16px;
  font-family: InterRegular;
  line-height: 24px;
`;

export const RequestDemoForm: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      {/* @ts-ignore*/}
      <form name="request-demo" method="POST">
        <input type="hidden" name="form-name" value="request-demo" />

        <Stack gap="32px">
          {/* Form fields */}
          <Stack gap="24px">
            <Grid alignItems="flex-start" gap="32px" columns="1fr 1fr">
              <FormField>
                <InputLabel htmlFor="first_name">First name</InputLabel>
                <TextInput required id="first_name" name="first_name" />
              </FormField>
              <FormField>
                <InputLabel htmlFor="last_name">Last name</InputLabel>
                <TextInput required id="last_name" name="last_name" />
              </FormField>
            </Grid>

            <FormField>
              <InputLabel htmlFor="email">Email</InputLabel>
              <TextInput required id="email" name="email" type="email" />
            </FormField>

            <FormField>
              <InputLabel htmlFor="phone">Phone number</InputLabel>
              <TextInput required id="phone" name="phone" type="tel" />
            </FormField>

            <FormField>
              <InputLabel htmlFor="company">Company</InputLabel>
              <TextInput required id="company" name="company" />
            </FormField>

            <FormField>
              <InputLabel htmlFor="role">Role</InputLabel>
              <TextInput required id="role" name="role" />
            </FormField>

            <FormField>
              <Stack gap="16px">
                <InputLabel htmlFor="security">
                  What’s most important to you? (Choose one)
                </InputLabel>
                <Grid alignItems="flex-start" columns="1fr 1fr" gap="32px">
                  <Stack gap="16px">
                    <RadioField
                      required={true}
                      id="security"
                      label="Better security"
                      name="feature"
                      value="security"
                    />
                    <RadioField
                      required={true}
                      id="integration"
                      label="Choice of blockchain integrations"
                      name="feature"
                      value="integration"
                    />
                    <RadioField
                      required={true}
                      id="cost"
                      label="Cost-effective solutions"
                      name="feature"
                      value="cost"
                    />
                  </Stack>
                  <Stack gap="16px">
                    <RadioField
                      required={true}
                      id="developer"
                      label="A great developer experience"
                      name="feature"
                      value="developer"
                    />
                    <RadioField
                      required={true}
                      id="all"
                      label="All of them"
                      name="feature"
                      value="all"
                    />
                    <RadioField
                      required={true}
                      id="something-else"
                      label="Something else"
                      name="feature"
                      value="something-else"
                    />
                  </Stack>
                </Grid>
              </Stack>
            </FormField>
          </Stack>

          {/* Terms */}
          <Stack gap="16px">
            <Paragraph>
              Palisade is committed to protecting and respecting your privacy.
              We’ll only use your personal information to administer your
              account and to provide the products and services you requested.
            </Paragraph>
            <Paragraph>
              From time to time, we would like to contact you about our products
              and services, as well as other content that may be of interest to
              you.
            </Paragraph>
            <Paragraph>
              By clicking submit, you consent to allow Palisade to store and
              process the personal information submitted above to provide you
              the content requested.
            </Paragraph>
          </Stack>

          <Stack gap="24px">
            <div>
              <Button minWidth="220px" type="submit">
                Submit
              </Button>
            </div>
            {/* <Paragraph>
              You can unsubscribe from these communications at any time.
            </Paragraph> */}
          </Stack>
        </Stack>
      </form>
    </Wrapper>
  );
};
