import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const EnterprisePlan: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.4873 39.1824V18.6524L31.3547 10.0981V47.27L10.4873 39.1824Z"
        stroke={color}
      />
      <path
        d="M4.69189 28.2605V12.3539L17.5217 5.87235V34.3884L4.69189 28.2605Z"
        stroke={color}
      />
      <path
        d="M41.9614 30.9604V9.7748L26.0904 1.11161V39.151L41.9614 30.9604Z"
        stroke={color}
      />
    </svg>
  );
};
