import styled, { keyframes } from 'styled-components';
import { colors } from '../../theme';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  border-color: ${colors.gray700} ${colors.gray300} ${colors.gray300};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  display: block;
  height: 48px;
  transform: translateZ(0);
  width: 48px;
`;
