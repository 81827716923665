import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as React from "react";
import { CMS_ACCESS_TOKEN, GQL_URI } from "../config";

export const ApolloGuardian = (props: any) => {
  const { children } = props;

  /**
   * clientRef:
   *
   * Is used to store the ApolloClient instance after
   * initial instantiation.
   *
   * Because useRef values are persisted between
   * re-renders (unlike useState) we're able to prevent
   * ApolloClient from being re-created on each rerender.
   */
  const clientRef = React.useRef<ApolloClient<NormalizedCacheObject> | null>(
    null
  );

  const httpLink = new HttpLink({
    uri: GQL_URI,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const authLink = setContext(async (operation, { headers }) => {
    /**
     * TODO: https://palisade-inc.atlassian.net/browse/ENG-302
     *
     * Web sockets can be long lived. For long lived websockets to work with refresh tokens
     * we need to make sure we renew the token once it expires e.g. upon receiving a 401
     * from the backend.
     *
     * This depends on the graphql server checking the validity
     * of the token which it currently does not.
     */

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${CMS_ACCESS_TOKEN}`,
      },
    };
  });

  /**
   * https://www.apollographql.com/docs/react/data/subscriptions/
   * The split function takes three parameters:
   *
   * A function that's called for each operation to execute
   *
   * The Link to use for an operation if the function
   * returns a "truthy" value
   *
   * The Link to use for an operation if the function
   * returns a "falsy" value
   */

  // const splitLink = split(({ query }) => {
  //   const definition = getMainDefinition(query);

  //   return (
  //     definition.kind === "OperationDefinition" &&
  //     definition.operation === "subscription"
  //   );
  // }, httpLink);

  /**
   * initClient:
   *
   * Using a combination of useRef and useMemo we're
   * preventing the ApolloClient from being recreated
   * on re-render.
   */
  const initClient = React.useMemo(() => {
    if (!clientRef.current) {
      clientRef.current = new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        cache: new InMemoryCache({ addTypename: false }),
      });

      return clientRef.current;
    }

    return clientRef.current;
  }, [clientRef, errorLink, authLink, httpLink]);

  return <ApolloProvider client={initClient}>{children}</ApolloProvider>;
};
