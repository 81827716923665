import React from "react";
import { Col, Hidden, Row } from "react-awesome-styled-grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { colors } from "../../theme";
import { ExternalLink } from "../ExternalLink";
import { Flex } from "../Flex";
import { FooterNavigation } from "../FooterNavigation";
import { Logo } from "../Logo";
import { Spacer } from "../Spacer";
import { LinkedInLogo } from "../icons/LinkedInLogo";
import { XLogo } from "../icons/XLogo";

interface Props {}
const year = new Date().getFullYear();

const Wrapper = styled.div``;

const DisclaimerWrapper = styled.div`
  border-radius: 5px;
  padding: 24px 32px;
  background-color: ${colors.gray50};
`;

const DisclaimerText = styled.p`
  font-size: 14px;
  margin: 0 0 18px 0;
  color: ${colors.gray700};
  font-family: InterRegular;

  > & {
    margin: 0;
  }
`;

export const Footer: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Row>
        <Col justify="center" align="flex-start">
          <Link to={PATHS.home}>
            <Logo width="142px" />
          </Link>
        </Col>
        <Col justify="center" align="flex-end">
          <Flex>
            <ExternalLink
              target="_blank"
              href="https://twitter.com/palisadeinc"
            >
              <XLogo />
            </ExternalLink>
            <Spacer />
            <ExternalLink
              target="_blank"
              href="https://www.linkedin.com/company/palisade-inc/"
            >
              <LinkedInLogo />
            </ExternalLink>
          </Flex>
        </Col>
      </Row>
      <Row reverse={["xs"]}>
        <Col justify="center">
          <Spacer direction="v" />
          <Hidden xs>
            <Spacer direction="v" />
            <Spacer direction="v" />
          </Hidden>
          <div>© {year} Palisade. All rights reserved.</div>
        </Col>
        <Col
          align={{
            xs: "flex-start",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
            xl: "flex-end",
          }}
        >
          <Spacer direction="v" />
          <Spacer direction="v" />
          <Spacer direction="v" />
          <FooterNavigation />
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer direction="v" size="4xl" />
          <DisclaimerWrapper>
            <DisclaimerText>
              Palisade Financial S.A.S. is a French société par actions
              simplifiée having its registered office located at 229 rue
              Saint-Honoré, 75001 Paris, registered with the Trade and Companies
              Registry of Paris under number 922 396 262. Palisade Financial
              S.A.S. is registered as a DASP (Digital Asset Service Provider)
              with the AMF under number E2023-82. Any investment in
              crypto-assets carries risks. Past financial performance does not
              guarantee future performance.
            </DisclaimerText>
            <DisclaimerText>
              Investment in digital assets carries a risk of total or partial
              capital loss and a risk of significant volatility potentially
              inappropriate for retail clients. Only risk capital that you are
              willing to lose.
            </DisclaimerText>
          </DisclaimerWrapper>
          <Spacer direction="v" size="4xl" />
        </Col>
      </Row>
      <Spacer direction="v" size="md" />
    </Wrapper>
  );
};
