import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Avalanche: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="102"
      height="101"
      viewBox="0 0 102 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.5 50.5C85.5 69.83 70.0538 85.5 51 85.5C31.9462 85.5 16.5 69.83 16.5 50.5C16.5 31.17 31.9462 15.5 51 15.5C70.0538 15.5 85.5 31.17 85.5 50.5ZM41.2238 64.4275H34.5283C33.1214 64.4275 32.4264 64.4275 32.0026 64.1524C31.545 63.8514 31.2653 63.3527 31.2313 62.8025C31.206 62.2952 31.5534 61.6761 32.2484 60.4379L48.7804 30.8758C49.4838 29.6204 49.8398 28.9928 50.289 28.7606C50.7721 28.5113 51.3484 28.5113 51.8315 28.7606C52.2807 28.9928 52.6367 29.6204 53.3401 30.8758L56.7561 36.9252C57.5159 38.272 57.9012 38.9549 58.0694 39.6716C58.2558 40.4541 58.2558 41.2795 58.0694 42.0619C57.8999 42.7842 57.5184 43.4721 56.7472 44.8392L48.0634 60.4122L48.0409 60.4521C47.276 61.8099 46.8885 62.498 46.3513 63.0174C45.7666 63.5849 45.0631 63.9976 44.2919 64.2297C43.5884 64.4275 42.8002 64.4275 41.2238 64.4275ZM58.1321 64.4283H67.7262C69.1416 64.4283 69.8534 64.4283 70.2772 64.1446C70.7349 63.8436 71.023 63.3363 71.0485 62.786C71.073 62.2955 70.733 61.7003 70.0668 60.5342C70.0441 60.4944 70.021 60.4541 69.9975 60.413L65.192 52.0728L65.1373 51.979C64.462 50.8204 64.121 50.2354 63.6834 50.0092C63.2003 49.7599 62.6325 49.7599 62.1494 50.0092C61.7086 50.2413 61.3527 50.8518 60.6492 52.0813L55.8607 60.4215L55.8443 60.4503C55.1433 61.6778 54.793 62.2913 54.8182 62.7947C54.8522 63.3449 55.1318 63.8523 55.5895 64.1531C56.0048 64.4283 56.7167 64.4283 58.1321 64.4283Z"
        fill={color}
      />
    </svg>
  );
};
