import React from "react";
import { config, Container } from "react-awesome-styled-grid";
import styled from "styled-components";
import { H1, SubH1 } from "../../components/Heading";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { Spacer } from "../../components/Spacer";
import { Stack } from "../../components/Stack";
import { Testimonials } from "../../components/Testimonials";
import { Experience } from "./Experience";
import { Founders } from "./Founders";
import { Timeline } from "./Timeline";

export const CopyContainer = styled.div`
  ${(props) => config(props).media["md"]`
    max-width: 620px;
  `}
`;

export const CompanyPage: React.FC = () => {
  return (
    <>
      <RestoreScrollTop />
      <Spacer direction="v" size="5xl" />
      <Spacer direction="v" size="md" />
      <Stack gap="64px">
        {/* Hero */}
        <Container>
          <CopyContainer>
            <Stack gap="24px">
              <H1>
                All the necessary building blocks for digital asset custody
              </H1>
              <SubH1>
                Since our inception in 2021 we’ve been studiously putting the
                building blocks in place for a suite of custody and tokenisation
                wallet tools to help our customers to deal with the turbulent
                regulatory landscape they’re faced with.
              </SubH1>
            </Stack>
          </CopyContainer>
        </Container>
        <Founders />
        <Experience />
        <Timeline />
        <Testimonials />
      </Stack>
    </>
  );
};
