import React from "react";
import styled from "styled-components";
import { colors } from "../../theme";

interface Props {
  children: any;
}

const NoticeWrapper = styled.div`
  background: ${colors.gray200};
  border-radius: 2px;
  color: ${colors.gray700};
  font-size: 14px;
  height: fit-content;
  line-height: 150%;
  padding: 8px 16px;
  width: fit-content;
`;

export const Notice: React.FC<Props> = (props) => {
  const { children } = props;

  return <NoticeWrapper>{children}</NoticeWrapper>;
};
