import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { CloudSign } from "../../components/CloudSign";
import { CodeExample } from "../../components/CodeExample";
import { ComplianceFirst } from "../../components/ComplianceFirst";
import { ExternalLink } from "../../components/ExternalLink";
import { Features } from "../../components/Features";
import { Flex } from "../../components/Flex";
import { Hero } from "../../components/Hero";
import { Notice } from "../../components/Notice";
import { PricingExample } from "../../components/PricingExample";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { Spacer } from "../../components/Spacer";
import { Stack } from "../../components/Stack";
import { SupportedChains } from "../../components/SupportedChains";
import { Testimonials } from "../../components/Testimonials";
import { UseCases } from "../../components/UseCases";
import { BlogPostHighlightsContainer } from "../../containers/BlogPostHighlightsContainer";
import { colors } from "../../theme";

interface Props {}

export const Home: React.FC<Props> = (props) => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      <RestoreScrollTop />
      <Spacer direction="v" size="xl" />
      <Flex justifyContent="center">
        <Notice>
          <ExternalLink
            color={colors.gray700}
            href="https://app.screenloop.com/careers/palisade/"
            showUnderline={true}
            target="_blank"
            whiteSpace="normal"
          >
            Join the team
          </ExternalLink>{" "}
          — Palisade is hiring to help transform cybersecurity in the digital
          asset space.
        </Notice>
      </Flex>
      <Hero />
      <Spacer direction="v" size="5xl" />
      <Spacer direction="v" size="md" />
      <Stack gap="150px">
        <Features />
        <UseCases />
        <SupportedChains />
        <Stack gap="32px">
          <CloudSign />
          <ComplianceFirst />
          <CodeExample />
        </Stack>
        <BlogPostHighlightsContainer />
        <Testimonials />
        <PricingExample />
      </Stack>
    </>
  );
};
