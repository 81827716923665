import React from "react";
import { Helmet } from "react-helmet-async";

interface SeoProps {
  description?: string;
  imgSrc?: string;
  name?: string;
  title?: string;
  type?: string;
}

export const SEO: React.FC<SeoProps> = ({
  description = "Institutional-grade security for your digital assets",
  imgSrc,
  name = "Palisade",
  title = "Digital asset custody",
  type = "website",
}) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>
        {name} - {title}
      </title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}

      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={`${name} - ${title}`} />
      {imgSrc && <meta property="og:image" content={`${imgSrc}`} />}
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}

      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
};
