export interface SimpleObject<T = string> {
  [key: string]: T;
}

export function withPathParams(path: string, params?: SimpleObject<any>) {
  let workingPath = path;

  if (params) {
    Object.keys(params).forEach((key) => {
      workingPath = workingPath.replace(`:${key}`, params[key]?.toString());
    });
  }

  return workingPath;
}
