import { useQuery } from "@apollo/client";
import * as React from "react";
import { createGraphQlLoadable } from "../../graphql/utils";
import { QUERY_GET_POSTS } from "./queries";
import { BlogPostHighlightsView } from "./view";

export const BlogPostHighlightsContainer: React.FC = () => {
  const { data, error, loading } = useQuery(QUERY_GET_POSTS, {
    variables: {
      take: 3,
    },
    fetchPolicy: "network-only",
  });

  const postListLoadable = createGraphQlLoadable({
    isLoading: loading,
    error,
    data,
  });

  return <BlogPostHighlightsView postListLoadable={postListLoadable} />;
};
