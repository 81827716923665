import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Contact: React.FC<Props> = ({ color = colors.white, ...rest }) => {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.7168 6L1.7168 94M9.7168 6L9.7168 94M17.7168 6V94M25.7168 6V94M33.7168 6L33.7168 94"
        stroke="url(#paint0_radial_2567_9545)"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeDasharray="0.02 8"
      />
      <path
        d="M19.6978 16.334L92.8518 16.4849L59.5665 45.3745L19.6978 16.334Z"
        fill="#94A3AE"
        stroke="#657683"
        strokeWidth="0.2"
      />
      <path
        d="M19.3438 16.3125L92.2741 69.4661L56.6957 69.1258L19.3438 16.3125Z"
        fill="#F7F9FA"
        stroke="#CAD2D8"
        strokeWidth="0.2"
      />
      <path
        d="M93.0126 16.6113L92.4839 69.3737L59.7134 45.4825L93.0126 16.6113Z"
        fill="#CAD2D8"
        stroke="#94A3AE"
        strokeWidth="0.2"
      />
      <path
        d="M56.5167 69.215L47.5834 83.687L37.8127 69.215H21.2305C20.7396 52.6117 19.3867 16.6875 19.3867 16.6875L56.5167 69.215Z"
        fill="#314756"
        stroke="#183142"
        strokeWidth="0.2"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2567_9545"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.3428 2.90458) rotate(106.282) scale(98.346 35.7622)"
        >
          <stop offset="0.0214217" stopColor="#47FFBC" />
          <stop offset="0.369792" stopColor="#314756" />
          <stop offset="0.666667" stopColor="#2DEBB2" />
          <stop offset="0.966574" stopColor="#657683" />
        </radialGradient>
      </defs>
    </svg>
  );
};
