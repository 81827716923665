import React from "react";
import { config, Container, Hidden } from "react-awesome-styled-grid";
import styled from "styled-components";

import { colors } from "../../theme";
import { AnchorButton } from "../AnchorButton";
import { Card } from "../Card";
import useEventTracker from "../GaEvent";
import { ExternalLinkIcon } from "../icons/ExternalLinkIcon";
import { Spacer } from "../Spacer";

export const UL = styled.ul`
  margin: 0px;
  padding: 0px;
`;

export const LI = styled.li`
  list-style-type: square;
  // background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDQgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00IDQuODcxMjlMMCAxLjE3ODk2VjkuMTc4OTZMNCA0Ljg3MTI5WiIgZmlsbD0iIzMxNDc1NiIvPgo8L3N2Zz4K)
  //   no-repeat left center;
  background-size: 5px;
  margin-left: 20px;
  padding: 0px 0px 0px 4px;

  & + & {
    margin-top: 12px;
  }
`;

const FeaturesHeading = styled.h2`
  font-family: "KurrentSemiBold";
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 1.5;
  margin: 0;
`;

const FeatureCopy = styled.p`
  margin: 0;
`;

const Graphic = styled.div`
  background: url("../images/v2/stocks.png") center;
  background-size: cover;
  height: 100%;
  min-height: 300px;
  position: relative;
  width: 100%;
`;

const CodeSnippet = styled.div`
  background-color: rgba(0, 27, 46, 0.85);
  border-radius: 6px;
  border: 1px solid ${colors.gray700};
  bottom: 40px;
  color: ${colors.gray25};
  color: white;
  font-family: FiraCodeRegular;
  font-size: 14px;
  left: 25px;
  max-width: calc(100% - 50px);
  padding: 16px;
  position: absolute;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${(props) => config(props).media["md"]`
    grid-template-columns: 3fr 2fr;
  `}
`;

const Frame = styled.div`
  padding: 32px;

  ${(props) => config(props).media["md"]`
    padding: 40px 48px 40px 64px;
  `}
`;

export const CloudSign: React.FC = () => {
  const gaEventTracker = useEventTracker("HOME:CODE_EXAMPLE");

  const GraphicWithCodeSnippet = () => {
    return (
      <Graphic>
        <CodeSnippet>"POST api.palisade.co/v1/wallet/create"</CodeSnippet>
      </Graphic>
    );
  };

  return (
    <Container>
      <Card padding="0">
        <Layout>
          <Hidden md lg xl>
            {GraphicWithCodeSnippet()}
          </Hidden>
          <Frame>
            <FeaturesHeading>Palisade Cloud Sign</FeaturesHeading>
            <Spacer direction="v" size="md" />
            <FeatureCopy>
              Explore a programmatic solution to wallet and asset management
              with fully automated wallet provisioning and transaction signing
              using hosted, cloud based MPC nodes.
            </FeatureCopy>
            <Spacer direction="v" size="xl" />
            <UL>
              <LI>Wallets and payment apps</LI>
              <LI>Automated DEX and CEX trading platforms</LI>
              <LI>Tokenisation and minting platforms</LI>
            </UL>
            <Spacer direction="v" size="xl" />
            <AnchorButton
              href="https://developers.palisade.co/docs/multi-party-computation-mpc"
              target="_blank"
              level="tertiary"
              onClick={() => gaEventTracker("CLOUD_SIGN", "CLICK_LINK")}
            >
              Read the docs
              <Spacer size="sm" />
              <ExternalLinkIcon />
            </AnchorButton>
          </Frame>
          <Hidden xs sm>
            {GraphicWithCodeSnippet()}
          </Hidden>
        </Layout>
      </Card>
    </Container>
  );
};
