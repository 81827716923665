import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { colors } from "../../theme";
import { withPathParams } from "../../utils";
import useEventTracker from "../GaEvent";
import { Spacer } from "../Spacer";
import { ExternalLinkIcon } from "../icons/ExternalLinkIcon";

const FooterNavigationList = styled.ul`
  margin: 0;
`;
const FooterNavigationListItem = styled.li`
  list-style: none;
  display: inline;
  padding: 0px 14px;

  &:last-child {
    padding-right: 0px;
  }

  &:first-child {
    padding-left: 0px;
  }
`;

const FooterNavigationLink = styled(NavLink)`
  color: ${colors.gray700};
  font-family: InterRegular;
  font-size: 16px;
  padding-bottom: 6px;
  text-decoration: none;
  transition: border-color 150ms, color 150ms;

  &:hover {
    color: ${colors.gray900};
  }

  &.active {
    color: ${colors.gray900};
    cursor: default;
    font-family: InterMedium;
  }
`;

const FooterNavigationLinkExternal = styled.a`
  align-items: center;
  color: ${colors.gray700};
  display: inline-flex;
  font-family: InterRegular;
  font-size: 16px;
  text-decoration: none;
  transition: color 150ms;

  &:hover {
    color: ${colors.gray900};
  }
`;

export const FooterNavigation: React.FC = () => {
  const gaEventTracker = useEventTracker("GLOBAL:FOOTER_NAVIGATION");

  return (
    <nav>
      <FooterNavigationList>
        <FooterNavigationListItem>
          <FooterNavigationLink to={PATHS.home}>Home</FooterNavigationLink>
        </FooterNavigationListItem>
        <FooterNavigationListItem>
          <FooterNavigationLink to={PATHS.company}>
            Company
          </FooterNavigationLink>
        </FooterNavigationListItem>
        <FooterNavigationListItem>
          <FooterNavigationLinkExternal
            href="https://app.screenloop.com/careers/palisade/"
            target="_blank"
          >
            Careers
          </FooterNavigationLinkExternal>
        </FooterNavigationListItem>
        <FooterNavigationListItem>
          <FooterNavigationLink to={withPathParams(PATHS.blogPost, { id: 1 })}>
            Resources
          </FooterNavigationLink>
        </FooterNavigationListItem>
        <FooterNavigationListItem>
          <FooterNavigationLink to={PATHS.legal}>Legal</FooterNavigationLink>
        </FooterNavigationListItem>
        <FooterNavigationListItem>
          <FooterNavigationLinkExternal
            href="https://developers.palisade.co/"
            target="_blank"
            onClick={() => gaEventTracker("READ_THE_DOCS", "CLICK_LINK")}
          >
            Docs
            <Spacer dir="v" size="xs" />
            <ExternalLinkIcon />{" "}
          </FooterNavigationLinkExternal>
        </FooterNavigationListItem>
      </FooterNavigationList>
    </nav>
  );
};
