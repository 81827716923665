import { GraphQlLoadable } from './types';

export const createGraphQlLoadable = (
  withProps?: Partial<GraphQlLoadable<any>>
): GraphQlLoadable<any> => {
  return {
    data: null,
    isLoading: false,
    ...withProps
  };
};
