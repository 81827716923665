import { gql } from "@apollo/client";

export const QUERY_GET_POSTS = gql`
  query GetArticles {
    articles(sort: "createdAt", pagination: { limit: 3 }) {
      meta {
        pagination {
          pageSize
        }
      }
      data {
        id
        attributes {
          title
          createdAt
          description
          body
          category {
            data {
              id
            }
          }
          slug
        }
      }
    }
  }
`;
