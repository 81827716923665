import React from "react";
import { config, Container } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Stack } from "../Stack";

interface Props {}

const QuotedHeading = styled("h3")`
  color: ${colors.gray700};
  font-family: KurrentSemiBold;
  font-size: 44px;
  line-height: 150%;
  margin-bottom: 0px;
  margin-top: 0px;
  position: relative;

  &:before {
    content: "“";
    font-family: KurrentSemiBold;
    font-size: 60px;
    left: 0;
    position: absolute;
    top: -20px;
  }
`;

const StyledBlockquote = styled.blockquote`
  border-left: 1px solid ${colors.gray200};
  font-size: 18px;
  line-height: 150%;
  margin: 0;
  padding-left: 32px;
`;

const Caption = styled.p`
  color: ${colors.gray700};
  font-family: InterMedium;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
`;

const CompanyName = styled.span`
  display: block;
  font-family: InterRegular;
  line-height: 150%;
  letter-spacing: 2.4px;
  text-transform: uppercase;
`;

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 470px;
`;

const Testimonial = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 68px;
  justify-content: center;
  padding: 0 32px;

  ${(props) => config(props).media["md"]`
    flex-direction: row;
    max-width: 1000px;
    padding: 0;
  `}
`;

const TestimonialImage = styled.img`
  max-width: 400px;
  width: 100%;
`;

export const Testimonials: React.FC<Props> = (props) => {
  return (
    <Container>
      <TestimonialContainer>
        <Testimonial>
          <TestimonialImage
            src="../images/v2/blockchain-laboratories.svg"
            alt="Blockchain Laboratories"
            width="400"
          />
          <Stack gap="20px">
            <QuotedHeading>A game changer...</QuotedHeading>
            <StyledBlockquote>
              Our partnership with Palisade represents a significant milestone
              for Blockchain Laboratories. Using Palisade custody means we're
              better placed than ever to provide our customers with a secure
              platform for tokenization and wallet management.
            </StyledBlockquote>
            <Caption>
              Boone Bergsma, CEO and founder
              <CompanyName>BLOCKCHAIN LABORATORIES</CompanyName>
            </Caption>
          </Stack>
        </Testimonial>
      </TestimonialContainer>
    </Container>
  );
};
