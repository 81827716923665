import React from "react";
import { Container, config } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Spacer } from "../Spacer";
import { FlyLogo } from "../icons/FlyLogo";
import { PortageLogo } from "../icons/PortageLogo";
import { RippleLogo } from "../icons/RippleLogo";

interface Props {}

export const Title = styled("p")`
  font-size: 16px;
  text-transform: uppercase;
  font-family: "InterLight";
  color: ${colors.gray700};
  margin: 0px;
  letter-spacing: 2px;
`;

const Logos = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;

  ${(props) => config(props).media["sm"]`
    grid-template-columns: 1fr 1fr 1fr;
  `};
`;
const Logo = styled.div`
  text-align: center;
`;

export const VentureBacked: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        position: "relative",
        padding: "24px 0px",
        background:
          "linear-gradient(180deg, rgba(227,234,238,1) 0%, rgba(227,234,238,0) 100%)",
      }}
    >
      <Container>
        <Title>Proudly backed by</Title>
        <Spacer direction="v" size="md" />
        <Logos>
          <Logo>
            <RippleLogo />
          </Logo>
          <Logo>
            <PortageLogo />
          </Logo>
          <Logo>
            <FlyLogo />
          </Logo>
        </Logos>
      </Container>
    </div>
  );
};
