import styled from "styled-components";
import { colors } from "../../theme";
import { Flex } from "../Flex";
import { Stack } from "../Stack";
import { Text } from "../Text";
import { CheckInCircle } from "../icons/CheckInCircle";

interface Feature {
  title: string;
  description: string;
}

const FeatureIcon = styled("div")`
  flex-grow: 1;
  margin-top: 2px;
`;

const FeatureHeading = styled("span")`
  color: ${colors.gray700};
  font-family: KurrentSemiBold;
  font-size: 20px;
  line-height: 150%;
`;

const FeatureDescription = styled(Text)`
  font-family: InterRegular;
  font-size: 16px;
  line-height: 150%;
`;

export const FeatureStack: React.FC = () => {
  const features: Feature[] = [
    {
      title: "Key storage",
      description:
        "Use HSM for easy to use high speed signing or MPC for coordinated signing flows",
    },
    {
      title: "Policy engine",
      description:
        "Apply governance to your transactions with our comprehensive policy engine",
    },
    {
      title: "API & console",
      description: "Automate your transaction flows with programmatic access",
    },
    {
      title: "Multi-chain",
      description:
        "Out of the box support for Ethereum, Avalanche and XRP ledger",
    },
  ];

  return (
    <Stack gap="32px">
      {features.map((feature, index) => {
        return (
          <Flex flexDirection="row" gap="8px" key={`feature_${index}`}>
            <FeatureIcon>
              <CheckInCircle width={24} height={24} />
            </FeatureIcon>
            <div>
              <FeatureHeading>{feature.title}</FeatureHeading>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </div>
          </Flex>
        );
      })}
    </Stack>
  );
};
