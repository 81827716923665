import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { colors } from "../../theme";
import { withPathParams } from "../../utils";
import { Button } from "../Button";
import useEventTracker from "../GaEvent";
import { Spacer } from "../Spacer";
import { ExternalLinkIcon } from "../icons/ExternalLinkIcon";

const NavigationList = styled("ul")`
  align-items: center;
  display: flex;
  gap: 24px;
  list-style: none;
  margin: 0;
`;

const NavigationLink = styled(NavLink)`
  align-items: center;
  border-bottom: 2px solid transparent;
  color: ${colors.gray700};
  display: inline-flex;
  font-family: InterMedium;
  font-size: 16px;
  text-decoration: none;
  transition: border-color 150ms, color 150ms;

  &:hover {
    color: ${colors.gray900};
  }

  &.active {
    border-color: ${colors.gray700};
    color: ${colors.gray900};
    cursor: default;
  }
`;

const NavigationLinkExternal = styled.a`
  align-items: center;
  color: ${colors.gray700};
  display: inline-flex;
  font-family: InterMedium;
  font-size: 16px;
  text-decoration: none;
  transition: color 150ms;

  &:hover {
    color: ${colors.gray900};
  }
`;

export const Navigation: React.FC = () => {
  const gaEventTracker = useEventTracker("GLOBAL:MAIN_NAVIGATION");
  const navigate = useNavigate();

  return (
    <nav>
      <NavigationList>
        <li>
          <NavigationLink to={PATHS.home}>Home</NavigationLink>
        </li>

        <li>
          <NavigationLink to={PATHS.company}>Company</NavigationLink>
        </li>

        <li>
          <NavigationLink to={withPathParams(PATHS.resources)}>
            Resources
          </NavigationLink>
        </li>

        <li>
          <NavigationLinkExternal
            href="https://developers.palisade.co/"
            target="_blank"
            onClick={() => gaEventTracker("READ_THE_DOCS", "CLICK_LINK")}
          >
            Developer docs <Spacer dir="v" size="xs" />
            <ExternalLinkIcon />
          </NavigationLinkExternal>
        </li>

        <li>
          <Button
            onClick={() => {
              navigate(PATHS.demoRequest);
            }}
            size="sm"
          >
            Request demo
          </Button>
        </li>
      </NavigationList>
    </nav>
  );
};
