import ReactMarkdown from "react-markdown";
import styled, { css } from "styled-components";
import { Spacer } from "../../components/Spacer";
import { colors } from "../../theme";

const BaseHeader = css`
  color: ${colors.gray700};
  font-family: InterSemiBold;
  line-height: 150%;

  & + * {
    margin-top: 8px;
  }
`;

const ArticleDate = styled("p")`
  color: ${colors.gray500};
  font-family: "InterRegular";
  font-size: 16px;
  line-height: 150%;
  margin: 0;
`;

const ArticleHeading = styled("h1")`
  color: ${colors.gray700};
  font-family: "KurrentBold";
  font-size: 48px;
  letter-spacing: -0.96px;
  line-height: 120%;

  & + * {
    margin-top: 30px;
  }
`;

const ArticleLabel = styled("p")`
  color: ${colors.gray700};
  font-family: InterLight;
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 2.4px;
  margin: 0;
  text-transform: uppercase;
`;

const ArticleContent = styled("div")`
  * {
    margin: 0;
  }

  h2 {
    ${BaseHeader}
    font-size: 20px;
  }

  h3 {
    ${BaseHeader}
    font-size: 16px;
  }

  h4 {
    ${BaseHeader}
    font-size: 16px;
    text-transform: uppercase;
  }

  h5 {
    ${BaseHeader}
    font-size: 16px;
    font-family: InterLight;
    text-transform: uppercase;
  }

  h6 {
    ${BaseHeader}
    font-size: 14px;
  }

  p {
    & + * {
      margin-top: 24px;
    }

    & + h2 {
      margin-top: 32px;
    }

    & + p {
      margin-top: 8px;
    }
  }

  a {
    color: ${colors.gray700};
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${colors.gray900};
    }
  }

  img {
    border-radius: 6px;
    margin: 24px 0;
    max-width: 100%;
  }

  ul {
    list-style-type: square;

    li + li {
      margin-top: 8px;
    }

    & + * {
      margin-top: 16px;
    }
  }

  ol {
    li + li {
      margin-top: 8px;
    }

    & + * {
      margin-top: 16px;
    }
  }

  blockquote {
    border-left: 4px solid ${colors.gray200};
    margin: 32px 0;
    padding: 8px 24px;
  }

  pre {
    background-color: ${colors.gray900};
    border-radius: 6px;
    border: 1px solid ${colors.gray700};
    color: ${colors.gray25};
    font-family: FiraCodeRegular;
    font-size: 14px;
    line-height: 160%;
    margin: 32px 0;
    max-width: 100%;
    padding: 16px;
  }

  code {
    white-space: pre-wrap;
  }
`;

interface Props {
  article: any;
}

export const Article: React.FC<Props> = ({ article }) => {
  return (
    <div>
      <ArticleDate>
        Created on:{" "}
        {new Intl.DateTimeFormat("en-US").format(new Date(article.createdAt))}
      </ArticleDate>
      <Spacer direction="v" />
      <ArticleLabel>Palisade Blog</ArticleLabel>
      <div>
        <ArticleHeading>{article.title}</ArticleHeading>
        <ArticleContent>
          {/* For testing only: <ExampleArticle /> */}
          <ReactMarkdown className="blog-post">{article.body}</ReactMarkdown>
        </ArticleContent>
      </div>
    </div>
  );
};
