import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {}

export const ExternalLinkIcon: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08061 2.65105L6.99992 2.65106V3.65107L4.09988 3.65105C3.81162 3.65105 3.62549 3.65144 3.48383 3.66301C3.21156 3.68526 3.03526 3.84886 3.01188 4.13488C3.00031 4.27647 2.99992 4.4625 2.99992 4.75064V12.5541C2.99992 12.8414 3.00031 13.0267 3.01187 13.1678C3.03414 13.4395 3.19794 13.6157 3.48325 13.6391C3.62438 13.6507 3.80975 13.6511 4.0971 13.6511H11.9034C12.1905 13.6511 12.3757 13.6507 12.5167 13.6391C12.704 13.6238 12.8553 13.555 12.9454 13.3781C12.958 13.3533 12.9769 13.303 12.988 13.1671C12.9995 13.0255 12.9999 12.8393 12.9999 12.5511V9.65108H13.9999V12.5703C13.9999 12.9868 14.0314 13.4494 13.8364 13.8321C13.6926 14.1143 13.4631 14.3438 13.1809 14.4876C12.7991 14.6821 12.3382 14.6511 11.9226 14.6511H4.07781C3.66212 14.6511 3.20084 14.6822 2.81894 14.4876C2.33644 14.2417 2.05856 13.7784 2.01521 13.2495C1.9999 13.0626 1.99991 12.8359 1.99992 12.5734V4.73136C1.99991 4.31502 1.9685 3.85259 2.16341 3.47006C2.30722 3.18782 2.5367 2.95834 2.81894 2.81453C3.20156 2.61958 3.66418 2.65103 4.08061 2.65105Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99992 2.65107H13.4999C13.7761 2.65107 13.9999 2.87492 13.9999 3.15107V7.65107H12.9999V4.35817L7.49992 9.85817L6.79281 9.15107L12.2928 3.65107H8.99992V2.65107Z"
        fill={color}
      />
    </svg>
  );
};
