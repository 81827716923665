import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const BNB: React.FC<Props> = ({ color = colors.gray700, ...rest }) => {
  return (
    <svg
      width="84"
      height="101"
      viewBox="0 0 84 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.8768 50.705L21.9092 62.7174L32.0001 68.7236V75.7564L16.0036 66.2666V47.1923L21.8768 50.705ZM21.8768 38.6926V45.6926L16 42.1762V35.1763L21.8768 31.6599L27.7823 35.1763L21.8768 38.6926ZM36.2142 35.1763L42.091 31.6599L47.9965 35.1763L42.091 38.6926L36.2142 35.1763Z"
        fill="#314756"
      />
      <path
        d="M25.9126 60.1198V53.0871L31.7893 56.6034V63.6034L25.9126 60.1198ZM36.0035 71.1348L41.8802 74.6512L47.7858 71.1348V78.1348L41.8802 81.6511L36.0035 78.1348V71.1348ZM56.2141 35.0685L62.0908 31.5521L67.9964 35.0685V42.0684L62.0908 45.5848V38.5848L56.2141 35.0685ZM62.0908 62.6096L62.1232 50.5972L68 47.0809V66.1551L52.0035 75.6449V68.6122L62.0908 62.6096Z"
        fill="#314756"
      />
      <path
        d="M58.0875 60.2276L52.2107 63.7112V56.7112L58.0875 53.1949V60.2276Z"
        fill="#314756"
      />
      <path
        d="M58.0874 41.1825L58.1198 48.2152L48.0001 54.2214V66.2629L42.1233 69.7465L36.2466 66.2629V54.2214L26.1269 48.2152V41.1825L32.0288 37.6661L42.0874 43.7014L52.2071 37.6661L58.1126 41.1825H58.0874ZM26.1233 29.1737L42.091 19.6511L58.0874 29.1737L52.2107 32.69L42.091 26.6547L32.0001 32.69L26.1233 29.1737Z"
        fill="#314756"
      />
    </svg>
  );
};
