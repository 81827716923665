import { useQuery } from "@apollo/client";
import * as React from "react";
import { useParams } from "react-router-dom";
import { createGraphQlLoadable } from "../../../graphql/utils";
import { QUERY_GET_ARTICLE, QUERY_LIST_ARTICLES } from "../queries";
import { BlogPostPageView } from "./view";

export interface UrlParams {
  id: string;
}

export const BlogPostPageContainer: React.FC = () => {
  const { id } = useParams() as any as UrlParams;

  const getQuery = useQuery(QUERY_GET_ARTICLE, {
    variables: {
      slug: id,
    },
    fetchPolicy: "network-only",
  });

  const postLoadable = createGraphQlLoadable({
    isLoading: getQuery.loading,
    error: getQuery.error,
    data: getQuery.data,
  });

  const listQuery = useQuery(QUERY_LIST_ARTICLES, {
    fetchPolicy: "network-only",
  });

  const listLoadable = createGraphQlLoadable({
    isLoading: listQuery.loading,
    error: listQuery.error,
    data: listQuery.data,
  });

  return (
    <BlogPostPageView postLoadable={postLoadable} listLoadable={listLoadable} />
  );
};
