import { Container, Hidden } from "react-awesome-styled-grid";
import styled from "styled-components";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Spacer } from "../../components/Spacer";
import { colors } from "../../theme";

const Frame = styled.div`
  padding: 40px 0;
`;

const HighlightBlock = styled.div`
  background: url(../images/v2/background-full-pattern-dark.png) repeat center
    center;
  display: flex;
  justify-content: center;
  padding: 48px 0px;
`;

const CopyContainer = styled.div`
  max-width: 920px;
`;

const P = styled.p`
  color: ${colors.gray25};
  font-family: InterRegular;
  font-size: 20px;
  line-height: 150%;
  margin: 0;
`;

const LinkedInLink = styled.a`
  align-items: center;
  display: flex;
  gap: 16px;
  text-decoration: none;

  span {
    border-bottom: 1px solid ${colors.white};
    transition: opacity 150ms;
  }

  &:hover {
    span {
      opacity: 0.8;
    }
  }
`;

export const Experience: React.FC = () => {
  return (
    <Frame>
      <HighlightBlock>
        <Container>
          <Flex gap="48px" justifyContent="center">
            <Hidden xs sm>
              <img src="../images/brand-logomark.svg" alt="Palisade Icon" />
            </Hidden>
            <CopyContainer>
              <H2 style={{ color: colors.white }}>An experienced team</H2>
              <Spacer size="xl" direction="v" />
              <P>
                We’re a high calibre team with the necessary domain expertise to
                deliver the most secure enterprise custody and tokenisation
                infrastructure available. We have a wealth of experience across
                Web3, Finance, Compliance, DeFi, Payments and Blockchain
                infrastructure.
              </P>
              <Spacer size="lg" direction="v" />
              <LinkedInLink
                href="https://www.linkedin.com/company/palisade-inc/"
                target="_blank"
                style={{ color: colors.white }}
              >
                <img src="../images/linkedin-logo.svg" alt="LinkedIn" />
                <span>Find us on LinkedIn</span>
              </LinkedInLink>
            </CopyContainer>
          </Flex>
        </Container>
      </HighlightBlock>
    </Frame>
  );
};
