import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { StyleSheetContextProvider } from "../context/StyleSheetContext";
import { BlogIndexPageContainer } from "../pages/Blog/BlogIndex";
import { BlogPostPageContainer } from "../pages/Blog/BlogPost";
import { CompanyPage } from "../pages/Company";
import { ConflictOfInterestPolicy } from "../pages/ConflictOfInterestPolicy";
import { ContactPage } from "../pages/Contact";
import { Home } from "../pages/Home";
import { LegalDocumentPageContainer } from "../pages/LegalArticle";
import { LegalArticleListPageContainer } from "../pages/LegalArticleList";
import { NotFound } from "../pages/NotFound";
import { RequestDemoPage } from "../pages/RequestDemo";
import { PATHS } from "./paths";

const customConf = {
  mediaQuery: "only screen",
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 1.5,
    lg: 1.5,
    xl: 1.5,
  },
  paddingWidth: {
    xs: 1,
    sm: 1,
    md: 1.5,
    lg: 1.5,
    xl: 1.5,
  },
  container: {
    xs: "full", // 'full' = max-width: 100%
    sm: "full", // 'full' = max-width: 100%
    md: "full", // 'full' = max-width: 100%
    lg: 90, // max-width: 1440px
    xl: 90, // max-width: 1440px
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 90, // 1440px
    xl: 120, // 1920px
  },
};

const Wrapper = styled.div`
  background-image: url("../images/v2/background-full-pattern-light.png");
  background-repeat: repeat;
  overflow-x: hidden;
`;

export const AppShell: React.FC = () => {
  return (
    <StyleSheetContextProvider>
      <HelmetProvider>
        <SEO />
        <Wrapper>
          <BrowserRouter>
            <ThemeProvider theme={{ awesomegrid: customConf }}>
              <Layout>
                <Routes>
                  <Route path={PATHS.home} element={<Home />} />
                  <Route path={PATHS.company} element={<CompanyPage />} />
                  <Route path={PATHS.contact} element={<ContactPage />} />
                  <Route
                    path={PATHS.demoRequest}
                    element={<RequestDemoPage />}
                  />
                  <Route
                    path={PATHS.conflictOfInterestPolicy}
                    element={<ConflictOfInterestPolicy />}
                  />
                  <Route
                    path={PATHS.blogPost}
                    element={<BlogPostPageContainer />}
                  />
                  <Route
                    path={PATHS.resources}
                    element={<BlogIndexPageContainer />}
                  />
                  <Route
                    path={PATHS.legal}
                    element={<LegalArticleListPageContainer />}
                  />
                  <Route
                    path={PATHS.legalArticle}
                    element={<LegalDocumentPageContainer />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Layout>
            </ThemeProvider>
          </BrowserRouter>
        </Wrapper>
      </HelmetProvider>
    </StyleSheetContextProvider>
  );
};

export default AppShell;
