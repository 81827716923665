import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const DownArrow: React.FC<Props> = ({
  color = colors.white,
  ...rest
}) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.79423 14.1511L15.5885 0.651123L0 0.651123L7.79423 14.1511Z"
        fill={color}
      />
    </svg>
  );
};
