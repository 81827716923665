import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const XRPL: React.FC<Props> = ({ color = colors.gray700, ...rest }) => {
  return (
    <svg
      width="103"
      height="101"
      viewBox="0 0 103 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M86.7976 79.778C85.7038 78.6944 84.6109 77.6098 83.5188 76.5243C82.3983 75.4115 81.2778 74.2986 80.1575 73.1855C79.051 72.0867 77.9444 70.9879 76.8377 69.8893C75.7242 68.7835 74.6107 67.6777 73.4974 66.5718C72.3909 65.473 71.2844 64.3741 70.178 63.2752C68.6523 61.7575 67.1269 60.2396 65.6016 58.7215C65.2801 58.4026 64.9507 58.0903 64.6096 57.7926C61.121 54.7472 57.0455 53.1484 52.4461 52.8909C50.8529 52.8056 49.2552 52.9214 47.6907 53.2356C44.675 53.8206 41.8344 55.0958 39.3901 56.9618C38.0796 57.9567 36.9778 59.1706 35.7966 60.3007C34.6629 61.3853 33.5652 62.5077 32.4516 63.6135C31.3522 64.7054 30.2527 65.7972 29.1533 66.8891C28.0257 68.0088 26.8982 69.1285 25.7706 70.2482C24.6781 71.3332 23.5856 72.4182 22.4931 73.5033C21.3097 74.6789 20.1266 75.8548 18.9438 77.0311C18.0419 77.9305 17.1451 78.835 16.2443 79.7356C16.1695 79.8104 16.0818 79.8722 16 79.9401C16.0761 79.9683 16.1553 79.9874 16.2358 79.997C19.6086 79.9999 22.9814 80.0007 26.3542 79.9995C26.4078 79.9203 26.4667 79.8447 26.5303 79.7734C27.056 79.2525 27.5867 78.7368 28.1124 78.2159C29.2273 77.1112 30.3411 76.0054 31.4538 74.8984C32.5675 73.7924 33.6812 72.6864 34.7949 71.5803C35.9016 70.4813 37.0083 69.3823 38.115 68.2833C39.6547 66.7515 41.1939 65.2192 42.7326 63.6864C43.6295 62.7967 44.6611 62.055 45.7892 61.489C47.8601 60.4297 50.1847 59.9719 52.5011 60.167C55.0017 60.3669 57.2619 61.2131 59.214 62.7852C60.1777 63.5612 61.0427 64.4629 61.9328 65.328C63.044 66.4081 64.1352 67.509 65.235 68.601C66.3557 69.7139 67.4764 70.827 68.597 71.9401C69.6966 73.0321 70.7963 74.1242 71.8959 75.2163C73.4146 76.7272 74.9322 78.2392 76.4487 79.7524C76.5192 79.8308 76.585 79.9133 76.6458 79.9995C80.0183 79.9995 83.3908 79.9986 86.7633 79.997C86.8442 79.9874 86.9236 79.9683 87 79.9401C86.9296 79.8898 86.8621 79.8357 86.7976 79.778Z"
        fill={color}
      />
      <path
        d="M19.8289 24.6906C20.9636 25.8175 22.0983 26.9443 23.2329 28.0713C24.3256 29.1564 25.4181 30.2416 26.5107 31.3268C27.9247 32.7329 29.3383 34.1396 30.7512 35.5469C32.5558 37.3447 34.3602 39.1427 36.1645 40.9409C36.7944 41.5664 37.4297 42.1871 38.0782 42.793C39.7121 44.3156 41.6009 45.5369 43.658 46.4007C46.8674 47.7638 50.3751 48.2656 53.8363 47.8566C57.2975 47.4476 60.5931 46.1421 63.3989 44.0682C64.7488 43.075 65.8779 41.846 67.0777 40.6928C68.2021 39.6122 69.2949 38.4985 70.4016 37.3994C71.5153 36.2934 72.6291 35.1874 73.7429 34.0815C74.8496 32.9824 75.9561 31.8833 77.0626 30.7841C78.1693 29.6851 79.276 28.5862 80.3829 27.4874C81.4967 26.3814 82.6103 25.2751 83.7236 24.1686C84.779 23.1151 85.8317 22.0589 86.8817 21H76.5275C76.4826 21.063 76.4341 21.1233 76.3821 21.1806C75.8417 21.7148 75.2976 22.2453 74.7578 22.7802C73.6427 23.8851 72.5287 24.9911 71.4157 26.0982C70.3019 27.2043 69.188 28.3103 68.0742 29.4163C66.9674 30.5155 65.8608 31.6147 64.7543 32.7141C63.2423 34.2181 61.7304 35.7223 60.2186 37.2266C59.346 38.0854 58.3463 38.8038 57.255 39.3563C55.2675 40.3714 53.0485 40.843 50.8214 40.7235C48.5944 40.604 46.4382 39.8976 44.57 38.6757C43.3883 37.9163 42.4487 36.8846 41.4501 35.9208C40.3208 34.8308 39.2193 33.712 38.1055 32.6059C37.0057 31.5137 35.9058 30.4216 34.8059 29.3295C33.4335 27.9652 32.0614 26.6004 30.6899 25.2352C29.8425 24.3933 28.9891 23.5576 28.1451 22.7123C27.5814 22.1477 27.0296 21.5712 26.4725 21H16.1183C16.2554 21.1382 16.3917 21.2773 16.5298 21.4145C17.6293 22.5068 18.729 23.5988 19.8289 24.6906Z"
        fill={color}
      />
    </svg>
  );
};
