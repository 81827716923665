import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Document: React.FC<Props> = ({
  color = colors.gray500,
  ...rest
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07959 8.24286L13.9819 8.24286V9.74286L7.07959 9.74286V8.24286ZM7.07959 10.9459L15.6782 10.9459V12.4459L7.07959 12.4459V10.9459ZM7.07959 16.3517L15.6782 16.3517V17.8517L7.07959 17.8517L7.07959 16.3517ZM7.07959 5.54004L15.6782 5.54004V7.04004L7.07959 7.04004V5.54004ZM9.41895 13.6402H15.1287V15.1402H9.41895V13.6402ZM7.07959 13.6402H8.57959V15.1402H7.07959V13.6402ZM18 3.65112H6V21.6511H18V3.65112ZM6 2.15112C5.17157 2.15112 4.5 2.8227 4.5 3.65112V21.6511C4.5 22.4795 5.17157 23.1511 6 23.1511H18C18.8284 23.1511 19.5 22.4795 19.5 21.6511V3.65112C19.5 2.8227 18.8284 2.15112 18 2.15112H6Z"
        fill={color}
      />
    </svg>
  );
};
