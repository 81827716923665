import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const BUSD: React.FC<Props> = ({ color = colors.gray700, ...rest }) => {
  return (
    <svg
      width="87"
      height="101"
      viewBox="0 0 87 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M43.5 22.6511L50.3134 29.7345L33.1567 47.1511L26.3433 40.2345L43.5 22.6511Z"
        fill="#314756"
      />
      <path
        d="M53.8433 33.1511L60.6567 40.2345L33.1567 68.1511L26.3433 61.2345L53.8433 33.1511Z"
        fill="#314756"
      />
      <path
        d="M22.8134 43.6511L29.6269 50.7345L22.8134 57.6511L16 50.7345L22.8134 43.6511Z"
        fill="#314756"
      />
      <path
        d="M64.1866 43.6511L71 50.7345L43.5 78.6511L36.6865 71.7345L64.1866 43.6511Z"
        fill="#314756"
      />
    </svg>
  );
};
