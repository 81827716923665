import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const FlyLogo: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="87"
      height="96"
      viewBox="0 0 87 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M63.8279 56.1148L48.8381 22.2281H55.1028C55.1028 22.2281 56.0682 22.1665 56.5119 22.7468C56.7283 23.0298 56.9776 23.6427 56.9776 23.6427C56.9776 23.6427 70.3655 53.85 70.5475 54.2304C70.7749 54.706 71.0145 55.4237 71.0145 55.9819C71.0145 56.4285 70.8918 57.1347 70.8367 57.2726C66.2881 67.4655 57.1207 88.0249 56.8395 88.7196C56.5584 89.4143 56.0049 89.7534 55.5272 89.7534H48.8382L63.8279 56.1148Z"
        fill={color}
      />
      <path
        d="M78.351 23.564C78.351 23.564 69.4579 43.3857 69.3137 43.782C68.7973 45.202 68.7304 45.893 69.4882 47.587C70.2461 49.281 72.3409 53.895 72.3409 53.895L86.406 22.1494H80.2258C80.2258 22.1494 79.2604 22.0877 78.8167 22.6681C78.6004 22.9511 78.351 23.564 78.351 23.564Z"
        fill={color}
      />
      <path
        d="M17.961 29.7598C17.1531 29.7598 16.3794 30.0856 15.8149 30.6635L9.38086 37.2502H30.0354V31.1598C30.0354 30.3866 29.4086 29.7598 28.6354 29.7598H17.961Z"
        fill={color}
      />
      <path
        d="M54.2264 59.7878C53.3715 59.8176 48.5339 59.8308 45.2449 56.5419C41.6268 52.9239 35.3562 46.6568 35.3531 46.6538C32.298 43.5985 31.8367 40.0264 31.8367 38.8075V7.17822L37.9272 7.17822C38.7003 7.17822 39.3272 7.80099 39.3272 8.57419L39.3271 43.2369C39.3271 49.5941 42.8894 52.2578 47.2324 52.2973H60.219L61.9374 56.0426L60.219 59.7878H54.2264Z"
        fill={color}
      />
      <path
        d="M1.40002 59.7882H7.49048V23.5674C7.49048 22.809 7.37207 20.5445 8.37681 18.5756C8.98455 17.3847 10.0464 16.4092 11.1737 15.7166C12.6248 14.8249 13.9078 14.661 14.7349 14.661L14.7362 14.661C14.8616 14.661 24.1136 14.6663 28.7229 14.6663C29.4961 14.6663 30.1229 14.0395 30.1229 13.2663V7.17578L21.7083 7.17578C17.6852 7.17578 15.0161 8.4991 12.3099 11.4305L4.00137 19.7024C0.636473 22.9728 -0.000359643 25.7547 1.52324e-07 28.3198C0.000312362 30.5457 8.27355e-05 52.6036 1.65355e-05 58.3993C7.70364e-06 59.1725 0.626813 59.7882 1.40002 59.7882Z"
        fill={color}
      />
    </svg>
  );
};
