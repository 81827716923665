import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Solana: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="90"
      height="101"
      viewBox="0 0 90 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M72.9157 64.8516L63.5492 74.7013C63.3453 74.9152 63.0994 75.0858 62.8252 75.2024C62.5515 75.319 62.2566 75.3791 61.9581 75.3789H17.5542C17.342 75.3789 17.1351 75.3182 16.9578 75.2043C16.7805 75.0904 16.641 74.9282 16.5565 74.7377C16.4726 74.5471 16.4466 74.3366 16.4826 74.1318C16.5181 73.9271 16.6144 73.7371 16.7592 73.5853L26.1328 63.7356C26.3362 63.5222 26.5814 63.3519 26.8545 63.2354C27.1276 63.1188 27.4219 63.0584 27.7192 63.058H72.1208C72.333 63.058 72.5398 63.1186 72.7171 63.2326C72.8945 63.3465 73.0339 63.5087 73.1185 63.6992C73.2024 63.8897 73.2284 64.1003 73.1923 64.305C73.1569 64.5097 73.0605 64.6997 72.9157 64.8516ZM63.5492 45.0172C63.3453 44.8032 63.0994 44.6326 62.8252 44.516C62.5515 44.3995 62.2566 44.3393 61.9581 44.3395H17.5542C17.342 44.3395 17.1351 44.4002 16.9578 44.5141C16.7805 44.6281 16.641 44.7902 16.5565 44.9808C16.4726 45.1713 16.4466 45.3818 16.4826 45.5866C16.5181 45.7913 16.6144 45.9813 16.7592 46.1332L26.1328 55.9828C26.3362 56.1962 26.5814 56.3664 26.8545 56.483C27.1276 56.5996 27.4219 56.66 27.7192 56.6605H72.1208C72.333 56.6605 72.5398 56.5998 72.7171 56.4859C72.8945 56.3719 73.0339 56.2098 73.1185 56.0192C73.2024 55.8287 73.2284 55.6182 73.1923 55.4134C73.1569 55.2087 73.0605 55.0187 72.9157 54.8668L63.5492 45.0172ZM17.5542 37.9421H61.9581C62.2566 37.9422 62.5515 37.8822 62.8252 37.7656C63.0994 37.649 63.3453 37.4784 63.5492 37.2644L72.9157 27.4147C73.0605 27.2629 73.1569 27.0729 73.1923 26.8682C73.2284 26.6634 73.2024 26.4529 73.1185 26.2623C73.0339 26.0718 72.8945 25.9096 72.7171 25.7957C72.5398 25.6818 72.333 25.6211 72.1208 25.6211H27.7192C27.4219 25.6216 27.1276 25.682 26.8545 25.7986C26.5814 25.9151 26.3362 26.0854 26.1328 26.2987L16.7616 36.1484C16.6168 36.3002 16.521 36.4899 16.485 36.6945C16.4489 36.8989 16.4743 37.1093 16.5589 37.2998C16.6428 37.4902 16.7817 37.6524 16.9584 37.7665C17.1357 37.8806 17.3426 37.9416 17.5542 37.9421Z"
        fill={color}
      />
    </svg>
  );
};
