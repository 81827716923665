import { gql } from "@apollo/client";

export const QUERY_GET_DOCUMENT = gql`
  query GetArticle($id: ID!) {
    legals(filters: { id: { eq: $id } }, pagination: { limit: 1 }) {
      data {
        id
        attributes {
          title
          createdAt
          body
        }
      }
    }
  }
`;

export const QUERY_LIST_DOCUMENTS = gql`
  query ListArticles {
    legals(sort: "createdAt") {
      data {
        id
        attributes {
          title
          createdAt
        }
      }
    }
  }
`;
