import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const FeatureDefi: React.FC<Props> = ({
  color = "#183142",
  ...rest
}) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        style={{
          filter: `drop-shadow(0px 0px 5px rgb(0 0 0 / 0.1))`,
        }}
      >
        <path
          d="M74.7257 36.1309L47.0347 63.8218L19.5091 35.9655L47.0347 8.43988L74.7257 36.1309Z"
          fill="#314756"
        />
        <path
          d="M19.3438 63.8223L47.0347 36.1313L74.7257 63.8223L47.0347 91.5132L19.3438 63.8223Z"
          fill="#CAD2D8"
        />
        <path
          d="M47.0312 36.1543L33.1861 50.0001L47.0312 63.8453L47.0312 36.1543Z"
          fill="#94A3AE"
        />
        <path
          d="M47.0312 36.1543L60.8767 49.9998L47.0312 63.8453L47.0312 36.1543Z"
          fill="#F7F9FA"
        />
      </g>
      <rect
        x="36.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 36.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="36.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 36.5 46.1826)"
        fill="#94A3AE"
      />
      <rect
        x="36.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 36.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="36.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 36.5 34.1826)"
        fill="#47FFBC"
      />
      <rect
        x="40.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 40.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="40.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 40.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="40.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 40.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="40.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 40.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="44.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 44.5 50.1826)"
        fill="#94A3AE"
      />
      <rect
        x="44.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 44.5 46.1826)"
        fill="#94A3AE"
      />
      <rect
        x="44.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 44.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="48.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 48.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="48.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 48.5 46.1826)"
        fill="#47FFBC"
      />
      <rect
        x="48.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 48.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="48.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 48.5 38.1826)"
        fill="#47FFBC"
      />
      <rect
        x="48.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 48.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="52.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 52.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="52.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 52.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="52.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 52.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="56.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 56.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="56.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 56.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="60.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 60.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="60.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 60.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="60.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 60.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="60.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 60.5 38.1826)"
        fill="#47FFBC"
      />
      <rect
        x="60.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 60.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="64.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 64.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="64.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 64.5 46.1826)"
        fill="#94A3AE"
      />
      <rect
        x="64.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 64.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="64.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 64.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="64.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 64.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="68.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 68.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="68.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 68.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="68.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 68.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="68.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 68.5 34.1826)"
        fill="#47FFBC"
      />
      <rect
        x="72.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 72.5 50.1826)"
        fill="#94A3AE"
      />
      <rect
        x="72.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 72.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="72.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 72.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="72.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 72.5 38.1826)"
        fill="#47FFBC"
      />
      <rect
        x="76.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 76.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="76.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 76.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="76.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 76.5 38.1826)"
        fill="#47FFBC"
      />
      <rect
        x="80.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 80.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="80.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 80.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="80.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 80.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="80.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 80.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="80.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 80.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="84.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 84.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="84.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 84.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="84.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 84.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="84.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 84.5 38.1826)"
        fill="#94A3AE"
      />
      <rect
        x="84.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 84.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="88.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 88.5 50.1826)"
        fill="#47FFBC"
      />
      <rect
        x="88.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 88.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="88.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 88.5 42.1826)"
        fill="#94A3AE"
      />
      <rect
        x="88.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 88.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="88.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 88.5 34.1826)"
        fill="#E6EAED"
      />
      <rect
        x="92.5"
        y="50.1826"
        width="2"
        height="2"
        transform="rotate(-90 92.5 50.1826)"
        fill="#E6EAED"
      />
      <rect
        x="92.5"
        y="46.1826"
        width="2"
        height="2"
        transform="rotate(-90 92.5 46.1826)"
        fill="#E6EAED"
      />
      <rect
        x="92.5"
        y="42.1826"
        width="2"
        height="2"
        transform="rotate(-90 92.5 42.1826)"
        fill="#E6EAED"
      />
      <rect
        x="92.5"
        y="38.1826"
        width="2"
        height="2"
        transform="rotate(-90 92.5 38.1826)"
        fill="#E6EAED"
      />
      <rect
        x="92.5"
        y="34.1826"
        width="2"
        height="2"
        transform="rotate(-90 92.5 34.1826)"
        fill="#E6EAED"
      />
    </svg>
  );
};
