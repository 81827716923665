import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { Button } from "../../components/Button";
import { Spacer } from "../../components/Spacer";
import { colors } from "../../theme";

const Container = styled("div")`
  background: url(../images/v2/background-full-pattern-dark.png) repeat center
    center;
  border-radius: 6px;
  padding: 24px;
`;

const H3 = styled("h3")`
  color: ${colors.gray50};
  font-family: "KurrentSemiBold";
  font-size: 30px;
  line-height: 120%;
  margin: 0;
`;

const P = styled("p")`
  color: ${colors.gray25};
  font-family: "InterRegular";
  font-size: 18px;
  line-height: 150%;
  margin: 0;
`;

export const ArticleCTA: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <H3>Request a demo</H3>
      <Spacer direction="v" size="sm" />
      <P>
        Discover wallet and custody infrastructure with security, governance and
        compliance in one platform. Get in touch to request a demo or speak to
        the team.
      </P>
      <Spacer direction="v" size="xl" />
      <div>
        <Button
          isFullWidth={false}
          level="tertiary"
          onClick={() => {
            navigate(PATHS.demoRequest);
          }}
        >
          Request a demo
        </Button>
      </div>
    </Container>
  );
};
