import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const BrandSquare: React.FC<Props> = ({
  color = colors.gray800,
  ...rest
}) => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H34.8363V35.5954H0V0ZM11.1204 9.23203L15.4707 6.0183V29.5771L11.1204 26.6405V9.23203ZM18.8827 6.0183L23.233 9.55246V22.3937L18.8827 26.1012V6.0183Z"
        fill={color}
      />
    </svg>
  );
};
