import React from "react";
import { config, Container } from "react-awesome-styled-grid";
import styled from "styled-components";

import { PATHS } from "../../AppShell/paths";
import { Card } from "../Card";
import useEventTracker from "../GaEvent";
import { LinkButton } from "../LinkButton";
import { Spacer } from "../Spacer";

interface Props {}

const UL = styled.ul`
  margin: 0px;
  padding: 0px;
`;

const LI = styled.li`
  list-style-type: square;
  // background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDQgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00IDQuODcxMjlMMCAxLjE3ODk2VjkuMTc4OTZMNCA0Ljg3MTI5WiIgZmlsbD0iIzMxNDc1NiIvPgo8L3N2Zz4K)
  //   no-repeat left center;
  background-size: 5px;
  margin-left: 20px;
  padding: 0px 0px 0px 4px;

  & + & {
    margin-top: 12px;
  }
`;

const FeaturesHeading = styled.h2`
  font-family: "KurrentSemiBold";
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 1.5;
  margin: 0;
`;

const Graphic = styled.div`
  background: url("../images/v2/compliance-first.png") center;
  background-size: cover;
  height: 100%;
  min-height: 300px;
  width: 100%;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${(props) => config(props).media["md"]`
    grid-template-columns: 2fr 3fr;
  `}
`;

const Frame = styled.div`
  padding: 32px;
  ${(props) => config(props).media["md"]`
    padding: 40px 32px 40px 64px;
  `}
`;

export const ComplianceFirst: React.FC<Props> = (props) => {
  const gaEventTracker = useEventTracker("HOME:FEATURE_EXAMPLE");

  return (
    <Container>
      <Card padding="0">
        <Layout>
          <Graphic />
          <Frame>
            <FeaturesHeading>Compliance first</FeaturesHeading>
            <Spacer direction="v" size="md" />
            <UL>
              <LI>
                Manage third party risk effectively, including{" "}
                <abbr title="Know Your Transaction">KYT</abbr> and Travel Rule
                compliance
              </LI>
              <LI>
                Get granular control and oversight of transactions with
                approvals
              </LI>
              <LI>
                Set policy rules for individual assets with the policy engine
              </LI>
            </UL>
            <Spacer direction="v" size="xl" />
            <LinkButton
              to={PATHS.contact}
              onClick={() => gaEventTracker("READ_THE_DOCS", "CLICK_LINK")}
            >
              Contact us
            </LinkButton>
          </Frame>
        </Layout>
      </Card>
    </Container>
  );
};
