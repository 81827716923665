import styled from "styled-components";
import { colors } from "../../../theme";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isCompact?: boolean;
}

export const Input = styled("input")<TextInputProps & { $isCompact: boolean }>`
  appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  background: ${colors.white};
  border-radius: 3px;
  border: 1px solid ${colors.gray700};
  box-shadow: none;
  color: ${colors.gray900};
  font-family: InterRegular;
  font-size: 16px;
  height ${({ $isCompact }) => ($isCompact ? "44px" : "60px")};
  padding: 14px;
  width: 100%;

  &:focus {
    border: 1px solid ${colors.gray700};
    box-shadow: 0 0 0 4px ${colors.gray200};
    outline: none;
  }
`;

export const TextInput: React.FC<TextInputProps> = ({
  isCompact = true,
  ...rest
}) => {
  return <Input {...rest} $isCompact={isCompact!} />;
};
