import { config } from "react-awesome-styled-grid";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../theme";

export const SubMenu = styled.div`
  border: none;
  max-width: 500px;

  ${(props) => config(props).media["xs"]`
    margin-bottom: 64px;
  `}
`;

export const ListTitle = styled("span")`
  color: ${colors.gray700};
  display: block;
  font-family: "KurrentBold";
  font-size: 36px;
  letter-spacing: -0.72px;
  line-height: 120%;
  margin-bottom: 16px;
`;

export const List = styled("ul")`
  margin: 0px;
  padding: 0px;
`;

export const ListLink = styled(NavLink)`
  color: ${colors.gray700};
  display: block;
  font-family: "InterSemiBold";
  font-size: 20px;
  line-height: 150%;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    cursor: default;
    text-decoration: underline;
  }
`;

export const ListItem = styled("li")`
  display: block;
  list-style: none;
  padding-bottom: 16px;

  & + & {
    border-top: 1px solid ${colors.gray200};
    padding-top: 8px;
  }
`;
