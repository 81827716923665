import React, { useEffect } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import ReactGA from "react-ga4";
import styled from "styled-components";
import { Flex } from "../../components/Flex";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { SEO } from "../../components/SEO";
import { Spacer } from "../../components/Spacer";

export const Heading = styled("h1")`
  font-family: "KurrentBold";
  font-size: 48px;
  line-height: 58px;
`;

export const Wrapper = styled(Flex)`
  min-height 400px;
`;

interface Props {}

export const NotFound: React.FC<Props> = (props) => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      <RestoreScrollTop />
      <SEO description="Page not found." title="Not found" />
      <Spacer direction="v" size="5xl" />
      <Wrapper justifyContent="center" alignItems="center">
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <Heading>Page Not Found</Heading>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};
