import React, { useState } from "react";
import { Visible } from "react-awesome-styled-grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { BlankButton } from "../BlankButton";
import { Logo } from "../Logo";
import { MobileNavigation } from "../MobileNavigation";
import { Navigation } from "../Navigation";
import { BrandSquare } from "../icons/BrandSquare";
import { Menu } from "../icons/Menu";

interface Props {}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
  width: 100%;
  z-index: 200;
`;

const StyledBlankButton = styled(BlankButton)`
  opacity: 1;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
`;

export const Header: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "visible";
  }, [isOpen]);

  return (
    <>
      <Wrapper>
        <Link to={PATHS.home}>
          <Visible md lg xl>
            <Logo width={140} />
          </Visible>
          <Visible sm xs>
            <BrandSquare />
          </Visible>
        </Link>
        <Visible md lg xl>
          <Navigation />
        </Visible>
        {!isOpen ? (
          <Visible sm xs>
            <StyledBlankButton onClick={handleToggleMenu}>
              <Menu />
            </StyledBlankButton>
          </Visible>
        ) : null}
      </Wrapper>
      <Visible sm xs>
        <MobileNavigation isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </Visible>
    </>
  );
};
