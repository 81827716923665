import { PropsWithChildren } from "react";
import styled from "styled-components";
import { colors } from "../../theme";

interface Props extends PropsWithChildren {
  backgroundColor?: string;
  borderColor?: string;
  padding?: string;
}

const StyledCard = styled.div.attrs((props) => ({
  backgroundColor: "rgba(251, 252, 253, 0.35)",
  borderColor: colors.gray200,
  padding: "32px",
  ...props,
}))<Props>`
  border-radius: 3px;
  background: ${({ backgroundColor }) => backgroundColor} cover no-repeat;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  box-shadow: 22px 23px 53px 1px rgba(215, 221, 230, 0.44);
  padding: ${({ padding }) => padding};
`;

export const Card: React.FC<Props> = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};
