import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Menu: React.FC<Props> = ({ color = colors.gray700, ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2193_17706)">
        <path
          d="M4 12L36 12"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="square"
        />
        <path
          d="M4 20L36 20"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="square"
        />
        <path
          d="M4 28L36 28"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2193_17706">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
