import React from "react";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { Navigate, useParams } from "react-router-dom";
import { UrlParams } from ".";
import { PATHS } from "../../../AppShell/paths";
import { ActivityIndicator } from "../../../components/ActivityIndicator";
import { RestoreScrollTop } from "../../../components/RestoreScrollTop";
import { SEO } from "../../../components/SEO";
import { Spacer } from "../../../components/Spacer";
import { Stack } from "../../../components/Stack";
import { Article } from "../Article";
import { ArticleCTA } from "../ArticleCTA";
import { ArticlesSubMenu } from "../ArticlesSubMenu";
import { YouTubeVideo } from "../YouTubeVideo";

interface Props {
  postLoadable: any;
  listLoadable: any;
}

export const BlogPostPageView: React.FC<Props> = (props) => {
  const { postLoadable, listLoadable } = props;
  const { id } = useParams() as any as UrlParams;

  const renderLoadingState = () => {
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <ActivityIndicator />
          </Col>
        </Row>
      </Container>
    );
  };

  if (listLoadable.isLoading || postLoadable.isLoading) {
    return renderLoadingState();
  }

  if (!listLoadable.data || !listLoadable.data) {
    return null;
  }

  if (!postLoadable.data?.articles?.data?.length) {
    return <Navigate to={PATHS.resources} replace={true} />;
  }

  const articleList = listLoadable?.data?.articles.data;
  const article = postLoadable?.data?.articles?.data[0].attributes;

  return (
    <>
      <SEO
        description={article.meta_description}
        imgSrc={
          !!article.youtube_video_id
            ? `https://img.youtube.com/vi/${article.youtube_video_id}/maxresdefault.jpg`
            : undefined
        }
        name="Palisade Blog"
        title={article.title}
      />
      <Container>
        <Spacer direction="v" size="5xl" />
        <Spacer direction="v" size="4xl" />
        <Row>
          <RestoreScrollTop />
          <Col md={4} lg={4} offset={{ lg: 0 }}>
            <ArticlesSubMenu articleList={articleList} />
          </Col>
          <Col md={7} lg={6} offset={{ md: 1, lg: 1 }}>
            <Stack gap="56px">
              <Article article={article} />
              {!!article.youtube_video_id && (
                <YouTubeVideo
                  id={article.youtube_video_id}
                  title={article.title}
                />
              )}
              <ArticleCTA />
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  );
};
