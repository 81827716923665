import React from "react";
import { Col, Container, Hidden, Row, config } from "react-awesome-styled-grid";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { colors } from "../../theme";
import { Button } from "../Button";
import { H1, SubH1 } from "../Heading";
import { CheckInCircle } from "../icons/CheckInCircle";
import { CodeSnippet } from "../icons/CodeSnippet";
import { Dashboard } from "../icons/Dashboard";
import { MobileApp } from "../icons/MobileApp";
import { Spacer } from "../Spacer";
import { Stack } from "../Stack";
import { Text } from "../Text";

interface Props {}

const HeroContainer = styled("div")`
  ${(props) => config(props).media["sm"]`
    padding: 0 32px;
  `}

  ${(props) => config(props).media["md"]`
    padding: 0;
  `}
`;

const HeroWrapper = styled("div")`
  overflow: hidden;
  position: relative;

  ${(props) => config(props).media["sm"]`
    padding-bottom: 250px;
  `}
`;

const HeroBulletWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeroBulletText = styled.span`
  color: ${colors.gray700};
  font-family: InterMedium;
  font-size: 16px;
  display: inline-block;
  margin-left: 6px;
`;

const DashboardWrapper = styled("div")`
  box-shadow: 0px 75px 150px 0px rgba(52, 64, 84, 0.14);
  right: -620px;
  top: 80px;
  position: absolute;

  ${(props) => config(props).media["sm"]`
    right: -880px;
  `}

  ${(props) => config(props).media["md"]`
    right: -780px;
  `}

  ${(props) => config(props).media["lg"]`
    right: -550px;
  `}

  ${(props) => config(props).media["xl"]`
    right: -400px;
  `}
`;

const CodeSnippetWrapper = styled("div")`
  top: 450px;
  right: -470px;
  position: absolute;

  ${(props) => config(props).media["lg"]`
    right: -340px;
  `}

  ${(props) => config(props).media["xl"]`
    right: -200px;
  `}
`;

const MobileAppWrapper = styled("div")`
  top: 600px;
  right: 70px;
  position: absolute;

  ${(props) => config(props).media["lg"]`
    right: 250px;
  `}

  ${(props) => config(props).media["xl"]`
    right: 450px;
    top: 600px
  `}
`;

const HeroBullets = styled.div`
  align-items: center;
  display: grid;

  ${(props) => config(props).media["xs"]`
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${(props) => config(props).media["md"]`
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-template-columns: none;
  `}
`;

export const Hero: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <HeroWrapper>
      <Hidden xs>
        <DashboardWrapper>
          <Dashboard />
        </DashboardWrapper>
        <CodeSnippetWrapper>
          <CodeSnippet />
        </CodeSnippetWrapper>
        <MobileAppWrapper>
          <MobileApp />
        </MobileAppWrapper>
      </Hidden>
      <Container>
        <Spacer direction="v" size="4xl" />
        <Row>
          <Col
            xl={6}
            lg={5}
            md={5}
            xs={12}
            sm={7}
            offset={{
              xl: 1,
              lg: 1,
              md: 1,
            }}
          >
            <HeroContainer>
              <Spacer direction="v" size="xl" />

              <Stack gap="24px">
                <H1>A secure foundation for your digital assets</H1>
                <SubH1>
                  Stop battling with digital asset custody. Discover security,
                  governance and compliance in one comprehensive platform with
                  Palisade.
                </SubH1>
                <HeroBullets>
                  {["Audited", "Regulated", "Secure", "Segregated"].map(
                    (bulletText, index) => {
                      return (
                        <HeroBulletWrapper key={`bullet_${index}`}>
                          <CheckInCircle width={20} height={20} />
                          <HeroBulletText>{bulletText}</HeroBulletText>
                        </HeroBulletWrapper>
                      );
                    }
                  )}
                </HeroBullets>
              </Stack>
              <Spacer direction="v" size="4xl" />
              <div>
                <Button
                  isFullWidth={false}
                  onClick={() => {
                    navigate(PATHS.demoRequest);
                  }}
                >
                  Request a demo
                </Button>
              </div>
              <Spacer direction="v" size="md" />
              <Text fontSize="14px">
                Get in touch for a demo or to sign up for our Sandbox.
              </Text>
            </HeroContainer>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
};
