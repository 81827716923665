import React from "react";
import { Col, Container, Row, config } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Card } from "../Card";
import { Text } from "../Text";
import { FeatureApprovals } from "../icons/v2/FeatureApprovals";
import { FeatureCompliance } from "../icons/v2/FeatureCompliance";
import { FeatureDefi } from "../icons/v2/FeatureDefi";
import { FeatureWallet } from "../icons/v2/FeatureWallet";

const FeaturesGrid = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr;

  ${(props) => config(props).media["sm"]`
    grid-template-columns: 1fr 1fr;
  `}
`;

const FeatureCard = styled(Card)`
  ${(props) => config(props).media["md"]`
    align-items: flex-start;
    display: flex;
  `}
`;

const FeatureGraphic = styled.div`
  margin: 0 24px 24px 0;
  width: 100px;
`;

const FeatureBody = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
`;

const FeatureHeading = styled.p`
  font-size: 24px;
  line-height: 1.2;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: KurrentBold;
  color: ${colors.gray700};
`;

const FeatureCopy = styled(Text)`
  color: ${colors.gray700};
  font-family: InterRegular;
  font-size: 16px;
  line-height: 24px;
`;

export const Features: React.FC = (props) => {
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={10}
          lg={10}
          offset={{
            xs: 0,
            sm: 0,
            md: 1,
            lg: 1,
          }}
        >
          <FeaturesGrid>
            <FeatureCard>
              <FeatureGraphic>
                <FeatureWallet />
              </FeatureGraphic>
              <FeatureBody>
                <FeatureHeading>
                  Provision wallets and transact securely
                </FeatureHeading>
                <FeatureCopy>
                  Provision wallets and sign transactions using a choice of
                  secure technologies including{" "}
                  <abbr title="Hardware Security Module">HSM</abbr>,{" "}
                  <abbr title="Multi-Party Computation">MPC</abbr> and
                  multi-sig.
                </FeatureCopy>
              </FeatureBody>
            </FeatureCard>

            <FeatureCard>
              <FeatureGraphic>
                <FeatureDefi />
              </FeatureGraphic>
              <FeatureBody>
                <FeatureHeading>Interact seamlessly with DeFi</FeatureHeading>
                <FeatureCopy>
                  Effortless integration and interaction with decentralized
                  finance platforms for enhanced business operations.
                </FeatureCopy>
              </FeatureBody>
            </FeatureCard>

            <FeatureCard>
              <FeatureGraphic>
                <FeatureCompliance />
              </FeatureGraphic>
              <FeatureBody>
                <FeatureHeading>
                  Active compliance with KYT and travel rule integrations
                </FeatureHeading>
                <FeatureCopy>
                  Seamless connections to{" "}
                  <abbr title="Know Your Transaction">KYT</abbr> and Travel Rule
                  solutions, for robust regulatory adherence and risk
                  management.
                </FeatureCopy>
              </FeatureBody>
            </FeatureCard>

            <FeatureCard>
              <FeatureGraphic>
                <FeatureApprovals />
              </FeatureGraphic>
              <FeatureBody>
                <FeatureHeading>
                  Create controls with approvals and policy engine
                </FeatureHeading>
                <FeatureCopy>
                  Establish robust controls using a sophisticated approvals and
                  policy engine, designed for precise governance and operational
                  efficiency.
                </FeatureCopy>
              </FeatureBody>
            </FeatureCard>
          </FeaturesGrid>
        </Col>
      </Row>
    </Container>
  );
};
