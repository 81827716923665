export const PATHS = {
  blogPost: "/blog/:id",
  company: "/company",
  conflictOfInterestPolicy: "/legal/conflict-of-interest-policy",
  contact: "/contact",
  demoRequest: "/request-a-demo",
  home: "/",
  legal: "/legal",
  legalArticle: "/legal/:id",
  resources: "/resources",
};
