import { ButtonLevels, ButtonVariants } from '.';
import { colors } from '../../theme';

export const getActivityIndicatorColor = (
  level: ButtonLevels,
  variant: ButtonVariants
) => {
  if (level === 'primary') {
    if (variant === 'ghost') {
      return colors.gray300;
    }

    return colors.gray400;
  }

  if (level === 'secondary') {
    return colors.gray400;
  }

  if (level === 'assertive') {
    return colors.error300;
  }
};
