import { Stack } from "../Stack";

interface Props {
  children: any;
  gap?: string;
}

export const FormField: React.FC<Props> = ({ children, gap = "8px" }) => {
  return <Stack gap={gap}>{children}</Stack>;
};
