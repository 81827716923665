import styled from "styled-components";

interface Props {}

export const BlankButton = styled.button<Props>`
  margin: 0;
  border: none;
  background: 0;
  padding: 0px;
  cursor: pointer;
`;
