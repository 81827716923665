import styled from "styled-components";

interface Props {
  alignItems?: "flex-start" | "flex-end" | "center" | "space-between";
  alignSelf?: "flex-start" | "flex-end" | "center";
  display?: "inline" | "flex" | "block" | "inline-flex";
  flex?: number | string;
  flexDirection?: "row" | "column";
  flexWrap?: string;
  gap?: string;
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between";
  justifySelf?: "flex-start" | "flex-end" | "center";
  position?: string;
  maxWidth?: string;
  width?: string;
}

export const Flex = styled.div.attrs((props) => ({
  display: "flex",
  ...props,
}))<Props>`
  align-items: ${({ alignItems }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap};
  justify-content: ${({ justifyContent }) => justifyContent};
  justify-self: ${({ justifySelf }) => justifySelf};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
`;
