import React from "react";
import { Col, Container, Row } from "react-awesome-styled-grid";
import ReactMarkdown from "react-markdown";
import { Link, Navigate } from "react-router-dom";
import styled from "styled-components";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import { Card } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { Spacer } from "../../components/Spacer";
import { Document } from "../../components/icons/Document";
import { colors } from "../../theme";

interface Props {
  postLoadable: any;
  listLoadable: any;
}

export const ShadowCard = styled(Card)`
  // box-shadow: 4px 24px 27px -6px #d9dee669;
  background-color: transparent;
  border: none;
`;

const Heading = styled("h1")`
  color: ${colors.gray700};
  font-family: "InterMedium";
  font-size: 48px;
  margin: 0px 0px 30px;
`;

const ArticleDate = styled("span")`
  color: ${colors.gray500};
  font-family: "InterRegular";
  font-size: 16px;
`;

const ListTitle = styled("span")`
  color: ${colors.gray700};
  font-family: "InterSemiBold";
  display: block;
  font-size: 20px;
  margin-bottom: 16px;
`;

const List = styled("ul")`
  margin: 0px;
  padding: 0px;
`;

const ListLink = styled(Link)`
  color: ${colors.gray700};
  font-family: "InterRegular";
  font-size: 16px;
  display: block;
  text-decoration: none;
  align-items: center;
  display: inline-flex;

  &:hover {
    text-decoration: underline;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  display: block;

  & + & {
    margin-top: 16px;
  }
`;

export const LegalDocumentPageView: React.FC<Props> = (props) => {
  const { postLoadable, listLoadable } = props;

  const renderLoadingState = () => {
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <ActivityIndicator />
          </Col>
        </Row>
      </Container>
    );
  };

  if (listLoadable.isLoading || postLoadable.isLoading) {
    return renderLoadingState();
  }

  if (!listLoadable.data || !listLoadable.data) {
    return null;
  }

  if (!postLoadable.data?.legals?.data?.length) {
    return <Navigate to="/not-found" replace={true} />;
  }

  const article = postLoadable?.data?.legals?.data[0].attributes;
  const articleList = listLoadable?.data?.legals.data;

  return (
    <Container>
      <Row>
        <RestoreScrollTop />
        <Col md={4} lg={4} offset={{ lg: 0 }}>
          <ShadowCard>
            <ListTitle>Documents</ListTitle>
            <List>
              {articleList.map((item: any) => {
                return (
                  <ListItem>
                    <ListLink to={`/legal/${item.id}`}>
                      <span
                        style={{
                          display: "inline",
                          float: "left",
                          marginRight: "10px",
                        }}
                      >
                        <Document />
                      </span>
                      <span style={{ display: "inline" }}>
                        {item?.attributes.title}
                      </span>
                    </ListLink>
                  </ListItem>
                );
              })}
            </List>
          </ShadowCard>
        </Col>
        <Col md={7} lg={7} offset={{ lg: 1 }}>
          <Flex alignItems="center">
            <ArticleDate>
              Created on:{" "}
              {new Intl.DateTimeFormat("en-US").format(
                new Date(article.createdAt)
              )}
            </ArticleDate>
          </Flex>
          <Spacer direction="v" size="md" />
          <div>
            <Heading>{article.title}</Heading>
            <ReactMarkdown className="blog-post">{article.body}</ReactMarkdown>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
