interface Props {
  height?: string;
  id: string;
  title?: string;
  width?: string;
}

export const YouTubeVideo: React.FC<Props> = ({
  height = "360",
  id,
  title = "YouTube video player",
  width = "100%",
}) => {
  return (
    <iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      height={height}
      referrerPolicy="strict-origin-when-cross-origin"
      src={`https://www.youtube.com/embed/${id}?si=kkZpDvAp5hepq9-p`}
      title={title}
      width={width}
    ></iframe>
  );
};
