import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Legal: React.FC<Props> = ({ color = colors.white, ...rest }) => {
  return (
    <svg
      width="100"
      height="101"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.37305 6.65112L1.37305 94.6511M9.37305 6.65112L9.37305 94.6511M17.373 6.65112V94.6511M25.373 6.65112V94.6511M33.3731 6.65112L33.373 94.6511"
        stroke="url(#paint0_radial_1888_35977)"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeDasharray="0.02 8"
      />
      <path
        d="M55.6038 39.8343L83.5608 38.5165L58.8788 90.8695L49.9049 65.7154L30.2246 10.2749L58.8165 10.2749L55.6038 39.8343Z"
        fill="#314756"
        stroke="#183142"
        strokeWidth="0.2"
      />
      <path
        d="M59.0234 91.0278L83.8132 38.4263L83.9461 91.0278H59.0234Z"
        fill="#CAD2D8"
        stroke="#94A3AE"
        strokeWidth="0.2"
      />
      <path
        d="M30 10.2012L58.7258 91.0278L30 91.1011V10.2012Z"
        fill="#94A3AE"
        stroke="#657683"
        strokeWidth="0.2"
      />
      <path
        d="M83.6895 38.3211L55.8341 39.6039L59.0024 10.3984L83.6895 38.3211Z"
        fill="#F7F9FA"
        stroke="#CAD2D8"
        strokeWidth="0.2"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1888_35977"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.9991 3.5557) rotate(106.282) scale(98.346 35.7622)"
        >
          <stop offset="0.0214217" stopColor="#47FFBC" />
          <stop offset="0.369792" stopColor="#314756" />
          <stop offset="0.666667" stopColor="#2DEBB2" />
          <stop offset="0.966574" stopColor="#657683" />
        </radialGradient>
      </defs>
    </svg>
  );
};
