import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const FeatureCompliance: React.FC<Props> = ({
  color = "#183142",
  ...rest
}) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        style={{
          filter: `drop-shadow(0px 0px 5px rgb(0 0 0 / 0.1))`,
        }}
      >
        <rect
          width="18.8002"
          height="21.1289"
          transform="matrix(0.939385 0.342865 5.0303e-05 1 60.9922 15.6885)"
          fill="#94A3AE"
        />
        <rect
          width="21.1884"
          height="60.8403"
          transform="matrix(-5.04071e-05 1 -0.939385 0.342865 78.6562 22.1348)"
          fill="#314756"
        />
        <rect
          width="20.9464"
          height="60.9261"
          transform="matrix(5.0303e-05 1 -0.939385 -0.342865 78.7344 63.8848)"
          fill="#F7F9FA"
        />
        <rect
          width="20.9464"
          height="14.5464"
          transform="matrix(-5.04071e-05 1 -0.939385 0.342865 78.7812 63.9131)"
          fill="#CAD2D8"
        />
      </g>
      <rect x="50.1562" y="10" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="10" width="2" height="2" fill="#E6EAED" />
      <rect x="58.1562" y="10" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="10" width="2" height="2" fill="#47FFBC" />
      <rect x="66.1562" y="10" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="14" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="14" width="2" height="2" fill="#E6EAED" />
      <rect x="58.1562" y="14" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="14" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="18" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="18" width="2" height="2" fill="#47FFBC" />
      <rect x="66.1562" y="18" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="22" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="22" width="2" height="2" fill="#47FFBC" />
      <rect x="58.1562" y="22" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="22" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="26" width="2" height="2" fill="#47FFBC" />
      <rect x="58.1562" y="26" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="30" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="30" width="2" height="2" fill="#47FFBC" />
      <rect x="58.1562" y="30" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="30" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="30" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="34" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="34" width="2" height="2" fill="#E6EAED" />
      <rect x="58.1562" y="34" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="34" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="38" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="38" width="2" height="2" fill="#47FFBC" />
      <rect x="58.1562" y="38" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="38" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="38" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="42" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="42" width="2" height="2" fill="#94A3AE" />
      <rect x="58.1562" y="42" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="42" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="42" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="46" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="46" width="2" height="2" fill="#E6EAED" />
      <rect x="58.1562" y="46" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="46" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="50" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="50" width="2" height="2" fill="#94A3AE" />
      <rect x="62.1562" y="50" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="50" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="54" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="54" width="2" height="2" fill="#47FFBC" />
      <rect x="62.1562" y="54" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="58" width="2" height="2" fill="#E6EAED" />
      <rect x="54.1562" y="58" width="2" height="2" fill="#E6EAED" />
      <rect x="58.1562" y="58" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="58" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="58" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="62" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="62" width="2" height="2" fill="#E6EAED" />
      <rect x="50.1562" y="66" width="2" height="2" fill="#94A3AE" />
      <rect x="54.1562" y="66" width="2" height="2" fill="#E6EAED" />
      <rect x="58.1562" y="66" width="2" height="2" fill="#E6EAED" />
      <rect x="62.1562" y="66" width="2" height="2" fill="#E6EAED" />
      <rect x="66.1562" y="66" width="2" height="2" fill="#47FFBC" />
    </svg>
  );
};
