import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Ethereum: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="78"
      height="101"
      viewBox="0 0 78 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3956 69.4238L16.6293 56.0551L38.3956 87.9336V69.4238ZM61.8011 51.9437L39.4671 65.5305V41.5173L61.8011 51.9437ZM39.4671 40.4829L61.5791 50.8056L39.4671 13.0264V40.4829ZM38.3956 40.4829V13.0264L16.1649 50.8508L38.3956 40.4829ZM38.3956 41.5173V65.5305L16 51.9622L38.3956 41.5173Z"
        fill={color}
      />
      <path
        d="M61.2333 56.0951L39.4671 87.9735V69.4638L61.2333 56.0951Z"
        fill={color}
      />
    </svg>
  );
};
