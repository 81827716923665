import { PropsWithChildren } from "react";
import { Grid } from "../Grid";

interface StackProps extends PropsWithChildren {
  gap?: string;
}

export const Stack: React.FC<StackProps> = ({ gap, children }) => {
  return (
    <Grid columns="1fr" gap={gap}>
      {children}
    </Grid>
  );
};
