import styled from "styled-components";
import { Flex } from "../Flex";
import { InputLabel } from "../InputLabel";
import { RadioInput } from "../Radio";

const RadioInputWrapper = styled.div`
  margin-top: 2px;
`;

const StyledInputLabel = styled(InputLabel)`
  flex: 1;
`;

interface RadioFieldProps {
  id: string;
  label: string;
  name: string;
  value: string;
  required: boolean;
}

export const RadioField: React.FC<RadioFieldProps> = (props) => {
  const { id, label } = props;
  return (
    <Flex flex="0 1" gap="12px">
      <RadioInputWrapper>
        <RadioInput {...props} />
      </RadioInputWrapper>
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
    </Flex>
  );
};
