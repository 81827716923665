import styled from "styled-components";
import { colors } from "../../theme";

interface Props {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  textDecoration?: string;
  opacity?: string;
  align?: string;
  lineHeight?: string;
  overflowWrap?: string;
  fontStyle?: string;
}

export const Text = styled.p<Props>`
  color: ${({ color }) => (!!color ? color : colors.gray700)};
  opacity: ${({ opacity }) => (!!opacity ? opacity : 1)};
  text-align: ${({ align }) => (!!align ? align : "left")};
  line-height: ${({ lineHeight }) => (!!lineHeight ? lineHeight : "1")};
  overflow-wrap: ${({ overflowWrap }) =>
    overflowWrap ? overflowWrap : "anywhere"};
  font-size: ${({ fontSize }) => (!!fontSize ? fontSize : "14px")};
  text-decoration: ${({ textDecoration }) =>
    !!textDecoration ? textDecoration : "none"};
  font-weight: ${({ fontWeight }) => (!!fontWeight ? fontWeight : "normal")};
  font-family: ${({ fontFamily }) => (!!fontFamily ? fontFamily : "Helvetica")};
  margin: 0;
  padding: 0;
  font-style: ${({ fontStyle }) => (!!fontStyle ? fontStyle : undefined)};
`;

export const InlineText = styled.span<Props>`
  color: ${({ color }) => (!!color ? color : colors.gray700)};
  opacity: ${({ opacity }) => (!!opacity ? opacity : 1)};
  text-align: ${({ align }) => (!!align ? align : "left")};
  overflow-wrap: ${({ overflowWrap }) =>
    overflowWrap ? overflowWrap : "anywhere"};
  line-height: ${({ lineHeight }) => (!!lineHeight ? lineHeight : "1")};
  font-size: ${({ fontSize }) => (!!fontSize ? fontSize : "14px")};
  text-decoration: ${({ textDecoration }) =>
    !!textDecoration ? textDecoration : "none"};
  font-weight: ${({ fontWeight }) => (!!fontWeight ? fontWeight : "normal")};
  font-family: ${({ fontFamily }) => (!!fontFamily ? fontFamily : "Helvetica")};
  margin: 0;
  padding: 0;
  font-style: ${({ fontStyle }) => (!!fontStyle ? fontStyle : undefined)};
`;
