import styled from "styled-components";
import { colors } from "../../theme";

export const InputLabel = styled("label")`
  color: ${colors.gray900};
  cursor: pointer;
  font-family: InterMedium;
  font-size: 16px;
  line-height: 150%;
`;
