import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager } from "styled-components";

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

interface Props {
  children?: React.ReactNode;
}

export const StyleSheetContextProvider: React.FC<Props> = (props) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {props.children}
    </StyleSheetManager>
  );
};
