import { useQuery } from "@apollo/client";
import * as React from "react";
import { createGraphQlLoadable } from "../../../graphql/utils";
import { QUERY_LIST_ARTICLES } from "./queries";
import { BlogIndexPageView } from "./view";

export const BlogIndexPageContainer: React.FC = () => {
  const listArticlesQuery = useQuery(QUERY_LIST_ARTICLES, {
    fetchPolicy: "network-only",
  });

  const listArticlesLoadable = createGraphQlLoadable({
    isLoading: listArticlesQuery.loading,
    error: listArticlesQuery.error,
    data: listArticlesQuery.data,
  });

  return <BlogIndexPageView listArticlesLoadable={listArticlesLoadable} />;
};
