import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PATHS } from "../../AppShell/paths";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { SEO } from "../../components/SEO";
import { Spacer } from "../../components/Spacer";
import { colors } from "../../theme";

interface Props {}

const Wrapper = styled("div")`
  margin: 140px auto;
  max-width: 800px;
`;

const Heading = styled("h1")`
  color: ${colors.white};
  font-family: "InterMedium";
  font-size: 26px;
  margin: 0px 0px 30px;
`;

const PreHeading = styled("h2")`
  color: ${colors.white};
  font-family: "InterMedium";
  font-size: 18px;
  margin: 40px 0 0 0;
`;

const StyledLink = styled(Link)`
  color: ${colors.white};
`;

const IndentedContainer = styled("div")`
  margin-left: 40px;
`;

const Table = styled("table")`
  border: 1px solid ${colors.white};
  width: 100%;
`;

const TD = styled("td")`
  border: 1px solid white;
  padding: 5px;
`;

const TH = styled(TD)`
  font-weight: 600;
`;

const ListItem = styled("li")`
  & + & {
    margin-top: 6px;
  }
`;

export const ConflictOfInterestPolicy: React.FC<Props> = (props) => {
  return (
    <>
      <RestoreScrollTop />
      <SEO
        description="Read our Conflict of Interest Policy to understand how we manage and prevent conflicts of interest."
        title="Conflict of Interest Policy"
      />
      <Wrapper>
        <Heading>Conflict of Interest Policy</Heading>
        <Table>
          <tbody>
            <tr>
              <TH>Name of the document:</TH>
              <TD>Conflict of Interest Policy</TD>
            </tr>
            <tr>
              <TH>Target Audience:</TH>
              <TD>All employees</TD>
            </tr>
            <tr>
              <TH>Charter Owner:</TH>
              <TD>James Skelton</TD>
            </tr>
            <tr>
              <TH>Version:</TH>
              <TD>V1 – 26/01/2023</TD>
            </tr>
            <tr>
              <TH>Effective date:</TH>
              <TD>26/01/2023</TD>
            </tr>
            <tr>
              <TH>Approved by:</TH>
              <TD>Manthan Dave</TD>
            </tr>
            <tr>
              <TH>Further information:</TH>
              <TD>Author: James Skelton - MLRO</TD>
            </tr>
          </tbody>
        </Table>
        <Spacer direction="v" />
        <p>
          In accordance with the MiFID II Directive, Article L. 54-10-5 I 4° of
          the French Monetary and Financial Code and Article 721-9 of the
          General Regulations of the French Financial Markets Authority
          (Autorité des marchés financiers), Palisade Financial SAS (the
          “Company” or “Palisade”) has established this Conflict-of-Interest
          Policy (the “Policy”). This Policy is available to clients and
          prospects, as well as to other stakeholders of the Company.
        </p>
        <p>
          This document details how the Company minimises and addresses
          conflicts of interest. It provides details on the various means used
          to ensure the effective implementation of procedures for identifying,
          preventing and managing conflicts of interest.
        </p>
        <PreHeading>1. Purpose and Scope of the Policy</PreHeading>
        <p>
          The Policy defines the Company’s procedures and clearly identifies
          circumstances that may give rise to conflicts of interest, where the
          Company cannot assure, with reasonable confidence, that the risk of
          harm to the client’s interests will be avoided.
        </p>
        <p>
          The Policy identifies the types of potential conflicts of interest
          that may arise on the technology interface operated by the Company via
          the <StyledLink to={PATHS.home}>https://www.palisade.co/</StyledLink>{" "}
          portal. The Policy describes the procedure for such identification and
          the steps taken by the Company.
        </p>
        <p>
          The Policy is set out in writing and is appropriate to the size and
          organisation of the Company and the nature, scale and complexity of
          our business.
        </p>
        <p>
          The Policy applies to the Company and its officers, employees and any
          other person directly or indirectly controlled by the Company (the “
          <strong>Connected Persons</strong>”).
        </p>
        <p>
          As minimum criteria, the Company considers the following situations:
        </p>
        <ul>
          <ListItem>
            the Company and/or a Connected Person has an interest in the outcome
            of a service provided to the client that is contrary to the client’s
            interest;
          </ListItem>
          <ListItem>
            the Company and/or a Connected Person have a financial or other
            incentive to favour the interest of a particular client or group of
            clients at the expense of its other clients;
          </ListItem>
          <ListItem>
            the Company and/or Connected Person have a financial or other
            incentive that causes them to overlook vigilance measures thus
            resulting in increased financial crime risk exposure;
          </ListItem>
          <ListItem>
            the Company and/or Connected Person receive, or will receive, from
            any person, other than the client, an inducement in connection with
            a service provided to the client, in the form of money, goods or
            services, other than the standard commission or fee consummate for
            that service;
          </ListItem>
          <ListItem>
            the Policy identifies the procedures and measures taken by the
            Company that are designed to ensure that Connected Persons engaged
            in various business activities involving a conflict of interest (as
            specified above) perform those activities at a level of independence
            appropriate to the Company’s size and business.
          </ListItem>
        </ul>
        <p>The procedures in the Policy are intended to:</p>
        <ul>
          <ListItem>
            prevent and monitor the exchange of information between Connected
            Person engaged in activities involving a potential conflict of
            interest when the exchange of such information may adversely affect
            the interests of one or more clients;
          </ListItem>
          <ListItem>
            separate the supervision of Connected Person whose primary duties
            involve performing activities on behalf of, or providing services
            to, clients whose interests may conflict, or who otherwise represent
            different interests that may conflict, including those of the
            Company;
          </ListItem>
          <ListItem>
            eliminate any direct link between the compensation of Connected
            Person engaged primarily in one activity and the compensation or
            income generated by other Connected Person engaged primarily in
            another activity, where a conflict of interest may arise in
            connection with these different activities;
          </ListItem>
          <ListItem>
            take steps to prevent or limit the ability of any person to exercise
            improper influence over the manner in which a Connected Person
            performs services.
          </ListItem>
          <ListItem>
            counter the risk of the Connected Person causing the Company to fail
            in its obligations of preventing money laundering and terrorist
            financing.
          </ListItem>
        </ul>
        <PreHeading>
          2. Identification of potential sources of conflict of interest
        </PreHeading>
        <p>
          Considering the services offered by the Company, potential sources of
          conflict of interest may arise in the following cases:
        </p>
        <ul>
          <ListItem>
            the Company may provide services to clients who may have interests
            in digital assets that conflict or compete with the interests of
            other clients of the Company;
          </ListItem>
          <ListItem>
            commissions, remuneration, gifts, etc. granted or paid to the
            Company’s employees by the Company, based on their performance, may
            negatively affect the Client and encourage more or less aggressive
            marketing tactics or drive concealment of important vigilance
            information, especially if such information will result in refusal
            of service;
          </ListItem>
          <ListItem>
            remuneration and/or incentives granted or paid to third parties
            (e.g., business introducers) on the basis of recommendations,
            referrals and/or integration of new clients, which may negatively
            affect the Client and encourage aggressive marketing tactics;
          </ListItem>
          <ListItem>
            compensation and/or incentives granted or paid to third parties who
            provide services under an outsourcing arrangement, if such
            compensation induces practices contrary to the interests of the
            Company’s clients;
          </ListItem>
          <ListItem>
            Palisade’s employees may have access to the clients confidential
            information which could be used for their personal benefit,
            jeopardizing the client’s capacity to provide its own clients with a
            fair and honest information.
          </ListItem>
          <ListItem>
            Palisade’s employees are known to have close connections /
            relationships with the Client through self-disclosure and/or general
            knowledge of their personal / professional network which may cause
            high risk indicators to be overlooked or concealed.
          </ListItem>
        </ul>
        <p>
          It should be noted that the above scenarios do not necessarily give
          rise to a “conflict of interest” or any material harm to the client
          but are disclosed in the interest of full transparency. The above
          scenarios should be considered in relation to the procedures and
          measures implemented by the Company to manage or limit their impact.
        </p>
        <PreHeading>3. Conflict of interest management</PreHeading>
        <p>
          The Company has organisational and administrative procedures to manage
          and prevent any conflict of interest that may arise and could
          constitute or result in a risk of harm to its clients.
        </p>
        <p>
          This section of the Policy will describe the actions taken by the
          Company in general, as well as those taken in relation to the above
          list of identified potential conflicts of interest.
        </p>
        <IndentedContainer>
          <PreHeading>3.1. General measures</PreHeading>
          <ul>
            <ListItem>
              The Company has procedures in place for the employment of persons
              who are considered knowledgeable and competent, and for the
              training of such employees;
            </ListItem>
            <ListItem>
              The Company will prohibit employees with close personal or
              professional relationships with the Client from participating in
              the assessment, due diligence and establishing of the business
              relationship.
            </ListItem>
            <ListItem>
              The Company will implement procedures to monitor third
              parties/outsourced service providers to ensure that they are
              competent to perform the services assigned to them
            </ListItem>
            <ListItem>
              The Company will implement a separation of departments/functions
              providing services to clients whose interests may conflict with
              those of other clients or with the Company’s interests;
            </ListItem>
            <ListItem>
              The Company will ensure that no single employee has
              responsibilities with conflicting interests; otherwise known as
              “segregation of duties” where relevant;
            </ListItem>
            <ListItem>
              The Company respects the confidentiality of information it
              receives from and about its clients and applies a “need to know”
              approach. Access to confidential information is limited to those
              whose job requires them to have access to it, in a proportionate
              and limited manner;
            </ListItem>
            <ListItem>
              The Company may establish “Chinese walls” to physically separate
              key departments of the Company in order to restrict the flow of
              confidential information within the Company;
            </ListItem>
            <ListItem>
              The Company has established an internal compliance function whose
              responsibilities include monitoring and reporting on KYC-AML
              procedures and the Policy, as well as identifying potential
              conflicts of interest and making recommendations for their
              management/limitation. The compliance function is also responsible
              for legal monitoring to ensure that the Company complies with
              applicable legislation;
            </ListItem>
            <ListItem>
              The Company will apply the “four eyes” principle in the
              supervision of its activities, which consists in assigning the
              different verification steps to different people.
            </ListItem>
            <ListItem>
              The Company will provide training to all employees on the
              importance of AML, severity of concealment of information and
              self-declaration of close connections. Enhanced training will be
              provided to employees whose roles have increased conflict of
              interest risk exposure.
            </ListItem>
          </ul>
          <PreHeading>
            3.2. Measures relating to the Company’s potential relation with
            third party service providers
          </PreHeading>
          <p>
            The Company is committed to transparency in its selection of the
            third-party service providers. Palisade’s clients should know
            whether the Company has any incentive to recommend a particular
            service because of its financial compensation related to the number
            of clients that choose said service.
          </p>
          <p>
            To ensure impartiality, the selection of third-party services
            providers must be made by persons who have no conflicting interest
            and must be based solely on objective criteria such as the quality
            of the service provided.
          </p>
          <PreHeading>
            3.3. Measures relating to incentives, commissions, remuneration,
            gifts, etc.
          </PreHeading>
          <p>
            <u>Employee compensation policy.</u>
          </p>
          <p>
            In accordance with current legislation, the Company does not base
            the variable compensation of its employees on factors that could
            create a conflict of interest with the clients with whom they are in
            contact.
          </p>
          <p>
            For employees paid a variable portion, this is therefore based on
            objective performance indicators within their departments. Any
            bonuses or one-off payments are at the discretion of senior
            management/ the Board and are only disclosed to that employee at the
            time of payment.
          </p>
        </IndentedContainer>
        <PreHeading>4. Conflict of Interest Disclosure</PreHeading>
        <p>
          When the Company becomes aware of a conflict of interest that may
          arise and when the organisational and administrative arrangements
          established by the Company to prevent or manage such conflict are not
          sufficient to ensure, with reasonable confidence, that the risk of
          harm to the client’s interests will be avoided, the Company will
          disclose such risk to its clients.
        </p>
        <p>
          Where disclosure of such a conflict is not sufficient to manage the
          conflict, the Company may elect, in its sole discretion, not to
          proceed with the transaction or business relationship giving rise to
          the conflict. The Company reserves the right to amend this Policy
          whenever it deems appropriate.
        </p>
      </Wrapper>
    </>
  );
};
