import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Cross: React.FC<Props> = ({ color = colors.gray700, ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.68628 31.3137L31.3137 8.68631M8.68628 8.68628L31.3137 31.3137"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="square"
      />
    </svg>
  );
};
