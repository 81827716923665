import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';

export const Wrapper = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const ActivityIndicator: React.FC = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};
