import { Button, ButtonProps } from "../Button";

interface AnchorButtonProps extends ButtonProps {
  href: string;
  target?: string;
}

export const AnchorButton: React.FC<AnchorButtonProps> = (props) => {
  const { isFullWidth, href, target, ...rest } = props;

  return (
    <a
      style={{
        textDecoration: "none",
        outline: 0,
      }}
      href={href}
      tabIndex={-1}
      target={target}
    >
      <Button {...rest} isFullWidth={isFullWidth} />
    </a>
  );
};
