import React from "react";
import { Col, Container, Row } from "react-awesome-styled-grid";
import styled from "styled-components";
import { colors } from "../../theme";
import { Grid } from "../Grid";
import { BrandSquare } from "../icons/BrandSquare";
import { DownArrow } from "../icons/v2/DownArrow";
import { Spacer } from "../Spacer";
import { Text } from "../Text";
import { OPTIONS } from "./helpers";

const FeatureHeading = styled("p")`
  font-size: 48px;
  letter-spacing: -1px;
  line-height: 1.2;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: KurrentSemiBold;
  color: ${colors.white};
`;

const OptionButton = styled.button<{ isActive: boolean }>`
  border: 0;
  border-radius: 0;
  background: transparent;
  font-size: 16px;
  font-family: InterLight;
  color: ${colors.gray50};
  text-align: left;
  letter-spacing: 2px;
  padding: 8px;
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  width 100%;

  &:hover,
  &:focus {
    background: rgb(49 71 86 / 61%);
  }

  ${(props) => {
    if (props.isActive) {
      return `
        background: rgb(49 71 86 / 61%);
        font-weight: 600;
      `;
    }
  }}

  & + & {
    margin-top: 2px;
  }
`;

export const UseCases: React.FC = () => {
  const [current, setCurrent] = React.useState<number>(0);

  // const getCurrentOption = () => {
  //   return OPTIONS[current];
  // };

  const renderOptionButtons = () =>
    OPTIONS.map((option, index) => (
      <OptionButton
        isActive={current === index}
        key={`option-button_${index}`}
        onClick={() => handleOnClick(index)}
      >
        {option.name}
      </OptionButton>
    ));

  const renderOptionDescription = () => {
    return (
      <div style={{ padding: "36px 24px" }}>
        <DownArrow />
        <Spacer direction="v" size="md" />

        <Text
          style={{ letterSpacing: "2px", textTransform: "uppercase" }}
          fontFamily="InterLight"
          fontSize="16px"
          fontWeight="600"
          color={colors.white}
        >
          {OPTIONS[current].name}
        </Text>
        <Spacer direction="v" size="md" />
        <Text
          fontFamily="InterRegular"
          fontSize="18px"
          lineHeight="1.5"
          color={colors.white}
        >
          {OPTIONS[current].description}
        </Text>
      </div>
    );
  };

  const handleOnClick = (index: number) => {
    setCurrent(index);
  };

  return (
    <div
      style={{
        position: "relative",
        padding: "48px 0px",
        background:
          "url(../images/v2/background-full-pattern-dark.png) repeat center center",
      }}
    >
      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            offset={{
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            }}
          >
            <Row>
              <Col
                md={4}
                lg={4}
                style={{ borderRight: `1px solid ${colors.gray600}` }}
              >
                <Grid columns="auto 1fr">
                  <div>
                    <div>
                      <FeatureHeading>
                        Operational freedom for your assets
                      </FeatureHeading>
                      <Spacer direction="v" size="md" />
                    </div>
                    <Text
                      fontSize="16px"
                      fontFamily="InterRegular"
                      color={colors.white}
                      lineHeight="24px"
                    >
                      From everyday payments to trading to issuing tokens, you
                      can maximise all of your digital assets within Palisade.
                    </Text>
                    <Spacer direction="v" size="md" />
                    <div style={{ position: "absolute", bottom: "-1px" }}>
                      <BrandSquare
                        color={colors.gray50}
                        width="60px"
                        height="60px"
                      />
                    </div>
                  </div>
                </Grid>

                <Spacer direction="v" />
              </Col>
              <Col
                md={4}
                lg={4}
                style={{ borderRight: `1px solid ${colors.gray600}` }}
              >
                <div style={{ padding: "0px 24px" }}>
                  {renderOptionButtons()}
                </div>
              </Col>
              <Col md={4} lg={4}>
                {renderOptionDescription()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
