import { colors } from "../../theme";

export const THEME = {
  hljs: {
    display: "block",
    overflowX: "auto",
    color: colors.gray100,
    background: colors.gray900,
    fontSize: "16px",
  },

  "hljs-comment": {
    color: "#66FFC9",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-quote": {
    color: "#8CECF3",
    fontStyle: "italic",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-keyword": {
    color: "#46DEB8",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-selector-tag": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-literal": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-subst": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-number": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-string": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-doctag": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-selector-id": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-selector-class": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-section": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-type": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-params": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-title": {
    color: "#8CECF3",
    fontWeight: "bold",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-tag": {
    color: "#000080",
    fontWeight: "normal",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-name": {
    color: "#8CECF3",
    fontWeight: "normal",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-attribute": {
    color: "#8CECF3",
    fontWeight: "normal",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-variable": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-template-variable": {
    color: "#8CECF3",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-regexp": {
    color: "#b68",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-link": {
    color: "#b68",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-symbol": {
    color: "#990073",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-bullet": {
    color: "#990073",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-built_in": {
    color: "#46DEB8",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-builtin-name": {
    color: "#46DEB8",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-meta": {
    color: "#999",
    fontWeight: "bold",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-deletion": {
    background: "#fdd",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-addition": {
    background: "#dfd",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-emphasis": {
    fontStyle: "italic",
    fontFamily: "FiraCodeRegular",
  },
  "hljs-strong": {
    fontWeight: "bold",
    fontFamily: "FiraCodeRegular",
  },
};
