import { useEffect, useRef, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import styled from "styled-components";
import Typed from "typed.js";
import { colors } from "../../theme";
import { THEME } from "./theme";

interface Props {
  isMobile?: boolean;
}

const TextCursor = styled.span`
  animation: blink 0.9s linear infinite;

  @keyframes blink {
    25% {
      opacity: 0.5;
    }

    50% {
      opacity: 0;
    }

    75% {
      opacity: 0.5;
    }
  }
`;

export const Code: React.FC<Props> = (props) => {
  const { isMobile } = props;

  const codeRef = useRef(null);
  const componentRef = useRef(null);
  const initializedRef = useRef(false);
  const [componentVisible, setComponentVisible] = useState<boolean>(false);

  useEffect(() => {
    let typed: Typed | undefined;

    if (!initializedRef.current) {
      typed = new Typed(codeRef.current, {
        strings: [
          '<span class="import">import</span> { Palisade } <span class="import">from</span> "palisade"\n\n<span class="variable">const</span> palisade = new <span class="constructor">Palisade</span>();\n\npalisade.authenticate({token: "eyJhbGciOiJIUzI1NiIsI..."})\n\n<span class="variable">const</span> transaction = palisade.transaction({\n  amount: 589,000\n  walletId: 1a7cef46-d145-4e2d-8c39-479d48eaff56\n  destination: 0x0000000000000000000000000000000000000000\n});',
        ],
        showCursor: false,
        smartBackspace: true,
        typeSpeed: 20,
      });
    }

    if (!!typed) {
      if (!componentVisible) {
        typed.reset(false);
      } else {
        typed.start();
      }
    }
    return () => {
      if (!!typed) {
        typed.destroy();
      }
    };
  }, [componentVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      setComponentVisible(entry.isIntersecting);
    });

    observer.observe(componentRef.current!);
  }, []);

  return (
    <div
      ref={componentRef}
      style={{ width: "100%", display: "block", overflowX: "hidden" }}
    >
      <SyntaxHighlighter
        renderer={() => {
          return (
            <div
              id="test"
              style={{ display: "flex", width: "max-width", height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    background: colors.gray900,
                    padding: "12px 16px",
                  }}
                >
                  {Array.from(Array(11)).map((item, index) => {
                    return <div key={`char_${index}`}>{index + 1}</div>;
                  })}
                </div>
                <div style={{ padding: "12px" }}>
                  <span>
                    <span className="syntax-highlighting" ref={codeRef} />
                    <TextCursor>|</TextCursor>
                  </span>
                </div>
              </div>
            </div>
          );
        }}
        language="javascript"
        style={THEME as any}
        wrapLongLines={false}
        wrapLines={false}
        showLineNumbers={true}
        customStyle={{
          height: "350px",
          background: colors.gray800,
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          border: `1px solid ${colors.gray700}`,
          borderBottom: isMobile ? "1px" : "none",
          borderRadius: isMobile ? "3px 0px 0px" : "3px",
          margin: 0,
          whiteSpace: "pre",
          overflowWrap: "normal",
          width: "100%",
        }}
        lineNumberStyle={{
          background: colors.gray700,
          color: colors.gray25,
          width: "80px",
          padding: "0px 30px",
          marginRight: "0px",
          whiteSpace: "pre",
          paddingTop: "2px",
          fontFamily: "FiraCodeLight",
        }}
        lineNumberContainerStyle={{
          padding: "100px",
          whiteSpace: "pre",
          background: colors.success700,
          paddingTop: "42px",
        }}
        codeTagProps={{
          style: {
            whiteSpace: "pre",
            width: "100%",
            display: "block",
            height: "100%",
          },
        }}
      >
        ''
      </SyntaxHighlighter>
    </div>
  );
};
