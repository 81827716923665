import { gql } from "@apollo/client";

export const QUERY_LIST_ARTICLES = gql`
  query ListArticles {
    articles(sort: "createdAt:desc") {
      data {
        id
        attributes {
          body
          category {
            data {
              id
            }
          }
          createdAt
          description
          slug
          title
        }
      }
    }
  }
`;
