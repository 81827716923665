/* eslint-disable no-process-env */

/**
 * Check the process.env global for the given key. If it does not exist,
 * throw an error since the app expects/requires that it exists in the environment.
 */
function getEnvValue(key: string) {
  if (process.env[key] !== undefined) {
    return process.env[key]!;
  }
  throw new Error(`env ${key} does not exist`);
}

/**
 * NODE_ENV: The environment that our web app is running in:
 *   - dev: Development mode, optimize for developers (logging, tooling, etc)
 *   - test: Test mode, optimize for test runners
 *   - production: Production/Staging mode, optimize for the end user & remove dev tooling
 */
export const NODE_ENV = getEnvValue("NODE_ENV");

/**
 * Palisade API service URIs
 */

export const GQL_URI = getEnvValue("REACT_APP_GQL_URI");
export const CMS_ACCESS_TOKEN = getEnvValue("REACT_APP_CMS_TOKEN");
export const API_URI = getEnvValue("REACT_APP_API_URI");
