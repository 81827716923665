import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import AppShell from "./AppShell";

import { ApolloGuardian } from "./context/ApolloGuardian";
import "./registerStyles";

const MEASUREMENT_ID = "G-22J37RTG80";
ReactGA.initialize(MEASUREMENT_ID);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloGuardian>
      <AppShell />
    </ApolloGuardian>
  </React.StrictMode>
);
