import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const ChainLink: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="89"
      height="101"
      viewBox="0 0 89 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M44.5 17.1511L38.5741 20.6855L22.4259 30.3667L16.5 33.9011V67.4011L22.4259 70.9355L38.7222 80.6167L44.6481 84.1511L50.5741 80.6167L66.5741 70.9355L72.5 67.4011V33.9011L66.5741 30.3667L50.4259 20.6855L44.5 17.1511ZM28.3519 60.3323V40.9699L44.5 31.2887L60.6481 40.9699V60.3323L44.5 70.0135L28.3519 60.3323Z"
        fill="#314756"
      />
    </svg>
  );
};
