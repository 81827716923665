import * as React from "react";
import { SVGProps } from "react";
import { colors } from "../../../theme";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const RippleLogo: React.FC<Props> = ({
  color = colors.gray700,
  ...rest
}) => {
  return (
    <svg
      width="156"
      height="96"
      viewBox="0 0 156 96"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M57.0462 43.1249C56.6355 42.9911 55.7685 42.9018 54.9014 42.9018C52.3002 42.9018 49.5165 44.9614 49.5165 47.5039V59.6287H43.5842V37.9954H49.2884V40.181C50.6118 38.5752 53.0303 37.5492 55.7228 37.5492C56.396 37.554 57.0677 37.6137 57.7308 37.7277L57.0462 43.1249Z"
        stroke="transparent"
      />
      <path
        d="M63.754 26.6211C65.8988 26.6211 67.6329 28.2269 67.6329 30.3233C67.6329 32.4198 65.8989 34.0254 63.754 34.0254C61.6091 34.0254 59.8751 32.4198 59.8751 30.3233C59.8751 28.2269 61.6093 26.6211 63.754 26.6211ZM66.7202 59.6287H60.7878V37.9954H66.7202V59.6287Z"
        stroke="transparent"
      />
      <path
        d="M84.2442 37.5482C81.8898 37.519 79.5985 38.2926 77.7644 39.7359V37.9952H72.0589V70.3327H77.9918V58.1124C79.6791 59.4153 81.7748 60.1087 83.9248 60.0755C87.2107 60.0755 90.3586 58.6469 92.3209 56.4167C94.0558 54.4537 95.1035 51.7788 95.1035 48.8343C95.1035 42.4557 90.2667 37.5482 84.2442 37.5482ZM83.5232 54.8108C79.8381 54.8108 77.416 52.3251 77.416 48.8532C77.416 45.3813 79.8381 42.8956 83.5232 42.8956C86.6687 42.8956 89.219 45.5634 89.219 48.8532C89.219 52.143 86.6687 54.8108 83.5232 54.8108Z"
        stroke="transparent"
      />
      <path
        d="M131.109 59.6287H125.177V27.9592H131.109V59.6287Z"
        stroke="transparent"
      />
      <path
        d="M155.295 56.194C153.15 58.6919 149.637 60.0747 146.214 60.0747C139.597 60.0747 134.623 55.6143 134.623 48.8343C134.623 42.8126 138.821 37.5492 145.393 37.5492C151.508 37.5492 155.843 42.5895 155.843 48.6112C155.846 49.1642 155.801 49.7165 155.706 50.2617H140.373C140.692 53.2947 143.202 55.3912 146.351 55.3912C148.861 55.3912 150.869 54.0084 151.918 52.8933L155.295 56.194ZM149.774 46.0242C149.591 44.1062 147.811 42.2328 145.393 42.2328C142.928 42.2328 140.921 44.0617 140.647 46.0242H149.774Z"
        stroke="transparent"
      />
      <path
        d="M110.804 37.5482C108.449 37.5191 106.158 38.2926 104.324 39.7359V37.9952H98.6183V70.3327H104.551V58.1124C106.238 59.4153 108.334 60.1087 110.484 60.0755C113.77 60.0755 116.918 58.6469 118.88 56.4167C120.615 54.4537 121.663 51.7788 121.663 48.8343C121.663 42.4557 116.826 37.5482 110.804 37.5482ZM110.082 54.8108C106.397 54.8108 103.975 52.3251 103.975 48.8532C103.975 45.3813 106.397 42.8956 110.082 42.8956C113.228 42.8956 115.778 45.5633 115.778 48.8532C115.778 52.143 113.228 54.8108 110.082 54.8108Z"
        stroke="transparent"
      />
      <path
        d="M31.3544 51.7471C30.015 50.9911 28.5066 50.772 27.0126 50.721C25.7591 50.6783 23.8889 49.8931 23.8889 47.6642C23.8889 46.0035 25.2661 44.667 27.0132 44.6074C28.5072 44.5564 30.0154 44.337 31.3544 43.5814C32.5573 42.9026 33.5561 41.9263 34.2506 40.7505C34.945 39.5748 35.3106 38.2412 35.3106 36.8836C35.3106 35.526 34.945 34.1923 34.2505 33.0166C33.556 31.8409 32.5572 30.8646 31.3543 30.1858C30.1515 29.5071 28.7871 29.1497 27.3982 29.1498C26.0092 29.1498 24.6448 29.5072 23.442 30.186C22.2392 30.8648 21.2404 31.8412 20.5459 33.0169C19.8515 34.1926 19.486 35.5263 19.486 36.8839C19.486 38.3823 20.0313 39.7612 20.727 41.0402C21.3121 42.1158 21.6081 44.1123 19.6013 45.2448C18.1084 46.0872 16.2453 45.5538 15.3147 44.098C14.5287 42.8684 13.5797 41.7163 12.2512 40.9665C11.0484 40.2877 9.68398 39.9303 8.29508 39.9303C6.90619 39.9303 5.54177 40.2877 4.33895 40.9665C3.13613 41.6452 2.1373 42.6215 1.44286 43.7972C0.748409 44.9729 0.382812 46.3066 0.382812 47.6642C0.382812 49.0217 0.748409 50.3554 1.44286 51.5311C2.1373 52.7068 3.13613 53.6831 4.33895 54.3618C5.54177 55.0406 6.90619 55.398 8.29508 55.398C9.68398 55.398 11.0484 55.0406 12.2512 54.3618C13.5794 53.6122 14.5284 52.4609 15.314 51.2321C15.959 50.223 17.5701 48.9368 19.6012 50.0838C21.0918 50.9256 21.5526 52.77 20.7263 54.2892C20.0307 55.5679 19.4861 56.9465 19.4861 58.4447C19.4861 59.8023 19.8517 61.1359 20.5462 62.3116C21.2406 63.4872 22.2394 64.4635 23.4422 65.1423C24.645 65.8211 26.0094 66.1784 27.3983 66.1784C28.7872 66.1784 30.1516 65.8211 31.3544 65.1423C32.5572 64.4635 33.556 63.4873 34.2505 62.3116C34.9449 61.1359 35.3105 59.8023 35.3105 58.4447C35.3105 57.0872 34.9449 55.7535 34.2505 54.5778C33.556 53.4022 32.5572 52.4259 31.3544 51.7471Z"
        stroke="transparent"
      />
    </svg>
  );
};
